<template>
  <div class="mailDetail">
    <not-found v-if="flag.notFound" />

    <div class="container" v-else>
      <div class="mb-5 d-flex align-items-center">
        <h1 class="title-page mb-0">送信済みメール</h1>
        <span v-if="detail" class="fs-5 ms-auto">{{ filterDatetime(detail.created_at) }}</span>
      </div>

      <div
        v-if="detail"
        :class="$style.detail">
        <dl
          class="n2br mt-5"
          v-for="column in columns"
          :key="column.name">
          <dt
            v-if="!column.data || this[column.data]"
            class="fs-4 fw-bold">【{{ column.label }}】</dt>
          <dd
            v-if="!column.data"
            v-html="detail[column.name]"></dd>
          <dd
            v-if="column.data && this[column.data]"
            v-html="this[column.data]"></dd>
        </dl>


        <ul class="btn-lists horizontal center mt-5">
          <li>
            <router-link
              class="btn btn__primary btn__medium"
              to="/admin/mail/"
              v-if="!flag.isConfirm">戻る</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NotFound from '@/views/404.vue';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Admin-Mail-Detail',
  mixins: [cf],
  components: {
    NotFound,
  },
  data() {
    return {
      flag: {
        notFound: false,
      },
      id: null,
      detail: null,
      columns: [
        { name: 'user_id', label: '送信者', data: 'sender' },
        { name: 'project_id', label: 'プロジェクト', data: 'projectTitle' },
        { name: 'title', label: 'タイトル' },
        { name: 'content', label: '本文' },
      ],
      sender: null,
      projectTitle: null,
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  created() {
    if (this.$route.params && this.$route.params.id) {
      const id = Number(this.$route.params.id);
      if (!cf.isNumber(id)) {
        this.flag.notFound = true;
        return;
      }
      this.id = id;
      if (this.helper.master.projects) {
        this.getMailDetail();
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'helper/putmaster') {
            this.getMailDetail();
          }
        });
      }
    } else {
      this.flag.notFound = true;
    }
  },
  methods: {
    getMailDetail() {
      this.axios({
        method: 'GET',
        url: '/v1/mail/get/detail',
        params: {
          id: this.id,
        },
      })
        .then((response) => {
          if (response.data.detail) {
            this.detail = response.data.detail;
            this.getSender();
            this.getProject();
          } else {
            this.flag.notFound = true;
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data.error);
          else console.log(error);
          this.flag.notFound = true;
        });
    },

    /**
     * 送信者
     */
    getSender() {
      this.axios({
        method: 'GET',
        url: '/v1/user/get/byId',
        params: { id: this.detail.user_id },
      })
        .then((response) => {
          this.sender = response.data.user.username;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data.error);
          else console.log(error);
        });
    },

    /**
     * 対象プロジェクト（ない場合もある）
     */
    getProject() {
      this.helper.master.projects.some((project) => {
        if (this.detail.project_id === project.id) {
          this.projectTitle = project.title;
          return true;
        }
        return false;
      });
    },
  },
};
</script>

<style module lang="scss">
.detail {
  dd {
    line-height: 2;
    * {
      &:not(:first-child, :last-child) {
        margin: 1em 0;
      }
    }
  }
}
</style>
