<template>
  <div class="editProfile edit">
    <div id="edit-top" class="container">
      <h1 class="title-page mb-4">プロフィール編集</h1>
      <form
        class="form horizontal"
        v-on:submit.prevent="updateFlagConfirm">

        <div class="form-contents">
          <dl class="form-group">
            <dt class="label form-label">プロフィールアイコン</dt>
            <dd
              class="detail file"
              v-if="flag.showFileInput">
              <div class="form-parts-file">
                <input
                  type="file"
                  name="file"
                  accept=".jpg, .jpeg, .png, .gif"
                  v-if="validate.view"
                  v-bind:disabled="flag.confirm"
                  v-on:change="updateFile">

                <p class="form-assistance sm"
                  >推奨サイズ：110 x 110<br>ファイルタイプ：.jpg, .jpeg, .png, .gif</p>
              </div>
            </dd>

            <dd
              class="detail d-flex align-items-center"
              v-if="!flag.showFileInput">
              <div
                class="image icon me-4"
                v-if="flag.isSaved">
                <div
                  class="image-body"
                  v-bind:style="`background-image: url(${getMyIconUrl(user)})`"></div>
              </div>
              <div
                class="image icon me-4"
                v-if="!flag.isSaved">
                <div
                  class="image-body"
                  v-bind:style="`background-image: url(${previewIconUrl})`"></div>
              </div>

              <button
                type="button"
                class="btn btn__tertiary sm"
                v-if="flag.isSaved"
                v-on:click="deleteURL">画像削除・変更</button>
              <button
                type="button"
                class="btn btn__tertiary sm"
                v-if="!flag.isSaved"
                v-on:click="deletePreviewImg">画像削除・変更</button>
            </dd>
          </dl>
        </div>


        <div
          class="form-contents"
          v-if="formItems.length">
          <dl
            class="form-group"
            v-for="(item, i) in formItems"
            v-bind:key="i">
            <dt class="label">
              <label
                class="form-label"
                v-bind:for="item.name">{{ item.label }}</label>
            </dt>
            <dd
              class="detail"
              v-bind:class="item.type">
              <div class="form-parts">
                <input
                  :id="item.name"
                  :type="item.type"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'text'"
                  @:change="updateValue($event, i)">

                <textarea
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'textarea'"
                  @:change="updateValue($event, i)"></textarea>

                <select
                  :id="item.name"
                  :name="item.name"
                  :disabled="flag.confirm"
                  v-model="item.value"
                  v-if="item.type === 'select'">
                  <option value="0">選択してください</option>
                  <option
                    v-for="option in item.options"
                    :key="option.value"
                    :value="option.value"
                    :selected="option.value === user[item.name]">{{ option.label }}</option>
                </select>
              </div>
              <p
                class="form-assistance"
                v-if="item.comment"
                v-html="item.comment"></p>
            </dd>
          </dl>
        </div>


        <div class="form-contents">
          <div class="form-group mt-sm">
            <ul class="btn-lists horizontal">
              <li v-if="!flag.confirm">
                <button
                  type="submit"
                  class="btn btn__primary sm"
                  id="btn_check"
                  >確認</button>
              </li>
              <li v-if="!flag.confirm">
                <router-link
                  to="/account/"
                  class="btn btn__tertiary sm">戻る</router-link>
              </li>
              <li v-if="flag.confirm">
                <div
                  class="btn btn__primary sm"
                  id="btn_submit"
                  v-on:click="submit">登録</div>
              </li>
              <li v-if="flag.confirm">
                <div
                  class="btn btn__tertiary sm"
                  id="btn_back"
                  v-on:click="updateFlagConfirm">編集画面に戻る</div>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { cloneDeep } from 'lodash';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-profile',
  mixins: [cf],
  data() {
    return {
      file: null,
      previewIconUrl: null,
      validate: {
        size: false,
        view: true,
      },
      flag: {
        confirm: false,
        showFileInput: true,
        isSaved: true,
      },
      formItems: [
        {
          name: 'username',
          type: 'text',
          label: 'お名前',
          value: null,
          comment: '他の支援者様には公開されません',
        },
        {
          name: 'displayname',
          type: 'text',
          label: '表示名',
          value: null,
          comment: 'サイト内で表示される名前です',
        },
        {
          name: 'profile',
          type: 'textarea',
          label: 'プロフィール',
          value: null,
        },
        {
          name: 'ageGroup',
          type: 'select',
          label: '年代',
          value: null,
          options: null,
        },
      ],
    };
  },
  created() {
    if (this.user.email) {
      this.setUserData();
      this.setAgeOptions();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.setUserData();
        } else if (mutation.type === 'helper/putmaster') {
          this.setAgeOptions();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** state.userの値をフォームにセット */
    setUserData() {
      const clone = cloneDeep(this.user);
      const keys = Object.keys(clone);
      // フォーム項目に該当する値のみを抽出
      this.formItems.forEach((item, i) => {
        if (keys.includes(item.name)) {
          this.formItems[i].value = clone[item.name];
        }
      });

      // アイコンが存在する
      if (clone.urls.icon.length
        && clone.urls.icon[0].url) {
        this.flag.showFileInput = false;
      }
    },

    /** state.helper.master.agesをageGroupのoptionsにセット */
    setAgeOptions() {
      this.formItems.some((item) => {
        if (item.name === 'ageGroup') {
          item.options = this.$store.state.helper.master.ages;
          return true;
        }
        return false;
      });
    },

    /** 確認と編集の切り替え */
    updateFlagConfirm(e) {
      const id = e.currentTarget.id;
      const next = id && id === 'btn_back';
      this.flag.confirm = !next;
      if (next) {
        this.$scrollTo('#edit-top');
      }
    },

    /** プロフィールテキストの更新 */
    updateValue(e, index) {
      const value = e.currentTarget.value;
      this.formItems[index].value = value;
    },

    /** 画像の更新 */
    updateFile(e) {
      const files = e.target.files || e.dataTransfer.files;

      // files.lengthが0の場合はキャンセル時
      if (!files.length) {
        this.file = null;
        return;
      }

      // ファイルのサイズチェック（上限2mb）
      const limit = 1024 * 1024 * 2;
      if (files[0].size > limit) {
        this.validate.size = true;
        // ファイルのリセットは非表示→表示で生成しなおす
        this.validate.view = false;
        this.$nextTick(() => {
          this.validate.view = true;
        });
        return;
      }

      // ファイルを格納
      this.file = files[0];
      this.validate.size = false;

      // プレビュー表示
      this.previewIcon();
    },

    previewIcon() {
      const path = window.URL.createObjectURL(this.file);

      this.previewIconUrl = path;
      this.flag.isSaved = false;
      this.flag.showFileInput = false;
    },

    /**
     * 確認後のサブミット
     * ファイルが存在する場合は更新・登録
     * ファイル削除時にはnullを登録
     * それ以外はユーザ情報のみ更新
     */
    submit() {
      this.showLoading();

      if (this.file) {
        // 画像更新・登録
        this.uploadFile();
      } else if (this.user.urls.icon.length
        && !this.user.urls.icon[0].url) {
        // 画像URLをnullに更新
        this.updateUrl({ uploaded: { s3Path: null } });
      } else {
        // ユーザ情報だけ更新
        this.updateBaseData();
      }
    },

    /** ファイルのアップロード */
    uploadFile() {
      // 保存時formDataに落とし込む
      const form = new FormData();
      form.append('file', this.file);

      this.axios({
        method: 'POST',
        url: '/v1/user/set/uploadfile',
        data: form,
        params: {
          id: this.user.id,
          environment: this.helper.env.name,
        },
      })
        .then((response) => {
          // URLの紐付けを保存
          this.updateUrl(response.data.data);
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    /** URLの紐付け更新 */
    updateUrl(uploadData) {
      // 既存の紐付けがあるか新規登録か
      const id = this.user.urls.icon.length
        ? this.user.urls.icon[0].id
        : null;

      const data = {
        id,
        user_id: this.user.id,
        url: uploadData.uploaded.s3Path,
        type: 2, // アイコンは2
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateUrl',
        data,
      })
        .then(() => {
          // 初期化
          this.file = null;
          // アイコンの表示非表示
          const next = uploadData.uploaded.s3Path === null;
          this.flag.showFileInput = next;
        })
        .catch((error) => {
          alert('プロフィール画像の更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.updateBaseData();
        });
    },

    /** 基本データの更新 */
    updateBaseData() {
      const data = { id: this.user.id };
      this.formItems.forEach((item) => {
        data[item.name] = item.value;
      });

      this.axios({
        method: 'POST',
        url: '/v1/user/set/update',
        data,
      })
        .then(() => {
          alert('プロフィールを更新しました。');
          this.flag.confirm = false;
          this.$store.dispatch('user/update', null, { root: true });
        })
        .catch((error) => {
          alert('プロフィールの更新に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** 画像URL削除 */
    deleteURL() {
      this.user.urls.icon[0].url = null;
      // input[type=file]の表示
      this.flag.showFileInput = true;
    },

    /** プレビュー画像URL削除 */
    deletePreviewImg() {
      this.file = null;
      this.previewIconUrl = null;
      // input[type=file]の表示
      this.flag.showFileInput = true;
    },
  },
};
</script>
