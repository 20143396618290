<template>
  <div class="delete-card">
    <p>以下のカードを削除します。よろしいですか？</p>
    <div :class="$style.card" class="mt-5">
      <div
        :style="`background-image: url(/img/cards/${cards[card.brand]}.png);`"
        :class="$style.icon"></div>
    </div>
    <label for="default-card">**** **** **** {{ card.last4 }}</label>
    <div>
      <ul class="btn-lists horizontal mt-4">
        <li>
          <button
            class="btn btn__primary"
            v-on:click="deleteCard">削除</button>
        </li>
        <li>
          <button
            class="btn btn__tertiary"
            v-on:click="hideModal">キャンセル</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'delete-card',
  props: ['data'],
  data() {
    return {
      card: {},
      cards: {
        Visa: 'visa',
        MasterCard: 'mastercard',
        'American Express': 'amex',
        JCB: 'jcb',
        'Diners Club': 'diners-club',
        Discover: 'discover', 
      },
    };
  },
  created() {
    this.user.cards.cards.forEach((card) => {
      if (this.data.card_id === card.id) {
        this.card.brand = card.brand;
        this.card.last4 = card.last4;
      }
    });
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    hideModal() {
      this.$store.dispatch('modal/messages/hideModal', null, { root: true });
    },

    deleteCard() {
      this.showLoading();
      const data = this.data;
      this.axios({
        method: 'POST',
        url: '/v1/stripe/customer/set/deleteCard',
        data,
      })
        .then(() => {
          // storeユーザー情報更新
          this.$store.dispatch('user/update', null, { root: true });
          const sendData = {
            customer: {
              customer_id: this.user.customer.customer_id,
            },
          };
          this.$store.dispatch('user/cards/getCustomer', sendData, { root: true });
          alert('クレジットカードを削除しました。');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          alert('クレジットカードの削除に失敗しました。お手数ですが、再度やり直してください。');
        })
        .finally(() => {
          this.hideLoading();
          this.hideModal();
        });
    },
  },
};
</script>

<style lang="scss" module>
.list {
  margin: .3em 0;
}
.card {
  display: inline-block;
  width: 2.4em;
  border-radius: 2px;
  overflow: hidden;
  margin: auto 5px auto 0;
}

.icon {
  padding-top: percentage(142/220);
  background-size: cover;
}
</style>

