<template>
  <div class="resendRegisterMail">
    <form class="form vertical">
      <div class="form-contents">
        <dl class="form-group">
          <dt class="label"><label class="form-label">メールアドレス</label></dt>
          <dd class="detail">
            <div class="form-parts">
              <input
                type="email"
                v-model="email"
                @input="updateValue">
            </div>
          </dd>
        </dl>
      </div>

      <div :class="$style.btnsWrap">
        <ul class="btn-lists horizontal center">
          <li>
            <button
              class="btn btn__medium btn-primary"
              @click="submit">メール再送</button>
          </li>
          <li>
            <router-link
              class="btn btn__medium btn__primary"
              to="/admin/">戻る</router-link>
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Ademin-ResendRegisterMail',
  mixins: [cf],
  components: {
  },
  data() {
    return {
      email: null,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    // ローダー非表示
    // this.$emit('loader', false);
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    updateValue(e) {
      this.email = e.currentTarget.value;
    },

    submit(e) {
      e.preventDefault();
      if (!this.email) return;
      this.showLoading();

      this.axios({
        method: 'POST',
        url: '/v1/user/resendRegisterMail',
        data: {
          email: this.email,
        },
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
          this.hideLoading();
          this.email = null;
          alert('再送処理完了');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>


<style module lang="scss">
.btnsWrap {
  margin-top: 5rem;
}
</style>
