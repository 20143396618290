<template>
  <div class="login-only">
    <div class="text-center">
      <p class="fs-3 fw-bold">{{ data.title }}にはログインが必要です。</p>
      <p
        class="fs-5 mt-4"
        v-if="data.description"
        v-html="data.description"></p>
    </div>

    <div class="buttons mt-5">
      <ul class="btn-lists horizontal justify-content-center">
        <li>
          <router-link
            class="btn btn__primary"
            to="/signup/"
            v-on:click="hideModal">新規会員登録</router-link>
        </li>
        <li>
          <router-link
            class="btn btn__primary"
            to="/login/"
            v-on:click="hideModal">ログイン</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'loginOnly',
  mixins: [cf],
  props: ['data'],
  methods: {
    ...mapActions('modal/messages', ['hideModal']),
  },
};
</script>
