<template>
  <div :class="$style.container">
    <img
      :class="$style.singleSliderImage"
      v-if="images.length === 1"
      :src="images[0].src"
    >
    <swiper
      v-if="images.length > 1"
      @swiper="initSwiper"
      :autoplay="false"
      :speed="1000"
    >
      <swiper-slide v-for="(image, index) of images" :virtualIndex="index" :key="index">
        <div :class="$style.sliderItem">
          <div
            :class="$style.sliderItem__blur"
            :style="{backgroundImage: `url(${image.src})`}"
          />
          <div :class="$style.sliderItem__inner">
            <img
              :src="image.src"
              :alt="image.src"
              :class="$style.sliderItem__img"
            />
          </div>
        </div>
      </swiper-slide>
      <ul :class="$style.nav">
        <li
          v-for="(image, index) of images"
          :key="index"
          :class="$style.navItem"
        >
          <button
            @click="slideTo(index)"
            :class="$style.navItem__trigger"
          >
            <img
              :src="image.src"
              :alt="image.src"
              :class="$style.navItem__img"
            />
          </button>
        </li>
      </ul>
    </swiper>
  </div>
</template>

<script>
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

SwiperCore.use([Autoplay]);

export default {
  name: 'HeroSlider',
  components: {
    Swiper,
    SwiperSlide,
  },
  props: ['images'],
  data() {
    return {
      swiper: null,
    };
  },
  methods: {
    initSwiper(swiper) {
      this.swiper = swiper;
    },
    slideTo(index) {
      this.swiper.slideTo(index);
    },
  },
};
</script>

<style>
.swiper-wrapper {
  transition-timing-function: cubic-bezier(0.11, 0.93, 0.23, 0.99);
  cursor: grab;
}

.swiper-wrapper:active {
  cursor: grabbing;

}
</style>

<style module>
.container {
  display: block;
  width: 100%;
}

.singleSliderImage {
  display: block;
  margin: auto;
}

.sliderItem {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  background-color: #000;
}

.sliderItem__blur {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent center no-repeat;
  background-size: cover;
  z-index: 1;
  filter: blur(15px);
  opacity: 0.9;
  transform: translate3d(0, 0, 0) scale(1.1);
  transform-origin: center;
}

.sliderItem__inner {
  position: relative;
  display: block;
  max-width: 845px;
  width: 100%;
  z-index: 2;
}

.sliderItem__inner::after {
  content: '';
  display: block;
  width: 100%;
  padding-top: 66.272189%;
}

.sliderItem__img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: auto;
  object-fit: cover;
  object-position: center;
}

.nav {
  display: flex;
  gap: min(3%, 24px);
  margin-top: 24px;
}

.navItem {
  width: 30%;
  max-width: 80px;
}

.navItem__trigger {
  display: block;
}

.navItem__img {
  display: block;
}

/* slideImg 845px と 左右の余白32px * 2 以下のときはblurは 不要なのでdisplay: noneする */
@media screen and (max-width: 908px) {
  .sliderItem__blur {
    display: none;
  }
}

</style>
