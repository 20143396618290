const actions = {
  toggleMenu({ commit, state }) {
    if (state.isOpen) {
      commit('closeMenu');
    } else {
      commit('openMenu');
    }
  },
  openMenu({ commit }) {
    commit('openMenu');
  },
  closeMenu({ commit }) {
    commit('closeMenu');
  },
};

const mutations = {
  openMenu(state) {
    state.isOpen = true;
  },
  closeMenu(state) {
    state.isOpen = false;
  },
};

const state = {
  isOpen: false,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
