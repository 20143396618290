<template>
  <div>
    <div :class="$style.table_flame">
      <table>
        <tr :class="$style.table_head"><th>協賛者名</th><th>金額</th><th>コース</th><th>口数</th><th>返金</th><th>返金額</th></tr>
        <tr v-for="sponser in sponsors" :key="sponser"
          :class="$style.table_item">
          <td>{{ getUsername(sponser) }}</td>
          <td>{{ sponser.amount }}</td>
          <td>{{ sponser.price }}</td>
          <td>{{ sponser.lot }}</td>
          <td>
            <div v-if="sponser.flag == 1">
              <button
                @click="refund(sponser)"
                :class="$style.refundBtn"
                :disabled="disableRefundBtn(sponser.id)">返金</button>
            </div>
            <div v-else-if="sponser.flag === 11">{{ sponser.refunds.amount }}円　返金済</div>
            <div v-else>返金不可</div>
          </td>
          <td>
            <input
              v-if="sponser.flag == 1"
              v-model="refundPrices[sponser.id]"
              type="number"
              placeholder="金額を入力">
            <div v-else-if="sponser.flag === 11">{{ sponser.refunds.amount }}円　返金済</div>
            <div v-else>返金不可</div>
          </td>
        </tr>
      </table>
      <div class="bulk-refund">
        <div>
          <p>まとめて返金</p>
        </div>
        <div>
          <select
            v-model="selectedType"
            @change="updateSelectType()">
            <option :value="0">選択</option>
            <option
              v-for="refundAmount in refundSelect"
              :key="refundAmount"
              :value="refundAmount.value">{{ refundAmount.label }}</option>
          </select>
          <input
            v-if="selectedType === 3"
            type="number"
            placeholder="金額を入力"
            v-model="bulkAmount"
            @input="setAmount()">
          <button
            :class="$style.refundBtn"
            :disabled="disableBulkRefundBtn()"
            @click="bulkRefund">一括返金</button>
        </div>
      </div>
      <p v-if="selectedType === 1">一括で全額返金する場合はあらかじめ決済手数料の差額を入金する必要があります</p>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'adomin-refund-detail',
  data() {
    return {
      flag: {},
      project: null,
      slug: null,
      sponsors: null,
      refundPrices: {},
      refundSelect: [
        {
          label: '全額',
          value: 1,
        },
        {
          label: '半額',
          value: 2,
        },
        {
          label: '指定金額',
          value: 3,
        },
      ],
      selectedType: 0,
      bulkAmount: null,
    };
  },
  created() {
    this.slug = this.$route.params.slug;

    if (this.helper.master.projects) {
      this.getProjectDetail();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.getProjectDetail();
        }
      });
    }
  },
  watch: {
    project: {
      handler() {
        this.getSales();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {
    getProjectDetail() {
      const slugs = this.helper.master.projects.map((r) => { const slug = r.slug; return slug; });
      if (!slugs.includes(this.slug)) { this.completion = true; this.flag.notFound = true; return; }
      this.$store.state.helper.master.projects.some((r) => {
        if (this.slug === r.slug) {
          this.project = r;
          /**
           * ローカル・dev環境ではコネクトアカウントを固定
           */
          if (this.$store.state.helper.env.name !== 'production') {
            this.project.connectedAccountId = 'acct_1Jp3xnPEsH6PE97m';
          }
          this.completion = true;
          return true;
        }
        return false;
      });
    },

    // 協賛者情報取得
    getSales() {
      const params = {
        project_id: this.project.id,
        intent: 1,
        refunds: 1,
      };
      this.axios({
        method: 'GET',
        url: '/v1/sales/get/list',
        params,
      })
        .then((response) => {
          const res = response.data.data;
          this.sponsors = res;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error.message);
        });
    },

    getUsername(user) {
      return user.users.username || user.users.displayname || user.users.corporatename || user.displayname.displayname;
    },

    // 返金ボタンの無効チェック
    disableRefundBtn(id) {
      let result = true;
      if (this.refundPrices[String(id)] && this.refundPrices[String(id)] >= 0) result = false;
      return result;
    },

    // 一括返金ボタンの無効チェック
    disableBulkRefundBtn() {
      let result = true;
      if (this.selectedType && this.selectedType >= 1) {
        if (this.selectedType === 3 && this.bulkAmount !== null && this.bulkAmount >= 0) result = false;
        if (this.selectedType === 1 || this.selectedType === 2) result = false;
      }
      return result;
    },

    setAmount() {
      this.sponsors.forEach((sale) => {
        if (sale.flag === 1) this.refundPrices[sale.id] = this.bulkAmount;
      });
    },

    updateSelectType() {
      this.refundPrices = {};
      if (this.selectedType === 1 || this.selectedType === 2) {
        const denominator = this.selectedType === 1 ? 1 : 2;
        this.sponsors.forEach((sale) => {
          if (sale.flag === 1) this.refundPrices[sale.id] = (sale.amount / denominator);
        });
      }
    },

    refund(sales) {
      // 金額の入力がされていないor0が入力されている
      if (!this.refundPrices[sales.id] || this.refundPrices[sales.id] === 0 || this.refundPrices[sales.id] <= 0) {
        alert('返金額を入力してください');
        return true;
      }
      // 協賛額より返金額の方が大きい時
      if (this.refundPrices[sales.id] > sales.amount) {
        alert('返金額が協賛金額の合計を超えています');
        return true;
      }

      const checkFlag = window.confirm(`${this.refundPrices[sales.id]}円の返金を行います`);
      if (!checkFlag) return true;

      // 返金元のコネクトアカウントを取得
      const connectedAccountId = this.helper.env.name !== 'production'
        ? 'acct_1Jp3xnPEsH6PE97m'
        : this.project.connectedAccountId;

      const data = {
        intent_id: sales.stripeIntent.intent_id,
        amount: this.refundPrices[sales.id],
        connectedAccountId,
      };

      this.showLoading();
      this.axios({
        method: 'POST',
        url: '/v1/stripe/intent/set/refund',
        data,
      })
        .then((response) => {
          console.log(response);
          alert('返金が完了しました。');
          this.refundPrices = {};
          this.getSales();
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
          alert('返金に失敗しました');
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    async bulkRefund() {
      // 返金額が最も低い協賛金額を超えていないかを検証
      this.sponsors.forEach((sale) => {
        if (sale.flag === 1) {
          if (this.bulkAmount > sale.amount) {
            alert('返金額が協賛金額を超えています');
            return true;
          }
        }
      });

      // 全てのsalesのフラグが1以外だったとき（返金が事実上できない）
      const salesRefundFlag = this.sponsors.every((elem) => elem.flag !== 1);
      if (salesRefundFlag) {
        alert('返金可能な購入情報がありません');
        return true;
      }

      // 残高確認
      if (this.selectedType === 1) {
        const flag = confirm('全額返金する場合はあらかじめ決済手数料の差額を入金する必要があります\nstripeのアカウントの残高は確認しましたか？');
        if (!flag) return true;
      }

      // 確認
      const checkFlag = confirm('一括で返金を実施します、stripeの残高は確認しましたか？');
      if (!checkFlag) return true;

      this.showLoading();
      // 返金元のコネクトアカウントを取得
      const connectedAccountId = this.helper.env.name !== 'production'
        ? 'acct_1Jp3xnPEsH6PE97m'
        : this.project.connectedAccountId;

      await Promise.all(
        this.sponsors.map(async (sale) => {
          // 対象、未返金or未キャンセルのものだけ
          if (sale.flag === 1) {
            const data = {
              intent_id: sale.stripeIntent.intent_id,
              amount: this.refundPrices[sale.id],
              connectedAccountId,
            };

            this.axios({
              method: 'POST',
              url: '/v1/stripe/intent/set/refund',
              data,
            })
              .then((response) => {
                console.log(response);
                alert('一括返金が完了しました');
                this.refundPrices = {};
                this.getSales();
              })
              .catch((error) => {
                if (error.message) console.log(error.message);
                else console.log(error);
                alert('返金に失敗しました');
              })
              .finally(() => {
                this.hideLoading();
              });
          }
        }),
      );
      console.log('refund success');
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },
  },
};
</script>

<style lang="scss" module>
.table_flame {
  table, th, td {
      border: 1px solid #444444;
      padding: 10px;
  }
}

.refundBtn {
  padding: 0 5px;
  border: 1px solid black;
  border-radius: 5px;
  background-color: #555555;
  color: #CCCCCC;
}
</style>
