const actions = {
  changeIsTransparent({ commit }, args) {
    commit('changeIsTransparent', args);
  },
};

const mutations = {
  changeIsTransparent(state, args) {
    state.isTransparent = args;
  },
};

const state = {
  isTransparent: false,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
