import { createRouter, createWebHistory } from 'vue-router';

/** @Global */
import Home from '@/views/Home.vue';
import NotFound from '@/views/404.vue';
import ProjectDetail from '@/views/projects/_slug.vue';
// import Subscription from '@/views/projects/Subscription.vue';

/** @Pages */
import Policy from '@/views/Pages/Policy.vue';
import Terms from '@/views/Pages/Terms.vue';
import Commercial from '@/views/Pages/Commercial.vue';

/** @Accounts */
import Signup from '@/views/Accounts/Signup.vue';
import SignupPassword from '@/views/Accounts/SignupPassword.vue';
import Login from '@/views/Accounts/Login.vue';
import ForgotPassword from '@/views/Accounts/ForgotPassword.vue';
import ResetPassword from '@/views/Accounts/ResetPassword.vue';
import Account from '@/views/Accounts/Account.vue';

/** @Edits */
import EditProfile from '@/views/Edit/Profile.vue';
import EditEmail from '@/views/Edit/Email.vue';
import UpdatedEmail from '@/views/Edit/UpdatedEmail.vue';
import EditPassword from '@/views/Edit/Password.vue';
import EditCorporate from '@/views/Edit/Corporate.vue';
import EditAddresses from '@/views/Edit/Addresses.vue';
// import EditSubscription from '@/views/Edit/SubscriptionList.vue';
import EditPayment from '@/views/Edit/Payment.vue';
import EditPaymentList from '@/views/Edit/PaymentList.vue';

/** @Payment */
import PaymentWrapper from '@/views/Payment/Wrapper.vue';
import PaymentInfo from '@/views/Payment/Info.vue';
import PaymentMethod from '@/views/Payment/Method.vue';
import PaymentCard from '@/views/Payment/Card.vue';
import PaymentBank from '@/views/Payment/Bank.vue';
import PaymentConfirm from '@/views/Payment/Confirm.vue';
import PaymentComplete from '@/views/Payment/Complete.vue';

/** @Admin */
import AdminWrapper from '@/views/Admin/Wrapper.vue';
import AdminResendRegisterMail from '@/views/Admin/ResendRegisterMail.vue';
// import AdminInformationRegister from '@/views/Admin/Information/Register.vue';
// import AdminInformation from '@/views/Admin/Information/List.vue';
// import AdminProjectRegister from '@/views/Admin/Project/Register.vue';
// import AdminProject from '@/views/Admin/Project/List.vue';
import AdminMailRegister from '@/views/Admin/Mail/Register.vue';
import AdminMail from '@/views/Admin/Mail/List.vue';
import AdminMailDetail from '@/views/Admin/Mail/Detail.vue';
import AdminRefund from '@/views/Admin/Refund/Refunds.vue';
import AdminRefundDetail from '@/views/Admin/Refund/RefundsDetail.vue';

const routes = [
  /** @Global */
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/projects/:slug',
    name: 'project-detail',
    component: ProjectDetail,
  },
  // {
  //   path: '/projects/subscription',
  //   name: 'project-subscription',
  //   component: Subscription,
  // },

  /** @pages */
  {
    path: '/policy',
    name: 'Policy',
    component: Policy,
    meta: {
      title: 'プライバシーポリシー',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms,
    meta: {
      title: '利用規約',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/commercial',
    name: 'Commercial',
    component: Commercial,
    meta: {
      title: '特定商取引法に基づく表記',
      description: '',
      og_image: '',
    },
  },

  /** @Accounts */
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'ログイン',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    meta: {
      title: '新規仮登録',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/signup/password',
    name: 'SignupPassword',
    component: SignupPassword,
    meta: {
      title: '新規本登録',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
    meta: {
      title: 'パスワード再発行',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: ResetPassword,
    meta: {
      title: 'パスワード再設定',
      description: '',
      og_image: '',
    },
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      title: 'アカウント情報',
      description: '',
      og_image: '',
    },
  },

  /** @Edits */
  {
    path: '/edit/profile',
    name: 'edit-profile',
    component: EditProfile,
    meta: {
      title: 'プロフィール編集',
    },
  },
  {
    path: '/edit/email',
    name: 'edit-email',
    component: EditEmail,
    meta: {
      title: 'メールアドレス更新',
    },
  },
  {
    path: '/edit/email/updated',
    name: 'updated-email',
    component: UpdatedEmail,
    meta: {
      title: 'メールアドレス更新完了',
    },
  },
  {
    path: '/edit/password',
    name: 'edit-password',
    component: EditPassword,
    meta: {
      title: 'パスワード変更',
    },
  },
  {
    path: '/edit/corporate',
    name: 'edit-corporate',
    component: EditCorporate,
    meta: {
      title: '企業情報変更',
    },
  },
  {
    path: '/edit/addresses',
    name: 'edit-addresses',
    component: EditAddresses,
    meta: {
      title: 'お届け先住所変更',
    },
  },
  // {
  //   path: '/edit/subscription',
  //   name: 'edit-subscription',
  //   component: EditSubscription,
  //   meta: {
  //     title: 'サブスクリプションプラン一覧',
  //   },
  // },
  {
    path: '/edit/payment',
    name: 'edit-payment',
    component: EditPayment,
    meta: {
      title: 'お支払い方法変更',
    },
  },
  {
    path: '/edit/payment-list',
    name: 'edit-payment-list',
    component: EditPaymentList,
    meta: {
      title: 'お支払い履歴',
    },
  },

  /** @payment */
  {
    path: '/payment',
    name: 'payment',
    component: PaymentWrapper,
    meta: {
      title: '支援内容確認',
    },
    children: [
      {
        path: 'info/:project/:return',
        name: 'payment-info-step1',
        component: PaymentInfo,
      },
      {
        path: 'method/:project/:return',
        name: 'payment-method-step2',
        component: PaymentMethod,
      },
      {
        path: 'card/:project/:return',
        name: 'payment-card-step3',
        component: PaymentCard,
      },
      {
        path: 'bank/:project/:return',
        name: 'payment-bank-step3',
        component: PaymentBank,
      },
      {
        path: 'confirm/:project/:return',
        name: 'payment-confirm-step4',
        component: PaymentConfirm,
      },
      {
        path: 'complete/:project/:return',
        name: 'payment-complete-step5',
        component: PaymentComplete,
      },
    ],
  },

  /** @Admin */
  {
    path: '/admin',
    name: 'admin',
    component: AdminWrapper,
    meta: {
      title: '管理者画面',
    },
    children: [
      {
        path: 'resendRegisterMail',
        name: 'admin-resendRegisterMail',
        component: AdminResendRegisterMail,
      },
      // {
      //   path: 'information',
      //   name: 'admin-information',
      //   component: AdminInformation,
      // },
      // {
      //   path: 'information/register',
      //   name: 'admin-information-register',
      //   component: AdminInformationRegister,
      // },
      // {
      //   path: 'project',
      //   name: 'admin-project',
      //   component: AdminProject,
      // },
      // {
      //   path: 'project/register',
      //   name: 'admin-project-register',
      //   component: AdminProjectRegister,
      // },
      {
        path: 'mail',
        name: 'admin-mail',
        component: AdminMail,
      },
      {
        path: 'mail/register',
        name: 'admin-mail-register',
        component: AdminMailRegister,
      },
      {
        path: 'mail/:id',
        name: 'admin-mail-detail',
        component: AdminMailDetail,
      },
      {
        path: 'refund',
        name: 'adomin-refund',
        component: AdminRefund,
      },
      {
        path: 'refund/:slug',
        name: 'adomin-refund-detail',
        component: AdminRefundDetail,
      },
    ],
  },

  /** @notfound */
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: {
      title: 'お探しのページは見つかりませんでした',
      description: '',
      og_image: '',
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // ページ遷移時
  scrollBehavior(to) {
    let position = {};
    if (!to.hash) {
      // ハッシュがない場合ページトップへ戻す
      position = { top: 0 };
    } else {
      // ハッシュが存在する場合スクロール
      position = { selector: to.hash };
    }
    return position;
  },
});

export default router;
