<template>
  <div class="updated-email edit">
    <div class="container">
      <h2 class="title-page lg">メールアドレス更新</h2>

      <div
        class="wrap-updated"
        v-if="flag.updated">
        <p>メールアドレスを更新しました。次回ログイン時から更新したメールアドレスをご利用ください。</p>

        <div class="buttons">
          <ul class="btn-lists d-flex horizontal justify-content-center">
            <li
              v-if="user.login.isLogin">
              <router-link
                class="btn btn__primary sm"
                to="/account/">マイページ</router-link>
            </li>
            <li
              v-if="user.login.isLogin">
              <button
                class="btn btn__secondary sm"
                v-on:click="logout">ログアウト</button>
            </li>
          </ul>
        </div>
      </div>

      <div
        class="wrap-error"
        v-if="flag.invalid">
        <p>アクティベートキーが無効です。<br>メールアドレス更新リクエスト時のメールを確認し、メール記載のURLからアクセスしてください。</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'updated-email',
  mixins: [cf],
  data() {
    return {
      activatekey: null,
      flag: {
        invalid: true,
        updated: false,
        mismatch: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.activate) {
      // アクティベートキーがあればメールアドレスを変更
      this.flag.invalid = false;
      this.activatekey = query.activate;

      if (this.user.email) {
        this.updateLoginEmailAddress();
      } else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/setUserData') {
            this.updateLoginEmailAddress();
          }
        });
      }
    }
  },
  computed: {
    ...mapState(['user', 'page']),
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** ログイン用メールアドレスを更新 */
    updateLoginEmailAddress() {
      this.showLoading();

      const data = {
        id: this.user.id,
        email: this.user.updateEmail,
        updateEmail: null,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateLoginEmail',
        data,
      })
        .then(() => {
          this.flag.updated = true;
          this.$store.dispatch('user/update', null, { root: true });
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            this.flag.invalid = true;
          } else {
            console.log(error);
          }
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
