<template>
  <div class="step2">
    <div class="payment-header">
      <p>お支払い方法をお選びください</p>
    </div>

    <div class="form">
      <div class="form-contents">
        <dl class="form-group">
          <dd class="detail radio-wrap">
            <input
              type="radio"
               name="paymentmethod"
               id="card"
               @change="updateMethod"
               checked>
            <label for="card">クレジットカード</label>

            <CardIcons />
          </dd>
        </dl>
        <dl class="form-group">
          <dd class="detail radio-wrap">
            <input
              type="radio"
              name="paymentmethod"
              id="bank"
              @change="updateMethod">
            <label for="bank">銀行振込</label>
            <p class="form-assistance">振込手数料は購入者さま負担となります。ご了承ください。</p>
          </dd>
        </dl>
      </div>

      <div :class="$style.btnsWrap">
        <ul class="btn-lists horizontal center flex-row-reverse">
          <li>
            <button
              class="btn btn__primary"
              @click="handleNext">次へ進む</button>
          </li>
          <li>
            <router-link
              class="btn btn__tertiary"
              :to="`/payment/info/${slugs.project}/${slugs.return}/`">戻る</router-link>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CardIcons } from '@/components/parts/Icon';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'PaymentStep2',
  mixins: [cf],
  props: ['project', 'returnItem', 'slugs'],
  components: {
    CardIcons,
  },
  data() {
    return {
      method: 'card',
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    const ls = cf.getLocalStorage('fk');
    if (!ls || !ls.lot) { this.$emit('invalid', true); }
    // ローダー非表示
    this.$emit('loader', false);
    this.moveToStep(2);
  },
  methods: {
    ...mapActions('payment', [
      'moveToStep',
    ]),

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 支払い方法の選択 */
    updateMethod(e) {
      const id = e.currentTarget.id;
      this.method = id;
    },

    /**
     * 支払い方法によって次のリンク先を振り分け
     * 【支払いフロー】
     * カードの場合
     *   1. 顧客取得・作成
     *   2. カード登録・選択
     *   3. 確認画面
     *   4. 支払い作成
     * 銀行振込の場合
     *   1. 確認画面
     *   3. 顧客取得・作成
     *   2. 支払い作成
     *   3. 銀行口座の周知
     */
    handleNext(e) {
      e.preventDefault();

      if (this.method === 'card') {
        // カード情報選択・登録のため/payment/card/へ
        this.$router.push({
          path: `/payment/card/${this.slugs.project}/${this.slugs.return}/`,
        });
      } else if (this.method === 'bank') {
        // 顧客登録してから確認画面へ遷移、intentを作成して口座周知
        if (this.user.customer) {
          // 顧客登録済みならばページ遷移
          this.$router.push({
            path: `/payment/confirm/${this.slugs.project}/${this.slugs.return}/`,
            query: { paymentType: 2 },
          });
        } else {
          // 未登録の場合はここで顧客登録
          this.createCustomer();
        }
      }
    },

    /**
     * stripe_customer登録
     */
    createCustomer() {
      this.showLoading();

      const data = {
        customerData: {
          email: this.user.email,
          name: this.user.username,
          metadata: {
            user_id: this.user.id,
          },
        },
      };

      this.axios({
        method: 'POST',
        url: '/v1/stripe/customer/set/register',
        data,
      })
        .then(() => {
          // storeユーザー情報更新
          this.$store.dispatch('user/update', null, { root: true });
          // 顧客登録後にページ遷移
          this.$router.push({
            path: `/payment/confirm/${this.slugs.project}/${this.slugs.return}/`,
            query: { paymentType: 2 },
          });
        })
        .catch((error) => {
          alert('顧客情報登録に失敗しました。お手数ですが管理者までご連絡ください。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>


<style module lang="scss">
.btnsWrap {
  margin-top: 5rem;
}
</style>
