<template>
  <a :href="href" :class="`${$style.socialIcon} ${$style[type]}`">
  </a>
</template>

<script>
export default {
  name: 'SocialIcon',
  props: ['href', 'type'],
};
</script>

<style module>
.socialIcon {
  display: block;
  width: 48px;
  height: 48px;
  background: transparent center no-repeat;
  background-size: contain;
}

.socialIcon.fb {
  border-radius: 100%;
  background-image: url('~@/assets/images/social/icon-fb.png');
}

.socialIcon.tw {
  border-radius: 100%;
  background-image: url('~@/assets/images/social/icon-tw.png');
}

.socialIcon.ig {
  background-image: url('~@/assets/images/social/icon-ig.png');
}

@media screen and (max-width: 767px) {
  .socialIcon {
    width: 32px;
    height: 32px;
  }
}
</style>
