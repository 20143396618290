<template>
  <div :class="[$style.container, $style[size]]">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ContentsContainer',
  props: {
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>

<style module>
.container {
  max-width: calc(1120px + 32px * 2);
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
  margin-left: auto;
  margin-right: auto;
}

.container.sm {
  max-width: 600px;
}

@media screen and (max-width: 767px) {
  .container {
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
