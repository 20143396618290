<template>
  <div class="form-contents">
    <dl
      class="form-group"
      v-for="(item, i) in items"
      v-bind:key="i">
      <dt class="label" :class="{ required: item.required }">
        <label
          class="form-label"
          v-bind:for="item.name">{{ item.label }}</label>
      </dt>
      <dd
        class="detail"
        v-bind:class="item.type">
        <div class="form-parts">
          <input
            :id="item.name"
            :type="item.type"
            :name="item.name"
            :disabled="isConfirm"
            :placeholder="item.placeholder"
            @input="invalidsCheck(item.name, $v[item.name].$invalid)"
            v-model.trim="$v[item.name].$model"
            @blur="addPref"
            v-if="(item.type === 'text' || item.type === 'email')
              && $v[item.name]">

          <input
            :id="item.name"
            :type="item.type"
            :name="item.name"
            :disabled="isConfirm"
            :placeholder="item.placeholder"
            @input="sendValue"
            v-model="this[item.name]"
            v-if="item.type === 'text' && !$v[item.name]">

          <select
            :id="item.name"
            :name="item.name"
            :disabled="isConfirm"
            @change="invalidsCheck(item.name, $v[item.name].$invalid)"
            v-model.trim="$v[item.name].$model"
            v-if="item.type === 'select'">
            <option
              v-for="option in item.options"
              :key="option.value"
              :value="option.value"
              :selected="option.value === user[item.name]">{{ option.label }}</option>
          </select>
        </div>
        <p
          class="form-assistance"
          v-if="item.comment"
          v-html="item.comment"></p>
        <p
          class="form-text text-danger"
          v-if="$v[item.name]
            && $v[item.name].$dirty
            && $v[item.name].email
            && $v[item.name].email.$invalid">正しいメールアドレスの形式で入力してください</p>
        <p
          class="form-text text-danger"
          v-if="$v[item.name]
            && $v[item.name].$dirty
            && $v[item.name].required
            && $v[item.name].required.$invalid">「{{ item.label }}」は必須項目です</p>
        <p
          class="form-text text-danger"
          v-if="$v[item.name]
            && $v[item.name].$dirty
            && $v[item.name].integer
            && $v[item.name].integer.$invalid">「{{ item.label }}」はハイフンなしで半角数値を入力してください</p>
      </dd>
    </dl>
  </div>
</template>


<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required, integer } from '@vuelidate/validators';
import { jsonp } from 'vue-jsonp';
import cf from '@/mixins/commonFunctions';


export default {
  name: 'form-addresses',
  mixins: [cf],
  props: ['items', 'isConfirm'],
  data() {
    return {
      invalids: {
        name: true,
        telNumber: true,
        zip: true,
        pref: false, // 初期値で「群馬県」選択
        city: true,
        address: true,
      },
      // vuelidateを使用しない項目
      displayname: null,
      building: null,
    };
  },
  created() {
    if (this.user.email) {
      this.setAddressData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') this.setAddressData();
      });
    }
  },
  setup() {
    const mail = ref('');
    const name = ref('');
    const telNumber = ref('');
    const zip = ref('');
    const pref = ref('群馬県');
    const city = ref('');
    const address = ref('');

    const rules = {
      mail: { email, required },
      name: { required },
      telNumber: { required, integer },
      zip: { required, integer },
      pref: { required },
      city: { required },
      address: { required },
    };

    const $v = useVuelidate(rules, {
      mail,
      name,
      telNumber,
      zip,
      pref,
      city,
      address,
    });
    return {
      mail,
      name,
      telNumber,
      zip,
      pref,
      city,
      address,
      $v,
    };
  },
  computed: {
    ...mapState(['user', 'page']),
    isGuest() {
      const ls = cf.getLocalStorage('fk');
      return ls.supportType === 'guest';
    },
  },
  methods: {
    /** this.user.addresses.main[0]に値があればセット */
    setAddressData() {
      if (this.user.addresses.main.length) {
        const updateKeys = [];
        this.items.forEach((item) => { updateKeys.push(item.name); });
        const main = this.user.addresses.main[0];
        const keys = Object.keys(main);
        keys.forEach((key) => {
          if (updateKeys.includes(key)) {
            this[key] = main[key];
            if (main[key]) this.invalids[key] = false;
          }
        });


        // ゲストユーザのメールアドレス・表示名
        this.mail = this.user.email || null;
        this.displayname = this.user.displayname || null;

        this.sendValue();
      } else {
        // 初期実装adhoc対応
        // ゲストユーザのメールアドレス・表示名
        this.mail = this.user.email || null;
        this.displayname = this.user.displayname || null;

        this.sendValue();
      }
    },

    async addPref() {
      await jsonp(`https://api.zipaddress.net/?zipcode=${this.zip}`)
        .then((response) => {
          this.pref = response.pref;
          this.city = response.address;
          this.invalids.zip = false;
          this.invalids.pref = false;
          this.invalids.city = false;
        })
        .catch((response) => {
          console.log(response);
        });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
      this.sendValue();
    },

    /** 親へデータを渡す */
    sendValue() {
      const keys = Object.keys(this.invalids);
      const bools = [];
      keys.some((key) => { bools.push(this.invalids[key]); return false; });

      // サブミット可否
      let readySubmit = !bools.includes(true);

      // TODL: 初期アドホック対応
      // メールアドレスと表示名が揃ってればOK
      if (this.$route.name.includes('payment-info')
        && this.mail) {
        readySubmit = true;
      }

      // データの受け渡し
      const data = {
        readySubmit,
        address: {
          // ゲスト用のメールと表示名
          email: this.mail,
          displayname: this.displayname,
          // お届け先情報
          name: this.name,
          telNumber: this.telNumber,
          zip: this.zip,
          pref: this.pref,
          city: this.city,
          address: this.address,
          building: this.building,
        },
      };
      this.$emit('sendValue', data);
    },
  },
};
</script>
