<template>
  <div :class="$style.container">
    <div :class="$style.circle">
      <svg
        ref='circle'
        :class="$style.svg"
        viewBox="0 0 160 160"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle
          :class="$style.gray"
          cx="80" cy="80" r="77.5"
          stroke="black" stroke-opacity="0.12"
        />
        <circle
          :class="$style.blue"
          cx="80" cy="80" r="77.5"
          stroke="#1DA6F2"
          transform="rotate(-90 80 80)"
          :stroke-dashoffset="dashoffset"
        />
      </svg>
    </div>
    <div :class="$style.inner">
      <p :class="$style.label">達成率</p>
      <p :class="$style.value" v-if="progress > 0">{{progress.toFixed(1)}}</p>
      <p :class="$style.value" v-else-if="progress === 0" >{{progress}}</p>
      <span :class="$style.unit">%</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressCircle',
  props: {
    progress: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      isShown: false,
    };
  },
  mounted() {
    this.setIntersectionObserver();
  },
  beforeUnmount() {
    this.unobserve();
  },
  computed: {
    dashoffset() {
      let progress = this.progress || 0;
      progress = progress > 100 ? 100 : progress;
      return this.isShown ? (1 - progress / 100) * 488 : 488;
    },
  },
  methods: {
    setIntersectionObserver() {
      const options = {
        rootMargin: '-15% 0px',
      };
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach(({ isIntersecting }) => {
          this.isShown = isIntersecting;
          if (isIntersecting) this.unobserve();
        });
      }, options);
      const circle = this.$refs.circle;
      this.observer.observe(circle);
    },
    unobserve() {
      const circle = this.$refs.circle;
      this.observer.unobserve(circle);
    },
  },
};
</script>

<style module>
.container {
  position: relative;
  width: 100%;
  border-radius: 100%;
  background-color: #fff;
}

.container::before {
  content: '';
  display: block;
  width: 100%;
  padding-top: 100%;
}

.circle {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.svg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.svg circle {
  stroke-width: 5px;
}

.blue {
  stroke-dasharray: 488px;
  stroke-linejoin: round;
  transition: all 1100ms 500ms cubic-bezier(0.98, 0.13, 0.37, 0.83)
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  padding: 10px;
  z-index: 1;
}

.label {
  padding: 1px 10px;
  font-size: 12px;
  line-height: 18px;
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 10px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.64);
}

.value {
  font-size: clamp(24px, calc(4px + 3.5vw), 40px);
  line-height: 1;
  font-weight: bold;
}

.unit {
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
}

@media screen and (max-width: 1023px) {
  .unit {
    font-size: 16px;
  }
}

@media screen and (max-width: 767px) {
  .inner {
    justify-content: center;
  }

  .label {
    display: none;
  }

  .unit {
    margin-top: 5px;
  }

  .svg circle {
    stroke-width: 10px;
    r: 75px;
  }
}

</style>
