<template>
  <div :class="$style.list">
    <div
      :class="$style.card"
      v-for="icon in icons"
      :key="icon">
      <div
        :class="$style.icon"
        :style="`background-image: url(/img/cards/${icon}.png);`"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardIcons',
  data() {
    return {
      icons: [
        'mastercard',
        'visa',
        'amex',
        'jcb',
        'diners-club',
        'discover',
      ],
    };
  },
};
</script>

<style lang="scss" module>
.list {
  margin: .3em 0;
}
.card {
  display: inline-block;
  width: 2.4em;
  border-radius: 2px;
  overflow: hidden;
  margin: auto 5px auto 0;
}

.icon {
  padding-top: percentage(142/220);
  background-size: cover;
}
</style>
