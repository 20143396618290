<template>
  <button
    v-scroll-to="{ el: '#returnList', offset: -56 }"
    :class="[$style.button, isShown ? $style.isShown : '']"
  >
    リターンを確認
    <span :class="$style.icon" />
  </button>
</template>

<script>
export default {
  name: 'ScrollToReturns',
  props: {
    isShown: {
      type: Boolean,
      required: true,
    },
    target: {
      type: Object,
    },
  },
};
</script>

<style module>
.button {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  font-size: 16px;
  font-weight: bold;
  background-color: #fff;
  color: #000;
  z-index: 4;
  transform: translate3d(0, 100%, 0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0);
  transition:
    transform 500ms ease-in-out,
    box-shadow 500ms ease-in-out;
}

.button.isShown {
  transform: translate3d(0, 0, 0);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
}

.icon {
  display: block;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  background: url('~@/assets/images/icon/caret-down-circle.svg') transparent center no-repeat;
  background-size: contain;
}

@media screen and (min-width: 768px) {
  .button {
    display: none;
  }
}
</style>
