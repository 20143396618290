<template>
  <div class="notfound">
    <div class="container">
      <h1 class="title-page lg">Page Not Found</h1>

      <div class="mt-5">
        <p>お探しのページは見つかりませんでした。</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NotFound',
};
</script>
