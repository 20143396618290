<template>
  <div class="editPassword edit">
    <div class="container">
      <h2 class="title-page lg">パスワード変更</h2>

      <form
        class="form vertical"
        v-on:submit="submit"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label
                for="oldPassword"
                class="form-label">現在のパスワード</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="oldPassword"
                  type="password"
                  name="oldPassword"
                  v-model.trim="$v.oldPassword.$model"
                  v-bind:class="{ input_error: validationFlags.oldPassword }"
                  v-on:input="invalidsCheck('oldPassword', $v.oldPassword.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.oldPassword.$dirty
                  && $v.oldPassword.required.$invalid">パスワードを入力してください</p>
            </dd>
          </dl>


          <dl class="form-group">
            <dt class="label">
              <label
                for="newPassword"
                class="form-label">新しいパスワード</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="newPassword"
                  type="password"
                  name="newPassword"
                  v-model.trim="$v.newPassword.$model"
                  v-bind:class="{ input_error: validationFlags.newPassword }"
                  v-on:input="invalidsCheck('newPassword', $v.newPassword.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.newPassword.$dirty
                  && $v.newPassword.required.$invalid">パスワードを入力してください</p>

              <p
                class="form-text"
                v-if="$v.newPassword.$dirty
                  && $v.newPassword.minLength.$invalid">パスワードは8文字以上で設定してください</p>
            </dd>
          </dl>


          <dl class="form-group">
            <dt class="label">
              <label
                for="repeatPassword"
                class="form-label">新しいパスワードの確認</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="repeatPassword"
                  type="password"
                  name="repeatPassword"
                  v-model.trim="$v.repeatPassword.$model"
                  v-bind:class="{ input_error: validationFlags.repeatPassword }"
                  v-on:input="invalidsCheck('repeatPassword', $v.repeatPassword.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.repeatPassword.$dirty
                  && $v.repeatPassword.sameAsPassword.$invalid">新しいパスワードと同一のパスワードを入力してください。</p>
            </dd>
          </dl>
        </div>

        <div class="form-group mt-sm">
          <ul class="btn-lists horizontal">
            <li>
              <button
                type="submit"
                class="btn btn__primary sm"
                v-bind:disabled="!submitFlag">送信</button>
            </li>
            <li>
              <router-link
                to="/account/"
                class="btn btn__tertiary sm">戻る</router-link>
            </li>
          </ul>

          <div class="messages mt-4">
            <p
              v-if="flag.updated">パスワードを更新しました。次回から新しいログインパスワードをご使用ください。</p>
            <div
              class="buttons"
              v-if="flag.updated">
              <ul class="btn-lists d-flex horizontal justify-content-center">
                <li
                  v-if="user.login.isLogin">
                  <router-link
                    class="btn btn__primary sm"
                    to="/account/">マイページ</router-link>
                </li>
                <li
                  v-if="user.login.isLogin">
                  <button
                    class="btn btn__secondary sm"
                    v-on:click="logout">ログアウト</button>
                </li>
              </ul>
            </div>

            <p
              v-if="flag.mismatch">現在のパスワードが一致しないため更新できません。<br>パスワードをお忘れに場合は<router-link href="/forgot-password/">こちら</router-link>からパスワードリセットを行ってください。</p>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-password',
  mixins: [cf],
  data() {
    return {
      validationFlags: {
        oldPassword: false,
        newPassword: false,
        repeatPassword: false,
      },
      // 無効フラグ
      invalids: {
        oldPassword: true,
        newPassword: true,
        repeatPassword: true,
      },
      flag: {
        updated: false,
        mismatch: false,
      },
    };
  },
  setup() {
    const oldPassword = ref('');
    const newPassword = ref('');
    const repeatPassword = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      oldPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        sameAsPassword: sameAs(newPassword),
      },
    };

    const $v = useVuelidate(rules, {
      oldPassword,
      newPassword,
      repeatPassword,
    });

    return {
      oldPassword,
      newPassword,
      repeatPassword,
      $v,
    };
  },

  created() {
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.oldPassword
        && !this.invalids.newPassword
        && !this.invalids.repeatPassword
        && !this.flag.updated;
    },
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),

    invalidsCheck(name, bool) {
      this.flag.mismatch = false;
      this.invalids[name] = bool;
    },

    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        id: this.user.id,
        email: this.user.email,
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updatePassword',
        data,
      })
        .then((response) => {
          if (response.data.token) {
            // tokenをlocalStorageに保存
            cf.saveLocalStorage({
              jwt: response.data.token,
            }, 'fk');
            this.$store.dispatch('user/login/authCheck', {
              type: 'check',
              jwt: response.data.token,
            });
          }
          if (response.data.updated) this.flag.updated = true;
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            if (error.response.data.status === 401
              && error.response.data.detail.includes('MISMATCH')) {
              this.flag.mismatch = true;
            }
          } else console.log(error);
        });
    },
  },
};
</script>
