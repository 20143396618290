<template>
  <form
    id="payment-form"
    class="form vertical payment mt-5"
    v-on:submit.prevent="submit">
    <div class="form-contents">
      <dl class="form-group">
        <dt class="label">
          <label class="form-label">クレジットカード番号</label>
          <CardIcons />
        </dt>
        <dd class="detail">
          <div class="form-parts stripe-element"><div id="cardNumber"></div></div>
          <div
            class="form-assistance text-danger"
            v-if="errors.cardNumber"
            v-html="errors.cardNumber"></div>
        </dd>
      </dl>
    </div>

    <div class="form-contents">
      <dl class="form-group">
        <dt class="label">
          <label class="form-label">有効期限</label>
        </dt>
        <dd class="detail">
          <div class="form-parts stripe-element"><div id="cardExpiry"></div></div>
          <div
            class="form-assistance text-danger"
            v-if="errors.cardExpiry"
            v-html="errors.cardExpiry"></div>
        </dd>
      </dl>
    </div>

    <div class="form-contents">
      <dl class="form-group">
        <dt class="label">
          <label class="form-label">セキュリティコード</label>
        </dt>
        <dd class="detail">
          <div class="form-parts stripe-element"><div id="cardCvc"></div></div>
          <div
            class="form-assistance text-danger"
            v-if="errors.cardCvc"
            v-html="errors.cardCvc"></div>
        </dd>
      </dl>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import { CardIcons } from '@/components/parts/Icon';
import cf from '@/mixins/commonFunctions';

// stripe
const STRIPE_PUB_KEY = cf.getStripePubKey();
const stripePromise = loadStripe(STRIPE_PUB_KEY);

export default {
  name: 'CardRegist',
  mixins: [cf],
  components: {
    CardIcons,
  },
  data() {
    return {
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      state: {
        cardNumber: false,
        cardExpiry: false,
        cardCv: false,
      },
      errors: {
        cardNumber: null,
        cardExpiry: null,
        cardCv: null,
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.generateCardElement();
  },
  methods: {
    /** カード登録の要素を作成 */
    async generateCardElement() {
      const stripe = await stripePromise;
      const elements = stripe.elements({
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Source+Code+Pro' }],
      });
      const style = {
        base: {
          color: '#303238',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          fontFamily: 'Source Code Pro, Consolas, Menlo, monospace',
          lineHeight: 1.6,
          textDecoration: 'underline #D3D3D3',
          '::placeholder': {
            color: '#A9A9A9',
          },
        },
        empty: {
          color: '#A9A9A9',
          textDecoration: 'underline',
        },
        invalid: {
          color: '#e5424d',
          ':focus': {
            color: '#303238',
          },
        },
      };

      // カード番号
      this.cardNumber = elements.create('cardNumber', { style });
      this.cardNumber.mount('#cardNumber');
      this.cardNumber.addEventListener('change', (event) => {
        this.state.cardNumber = event.complete;
        if (event.complete) this.cardExpiry.focus();
        this.errors.cardNumber = event.error ? event.error.message : null;
        this.completed();
      });

      // 有効期限
      this.cardExpiry = elements.create('cardExpiry', { style });
      this.cardExpiry.mount('#cardExpiry');
      this.cardExpiry.addEventListener('change', (event) => {
        this.state.cardExpiry = event.complete;
        if (event.complete) this.cardCvc.focus();
        this.errors.cardExpiry = event.error ? event.error.message : null;
        this.completed();
      });

      // セキュリティコード
      this.cardCvc = elements.create('cardCvc', { style });
      this.cardCvc.mount('#cardCvc');
      this.cardCvc.addEventListener('change', (event) => {
        this.state.cardCvc = event.complete;
        this.errors.cardCvc = event.error ? event.error.message : null;
        this.completed();
      });
    },

    completed() {
      const completed = this.state.cardNumber && this.state.cardExpiry && this.state.cardCvc;
      const data = {
        completed,
      };
      this.$emit('sendRegistFlag', data);
    },

    async getToken() {
      const stripe = await stripePromise;
      const token = await stripe.createToken(this.cardNumber);
      return token;
    },
  },
};
</script>
