<template>
  <div class="">
    <div class="title">
      <p>お支払いカード選択</p>
    </div>
    <form class="form">
      <div class="form-contents">
        <dl class="form-group mt-4">
          <dt class="label">
            <label class="form-label">登録カード一覧</label>
          </dt>
          <dd class="detail">
            <div
              class="form-check mt-4"
              v-for="(card, i) in user.cards.cards"
              v-bind:key="card">
              <input
                class="form-check-input"
                type="radio"
                :id="`default-card-${i}`"
                name="payment-type"
                :value="card"
                v-model="selectCard">
              <div :class="$style.cardWrap">
                <div :class="$style.card">
                  <div
                    :style="`background-image: url(/img/cards/${cards[card.brand]}.png);`"
                    :class="$style.icon"></div>
                </div>
                <label :for="`default-card-${i}`">**** **** **** {{ card.last4 }}<span v-if="user.cards.default === card.id">（デフォルト）</span></label>
              </div>
            </div>
            <div
              :class="$style.btn"
              v-on:click="registCard">別のカードを登録する</div>
          </dd>
        </dl>
      </div>

      <div class="form-contents">
        <div class="form-group">
          <div class="form-check">
            <input
              type="checkbox"
              id="setDefault"
              class="form-check-input"
              v-model="setDefault">
            <label for="setDefault">デフォルトのカードに設定する</label>
          </div>
        </div>
      </div>

      <div class="form-contents">
        <div class="form-group mt-sm">
          <ul class="btn-lists horizontal">
            <li>
              <button
                type="button"
                class="btn btn__primary sm"
                id="btn_check"
                v-on:click="regist"
                >確定</button>
            </li>
            <li>
              <button
                type="button"
                class="btn btn__primary sm"
                :disabled="user.cards.default === paymentType"
                v-on:click="hideModal">キャンセル</button>
            </li>
          </ul>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'formsubscription',
  mixins: [cf],
  components: {
  },
  data() {
    return {
      defaultCard: {},
      selectCard: {},
      setDefault: false,
      cards: {
        Visa: 'visa',
        MasterCard: 'mastercard',
        'American Express': 'amex',
        JCB: 'jcb',
        'Diners Club': 'diners-club',
        Discover: 'discover',
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.user.cards.cards.forEach((card) => {
      if (this.user.cards.default === card.id) {
        this.defaultCard = card;
      }
    });
    if (this.user.cards.temporary.id) {
      this.selectCard = this.user.cards.temporary;
    } else {
      this.selectCard = this.defaultCard;
    }
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    hideModal() {
      this.$store.dispatch('modal/contents/hideModal', null, { root: true });
    },

    registCard() {
      const data = {
        from: 'payment-step',
      };
      const args = {
        modalName: 'registCard',
        data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    regist() {
      this.showLoading();

      if (this.defaultCard.id === this.selectCard.id) {
        this.$store.dispatch('user/cards/resetTemporaryCard', null, { root: true });
        this.hideLoading();
        this.hideModal();
        return;
      }
      if (this.setDefault) {
        this.$store.dispatch('user/cards/resetTemporaryCard', null, { root: true });
        // デフォルトにする
        const data = {
          customer_id: this.user.customer.customer_id,
          card_id: this.selectCard.id,
        };

        this.axios({
          method: 'POST',
          url: '/v1/stripe/customer/set/changeDefaultCard',
          data,
        })
          .then(() => {
            // storeユーザー情報更新
            this.$store.dispatch('user/update', null, { root: true });
            const sendData = {
              customer: {
                customer_id: this.user.customer.customer_id,
              },
            };
            this.$store.dispatch('user/cards/getCustomer', sendData, { root: true });
            this.hideLoading();
          })
          .catch((error) => {
            if (error.response) console.log(error.response.data);
            else console.log(error);
            alert('デフォルトのクレジットカードの変更に失敗しました。お手数ですが、再度やり直してください。');
          })
          .finally(() => {
          });
      } else {
        const data = this.selectCard;
        this.hideLoading();
        this.$store.dispatch('user/cards/setTemporaryCard', data, { root: true });
      }
      this.hideModal();
    },
  },
};
</script>


<style lang="scss" module>
.list {
  margin: .3em 0;
}

.cardWrap {
  display: flex;
  align-items: center;
}

.card {
  width: 2.4em;
  border-radius: 2px;
  overflow: hidden;
  margin: auto 5px auto 0;
}

.icon {
  padding-top: percentage(142/220);
  background-size: cover;
}

.assistant {
  margin-top: .5em;
  color: #777;
  font-size: 12px;
  line-height: 18px;
}

.btn {
  cursor: pointer;
  font-size: 14px;
  display: inline-block;
  margin-top: 1.5em;
  padding: .3em 2em .2em;
  border: 1px solid black;
  border-radius: 2em;
}
</style>
