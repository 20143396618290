<template>
  <div class="step4">
    <div class="payment-header">
      <p>下記の内容で申し込みします。<br>よろしければ、「確定する」ボタンを押してください。</p>
    </div>

    <div class="payment-body" :class="$style.confirmArea">
      <dl>
        <dt>プロジェクト名</dt>
        <dd>{{ project.title }}</dd>
        <dt>リターン名（支援内容名）</dt>
        <dd>{{ returnItem.title }}</dd>
          <dt v-for="(additional, i) in project.additionalData" :key="i">{{ additional.text }}</dt>
          <dd v-for="(value, i) in additionalDatas" :key="i">{{ value }}</dd>
        <!-- <dt>お届け予定</dt>
        <dd>{{ returnItem. }}</dd> -->
        <dt>支払い方法</dt>
        <dd>{{ paymentType.label }}</dd>

        <dt v-if="price.unit">支援単価</dt>
        <dd v-if="price.unit">{{ addComma(price.unit) }}円</dd>
        <dt v-if="price.lot">口数</dt>
        <dd v-if="price.lot">{{ addComma(price.lot) }}口</dd>
        <dt v-if="price.amount">支援額</dt>
        <dd v-if="price.amount">{{ addComma(price.amount) }}円</dd>
        <dt v-if="price.tax">{{ taxRate.display_name }}</dt>
        <dd v-if="price.tax">{{ addComma(price.tax) }}円</dd>
        <dt v-if="price.total">お支払い総額</dt>
        <dd v-if="price.total">{{ addComma(price.total) }}円</dd>
      </dl>

      <div :class="$style.notice">
        <ul class="notice-lists">
          <li>原則として、決済完了後にご自身の都合によるキャンセルを受け付けておりません。</li>
          <li>プロジェクトやリターンの内容、製品に関するお問い合わせは、プロジェクト実行者へ直接お問い合わせ願います。</li>
        </ul>
      </div>

      <div :class="$style.btnsWrap">
        <ul class="btn-lists horizontal center flex-row-reverse">
          <li>
            <button
              class="btn btn__primary"
              :disabled="!flag.submit"
              @click="createIntent"
              >確定する</button>
          </li>
          <li>
            <router-link
              class="btn btn__tertiary"
              :to="`/payment/method/${slugs.project}/${slugs.return}/`">戻る</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'PaymentStep4',
  mixins: [cf],
  props: ['project', 'returnItem', 'slugs'],
  components: {
  },
  data() {
    return {
      taxRate: null,
      price: {
        unit: this.returnItem.price,
        lot: null,
        amount: null,
        tax: null,
        total: null,
      },
      customerStripe: null,
      sales_id: null,
      displayname: '',
      corporatename: '',
      logo: null,
      comment: '',
      additionaldata: null,
      label: null,
      flag: {
        submit: false,
        connectedAccount: true, // プロジェクトのconnect利用有無
        add: true,
      },
      paymentType: {
        label: null,
        type: null,
      },
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    additionalDatas() {
      return this.additionaldata;
    },
  },
  created() {
    const ls = cf.getLocalStorage('fk');
    if (!ls || !ls.lot || !this.$route.query.paymentType) {
      this.$emit('invalid', true);
      this.$emit('loader', false);
      return;
    }
    this.price.lot = ls.lot;
    this.comment = ls.comment;
    this.additionaldata = ls.additionaldata;
    if (this.additionaldata) {
      this.flag.add = false;
    }
    this.displayname = ls.displayname;
    this.corporatename = ls.corporatename;
    this.corporateurl = ls.corporateurl;
    this.logo = ls.logo;
    this.label = ls.label;

    // connectアカウント利用時のフラグ判定
    if (this.project.connectedAccountId) this.flag.connectedAccount = true;

    // クレジットカードと銀行振込のラベル振り分け
    const paymentType = Number(this.$route.query.paymentType);
    this.paymentType = {
      label: cf.methods.convPaytypeVal2Label(paymentType),
      type: paymentType,
    };

    this.moveToStep(4);

    // this.user.customerが検出できていないとinvalidなため、
    // card(1)とbank(2)でinitializeに渡すロジックを変える
    if (this.paymentType.type === 1) {
      // クレジットカード（this.user.customerは必ず存在する）
      if (this.user.email && this.user.customer) this.initialize();
      else {
        this.$store.subscribe((mutation) => {
          if (mutation.type === 'user/update' || mutation.type === 'user/setUserData') this.initialize();
        });
      }
    } else if (this.paymentType.type === 2) {
      // 銀行振込（ゲスト登録時は存在しない場合があるためmutation検知）
      if (this.user.email && this.user.customer) this.initialize();
      else {
        this.$store.subscribe((mutation) => {
          if (
            mutation.type === 'user/setUserData' || mutation.type === 'user/update'
          ) {
            // 直前のmethod.vueで本ページへの遷移前に
            // customer登録後にstore.user.updateしてるため
            // user/updateも含める
            this.initialize();
          }
        });
      }
    }
  },
  methods: {
    ...mapActions('payment', [
      'moveToStep',
    ]),
    initialize() {
      this.getTaxRate();
      this.getStripeCustomer();
    },

    /** stripeで設定した税率を取得 */
    getTaxRate() {
      this.axios({
        method: 'GET',
        url: '/v1/stripe/get/tax',
        params: {},
      })
        .then((response) => {
          const res = response.data;
          this.taxRate = res.taxRate;
          this.getPrices();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** 表示用料金の計算 */
    getPrices() {
      this.price.amount = this.price.unit * this.price.lot;
      this.price.tax = this.project.isDonation ? 0 : this.price.amount * (this.taxRate.percentage / 100);
      this.price.total = this.price.amount + this.price.tax;
      // ローダー非表示
      this.$emit('loader', false);
    },

    /**
     * userに紐づいたcustomer_idをもとに
     * stripeから詳細データを取得
     */
    getStripeCustomer() {
      this.axios({
        method: 'GET',
        url: '/v1/stripe/customer/get/stripeData',
        params: {
          customer_id: this.user.customer.customer_id,
        },
      })
        .then((response) => {
          const res = response.data;
          this.customerStripe = res.customer;
          // bankの場合はdefault_sourceは設定されていない
          // if (res.customer.default_source) this.flag.submit = true;
          this.flag.submit = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /**
     * stripe_intent登録
     */
    createIntent() {
      this.showLoading();

      let data;
      if (this.paymentType.type === 1) {
        // クレジットカード
        // デフォルトでカードか登録したカードか
        const paymentMethod = this.user.cards.temporary.id
          ? this.user.cards.temporary.id
          : this.customerStripe.default_source;

        data = {
          intentData: {
            amount: this.price.total,
            currency: 'jpy',
            customer: this.user.customer.customer_id,
            payment_method_types: ['card'],
            payment_method: paymentMethod,
            confirm: true, // falseだと即時決済されない
            receipt_email: this.user.email,
          },
        };
      } else if (this.paymentType.type === 2) {
        // 銀行振込
        data = {
          intentData: {
            amount: this.price.total,
            currency: 'jpy',
            customer: this.user.customer.customer_id,
            payment_method_types: ['customer_balance'],
            payment_method_data: {
              type: 'customer_balance',
            },
            payment_method_options: {
              customer_balance: {
                funding_type: 'bank_transfer',
                bank_transfer: {
                  type: 'jp_bank_transfer',
                },
              },
            },
            confirm: true,
          },
          // 銀行振込のみメール送信に必要
          project: { title: this.project.title },
          user: this.user,
          payment_type: this.paymentType.type,
        };
      }

      /**
       * connect acountが設定されている場合の送金先指定
       */
      if (this.project.connectedAccountId) {
        // prod以外はコネクトアカウント固定
        const connectedAccountId = this.helper.env.name !== 'production'
          ? 'acct_1Jp3xnPEsH6PE97m'
          : this.project.connectedAccountId;
        // 支払い方法に応じて手数料変動
        let commissionRate = this.paymentType.type === 1
          ? 0.136 // クレジットは13.6%
          : 0.115; // 銀行振込は11.5%

        if (this.user.email === 'ntv@kiryu.fund') {
          // BS日テレ手数料特別利率(7%+stripe手数料)
          commissionRate = this.paymentType.type === 1
            ? 0.106 // クレジットは10.6%
            : 0.085; // 銀行振込は8.5%
        }

        data.intentData.application_fee_amount = Math.floor(this.price.total * commissionRate);
        data.intentData.transfer_data = { destination: connectedAccountId };
      }

      this.axios({
        method: 'POST',
        url: '/v1/stripe/intent/set/register',
        data,
      })
        .then((response) => {
          const res = response.data;
          this.intent = res.intent;
          this.registedIntent = res.registed;
          // salse登録
          this.registSales();
        })
        .catch((error) => {
          if (error.response) {
            const res = error.response.data;
            console.log(res);
            if (res.alert_message) alert(res.alert_message);
          } else {
            console.log(error);
            alert('決済の登録に失敗しました。\nお手数ですが管理者までお問い合わせください');
          }
          this.hideLoading();
        });
    },

    /**
     * 支援内容登録
     */
    registSales() {
      const data = {
        project_id: this.project.id,
        return_id: this.returnItem.id,
        user_id: this.user.id,
        intent_id: this.registedIntent ? this.registedIntent.id : null,
        price: this.returnItem.price,
        lot: this.price.lot,
        amount: this.price.amount,
        tax: this.price.tax,
        payment_type: this.paymentType.type,
        status: this.intent ? this.intent.status : 'cicac_bank',
      };
      // console.log(data);

      this.axios({
        method: 'POST',
        url: '/v1/sales/set/register',
        data,
      })
        .then((response) => {
          // storeユーザー情報更新
          this.$store.dispatch('user/update', null, { root: true });

          this.sales_id = response.data.sales.id;
          // /payment/completeへの遷移
          this.$router.push({
            path: `/payment/complete/${this.slugs.project}/${this.slugs.return}/`,
          });

          // localstorageに保存されてるlotのリセット
          cf.deleteLocalStorage('fk', 'lot');
          if (this.returnItem.type === 0 && this.displayname) this.registDisplayname();
          if (this.returnItem.type === 1 && this.corporatename) this.registCorporateData();
          if (this.returnItem.type === 2 && this.corporatename) this.registCorporateData();
        })
        .catch((error) => {
          alert('決済自体は成功していますがお支払いの登録に失敗しました。お手数ですが管理者までお問い合わせください');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        })
        .finally(() => {
        });
    },

    registDisplayname() {
      const data = {
        id: null,
        flag: 1,
        user_id: this.user.id,
        project_id: this.project.id,
        return_id: this.returnItem.id,
        displayname: this.displayname,
        sales_id: this.sales_id,
      };

      this.axios({
        method: 'POST',
        url: '/v1/displayname/set/register',
        data,
      })
        .then(() => {
          cf.deleteLocalStorage('fk', 'displayname');
          if (this.comment) this.registComment();
          else this.hideLoading();
          if (this.additionaldata) this.registadditionalreturndata();
          else this.hideLoading();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        })
        .finally(() => {
        });
    },

    registCorporateData() {
      const data = {
        id: this.user.id,
        corporatename: this.corporatename,
        corporateurl: this.corporateurl,
        url_id: this.logo.url_id,
        logo: this.logo.s3,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateCorporateData',
        data,
      })
        .then(() => {
          cf.deleteLocalStorage('fk', 'corporatename');
          cf.deleteLocalStorage('fk', 'logo');
          if (this.comment) this.registComment();
          else this.hideLoading();
          if (this.additionaldata) this.registadditionalreturndata();
          else this.hideLoading();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        })
        .finally(() => {
        });
    },

    registComment() {
      const data = {
        id: null,
        user_id: this.user.id,
        flag: 1,
        comment: this.comment,
        project_id: this.project.id,
        return_id: this.returnItem.id,
        sales_id: this.sales_id,
      };

      this.axios({
        method: 'POST',
        url: '/v1/comment/set/register',
        data,
      })
        .then(() => {
          cf.deleteLocalStorage('fk', 'comment');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    registadditionalreturndata() {
      const data = {
        sales_id: this.sales_id,
        project_id: this.project.id,
        return_id: this.returnItem.id,
        label: this.label,
        value: this.additionaldata,
      };

      this.axios({
        method: 'POST',
        url: '/v1/additionalreturndata/set/register',
        data,
      })
        .then(() => {
          cf.deleteLocalStorage('fk', 'additionaldata');
          cf.deleteLocalStorage('fk', 'label');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>


<style module lang="scss">
.confirmArea {
  padding: 1em;
  margin-top: 2rem;
  background-color: white;
  dl {
    dt {
      font-weight: bold;
      &:not(:first-of-type) {
        margin-top: 1em;
      }
    }
    dd {
      padding: .5em;
      margin-top: .5em;
      background-color: #efefef;
    }
  }
}

.notice {
  margin-top: 2rem;
  padding: 1em;
  background-color: #efefef;
}

.btnsWrap {
  margin-top: 5rem;
}
</style>
