<template>
  <div id="signup" class="signup accounts">
    <div class="container-sm">
      <h2 class="title-page lg">パスワード登録</h2>

      <div
        class="wrap-error"
        v-if="invalid">
        <p>アクティベートキーが無効です。<br>メールアドレス登録時のメールに記載されたURLから再度アクセスしてください。</p>
      </div>

      <form
        class="form vertical"
        v-if="!invalid"
        v-on:submit="submit"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label
                for="password"
                class="form-label">パスワード</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="password"
                  type="password"
                  name="password"
                  v-bind:disabled="flag.sended && flag.registed"
                  v-model.trim="$v.password.$model"
                  v-bind:class="{ input_error: validationFlags.password }"
                  v-on:input="invalidsCheck('password', $v.password.$invalid)">
              </div>

              <p
                class="form-text"
                v-if="$v.password.$dirty
                  && $v.password.required.$invalid">パスワードを入力してください</p>

              <p
                class="form-text"
                v-if="$v.password.$dirty
                  && $v.password.minLength.$invalid">パスワードは8文字以上で設定してください</p>
            </dd>
          </dl>

          <dl class="form-group">
            <dt class="label">
              <label
                for="repeatPassword"
                class="form-label">パスワードの確認</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="repeatPassword"
                  type="password"
                  name="repeatPassword"
                  v-model.trim="$v.repeatPassword.$model"
                  v-bind:class="{ input_error: validationFlags.repeatPassword }"
                  v-on:input="invalidsCheck('repeatPassword', $v.repeatPassword.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.repeatPassword.$dirty
                  && $v.repeatPassword.sameAsPassword.$invalid">パスワードと同一のパスワードを入力してください。</p>
            </dd>
          </dl>
           
          <Policy class="policy"/>
          
          <div class="form-group btns mt-sm">
            <ul class="button_list">
              <li v-if="!flag.sended">
                <button
                  class="btn btn__primary sm"
                  type="submit"
                  v-bind:disabled="!submitFlag">同意してパスワード登録</button>
              </li>
              <li v-if="flag.sended && flag.registed">
                <router-link
                  class="btn btn__primary sm"
                  v-bind:to="{
                    path: '/login/',
                    query: { isRegist: true },
                  }">ログイン</router-link>
              </li>
            </ul>

            <div
              class="messages mt-4">
                <p
                  v-if="!flag.sended">プライバシーポリシーに同意の上、パスワードを入力・送信してください</p>

                <p
                  v-if="flag.sended
                    && flag.registed">パスワードを設定しました。<br><router-link to="/login/">ログインページ</router-link>よりログインしてください。</p>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, minLength, sameAs } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';
import Policy from '../Pages/Policy';

export default {
  name: 'signupPassword',
  components: {
    Policy,
  },
  mixins: [cf],
  data() {
    return {
      validationFlags: {
        password: false,
        repeatPassword: false,
      },
      // 無効フラグ
      invalids: {
        password: true,
        minLength: true,
        repeatPassword: true,
      },
      // パラメータなし等で無効
      invalid: true,
      activatekey: null,
      flag: {
        sended: false,
        registed: false,
      },
    };
  },
  created() {
    // パラメータが含まれているか確認
    const query = this.$route.query;
    if (query.activate) {
      this.invalid = false;
      this.activatekey = query.activate;
    }
  },
  // https://qiita.com/tsuji_masayuki/items/2250f87b391a0bd374cc
  setup() {
    const password = ref('');
    const repeatPassword = ref('');

    const rules = {
      // パスワードは必須で8文字以上
      password: {
        required,
        minLength: minLength(8),
      },
      repeatPassword: {
        sameAsPassword: sameAs(password),
      },
    };

    const $v = useVuelidate(rules, { 
      password,
      repeatPassword,
    });
    return { 
      password,
      repeatPassword,
      $v, 
    };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.password
        && !this.invalids.repeatPassword;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        flag: 1,
        password: this.password,
        activatekey: this.activatekey,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/registPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.registed) this.flag.registed = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.invalid = true;
        })
        .finally(() => {
          this.flag.sended = true;
        });
    },
  },
};
</script>

<style scoped>
  .policy {
    margin-top: 10px;
    border: 1px solid #a9a9a9;
    height: 300px;
    overflow-y: scroll;
    border-radius: 5px;
    font-size: 13px; 
  }
  .policy >>> .pages_top_title {
    font-size: 18px;
  }
  .policy >>> .pages_section_title {
    font-size: 15px;
  }

 </style>
