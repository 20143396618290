<template>
  <div class="admin">
    <div class="title-page lg">管理者画面</div>
    <div>
      <ul class="list-group">
        <li
          v-for="link in links"
          v-bind:key="link"
          class="card mt-2">
          <router-link :to="`/admin/${link.link}`" class="text-body">
            <div class="card-header fw-bold">{{ link.label }}</div>
            <div class="card-body" v-if="link.description"><p class="fs-5">{{ link.description }}</p></div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  name: 'admin-top',
  data() {
    return {
      links: [
        {
          label: '登録メール再送信',
          link: 'resendRegisterMail/',
          description: '特定のメールアドレスに対し、登録を促すメールを再送信します。',
        },
        // {
        //   label: '新着情報編集',
        //   link: 'information/',
        // },
        // {
        //   label: 'プロジェクト編集',
        //   link: 'project/',
        // },
        {
          label: 'メール',
          link: 'mail/',
          description: '利用者全員に対し、指定したタイトル・内容のメールを送信します。',
        },
        {
          label: '返金',
          link: 'refund/',
          description: '特定の利用者、または一括で返金を行います',
        },
      ],
    };
  },
  methods: {},
};
</script>
