import menu from '@/store/modules/layout/menu';
import header from '@/store/modules/layout/header';

const actions = {
};

const mutations = {
};

const state = {
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
  modules: {
    menu,
    header,
  },
};
