<template>
  <div class="adminInformation">
    <div class="container">
      <div class="d-flex mb-4">
        <h1 class="title-page">送信済みメール一覧</h1>
        <div class="ms-auto">
          <router-link
            class="btn btn__medium btn-primary"
            to="/admin/mail/register/"
            >新規作成</router-link>
        </div>
      </div>


      <div class="mails">
        <div v-if="mails.length">
          <ul class="list-group">
            <li
              class="card mt-2"
              v-for="mail in mails"
              v-bind:key="mail.id">
              <h2 class="card-header fw-bold">{{ mail.title }}</h2>
              <div class="card-body">
                <div class="d-flex align-items-end">
                  <small class="ms-auto fs-5">{{ filterDatetime(mail.created_at) }}</small>
                </div>
                <p class="card-text">{{ filterExcerpt(mail.content, 95) }}</p>

                <p class="mt-3 text-center"><router-link :to="`/admin/mail/${mail.id}/`" class="btn btn__medium btn-primary">詳細確認</router-link></p>
              </div>
            </li>
          </ul>

          <ul class="btn-lists horizontal center mt-5">
            <li>
              <router-link
                class="btn btn__primary btn__medium"
                to="/admin/">戻る</router-link>
            </li>
          </ul>
        </div>

        <p v-else class="mt-5">送信済みメールは見つかりませんでした。</p>
      </div>
    </div>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Admin-Mail',
  mixins: [cf],
  components: {
  },
  data() {
    return {
      mails: [],
    };
  },
  created() {
    this.getMails();
  },
  methods: {
    getMails() {
      const params = {};

      this.axios({
        method: 'GET',
        url: '/v1/mail/get/list',
        params,
      })
        .then((response) => {
          const data = response.data;
          if (data.mails.data.length) this.mails = data.mails.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data.error);
          else console.log(error);
        });
    },
  },
};
</script>
