<template>
  <footer class="footer">
    <div
      class="footer__parts footer__top"
      v-if="!hideSponsors">
      <div
        class="footer__parts__sponsors"
        v-if="corporateSponsors.logo_LL.length
              || corporateSponsors.logo_L.length
              || corporateSponsors.logo_M.length
              || corporateSponsors.logo_S.length
              || corporateSponsorsText.length">
        <p class="footer__parts__sponsors__title">ファンディング桐生に<br class="br-sp">協賛いただいた企業</p>

        <!-- logo_LL -->
        <ul
          class="footer__parts__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-show="row.name !== '匿名'"
            v-for="(row, i) in corporateSponsors.logo_LL"
            :key="i"
            class="logo_LL">
            <component
              class="footer__parts__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              target="_blank"
              >
              <div class="footer__parts__logo" :style="`background-image: url(${row.img})`"></div>
            </component>
            <div v-if="!row.img" class="footer__parts__text text_LL">
              <!--ロゴのない企業でもリンク先があればリンクとして認識させる-->
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
          </li>
        </ul>

        <!-- logo_L -->
        <ul
          class="footer__parts__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-show="row.name !== '匿名'"
            v-for="(row, i) in corporateSponsors.logo_L"
            :key="i"
            class="logo_L">
            <component
              class="footer__parts__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              target="_blank"
              >
              <div class="footer__parts__logo" :style="`background-image: url(${row.img})`"></div>
            </component>
            <!-- <div v-if="!row.img" class="footer__parts__text text_L">{{row.name}}</div> -->
            <div v-if="!row.img" class="footer__parts__text text_L">
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
          </li>
        </ul>

        <!-- logo_M -->
        <ul
          class="footer__parts__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-show="row.name !== '匿名'"
            v-for="(row, i) in corporateSponsors.logo_M"
            :key="i"
            class="logo_M">
            <component
              class="footer__parts__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              target="_blank"
              >
              <div class="footer__parts__logo" :style="`background-image: url(${row.img})`"></div>
            </component>
            <div v-if="!row.img" class="footer__parts__text text_M">
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
          </li>
        </ul>

        <!-- logo_S -->
        <ul
          class="footer__parts__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-show="row.name !== '匿名'"
            v-for="(row, i) in corporateSponsors.logo_S"
            :key="i"
            class="logo_S">
            <component
              class="footer__parts__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              target="_blank"
              >
              <div class="footer__parts__logo" :style="`background-image: url(${row.img})`"></div>
            </component>
            <!-- <div v-if="!row.img" class="footer__parts__text text_S">{{row.name}}</div> -->
            <div v-if="!row.img" class="footer__parts__text text_S">
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
          </li>
        </ul>

      </div>

      <!-- 個人協賛 -->
      <div
        class="footer__parts__sponsors"
        v-if="personalSponsors.length">
        <p class="footer__parts__sponsors__title">ファンディング桐生に<br class="br-sp">協賛いただいた個人</p>
        <div
          class="footer__parts__p"
          v-if="personalSponsors.length">
          <p
            v-show="row.name !== '匿名'"
            v-for="(row, i) in personalSponsors"
            :key="i">
            {{ row.name }}
          </p>
        </div>
      </div>
    </div>

    <div class="footer__parts footer__bottom">
      <router-link to="/" class="footer__parts__kiryuLogo"><img src="../../assets/images/logo.svg" alt="ファンディング桐生"></router-link>
      <div class="footer__parts__mainLogo">
        <a href="http://kiryutimes.co.jp/" target="_blank"><img src="../../assets/images/company/times.png" alt="タイムス"></a>
        <div  class="footer__parts__mainLogo__cross"></div>
        <a href="https://cicac.jp" target="_blank"><img src="../../assets/images/company/cicac.svg" alt="CICAC"></a>
      </div>
      <ul class="footer__parts__link">
        <li><router-link to="/">TOP</router-link></li>
        <!-- <li><router-link to="/">TOP</router-link><div class="footer__link__slash"></div></li> -->
        <!-- <li><router-link to="/media/">MEDIA</router-link><div class="footer__link__slash"></div></li>
        <li><router-link to="/about/">ファンディング桐生とは</router-link></li> -->
      </ul>
      <!-- <div class="footer__parts__line"></div> -->

      <div
        class="footer__parts__contents"
        v-if="backers.banners.length">
        <h4>後援</h4>
        <div class="footer__parts__subLogo">
          <a
            v-for="(banner, i) in backers.banners"
            :key="i"
            :href="banner.link"
            target="_blank">
            <img :src="banner.img" :alt="banner.alt">
          </a>
        </div>
      </div>

      <div class="pages_links_wrap">
        <ul class="pages_links">
          <li><router-link to="/terms/">利用規約</router-link></li>
          <li><router-link to="/policy/">プライバシーポリシー</router-link></li>
          <li><router-link to="/commercial/">特定商取引法に基づく表記</router-link></li>
        </ul>
      </div>
      <p class="footer__parts__copyright">Copyright © CICAC Inc. All Right Reserved.</p>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'footer',
  mixins: [cf],
  computed: {
    ...mapState(['helper', 'page']),
  },
  data() {
    return {
      hideSponsors: false,
      usersRawData: [],
      sizeChangeLine: {
        L: 100000,
        M: 60000,
      },
      corporateSponsors: {
        logo_LL: [],
        logo_L: [],
        logo_M: [],
        logo_S: [],
      },
      corporateSponsorsText: [],
      personalSponsors: [],
      backers: {
        banners: [
          { link: 'https://www.kiryucci.or.jp/', img: '/img/backers/shokokaigisho.jpg', alt: 'Google' },
          // { link: 'https://yahoo.co.jp', img: 'https://placehold.jp/480x160.png', alt: 'Yahoo' },
          // { link: 'https://www.apple.com/jp/', img: 'https://placehold.jp/480x160.png', alt: 'Apple' },
        ],
      },
    };
  },
  created() {
    if (this.helper.master.manualLogos) {
      this.getSales();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.getSales();
        }
      });
    }
  },

  watch: {
    $route(to) {
      if (to.params.slug === 'UJZR8GeeR83p' || to.name.includes('admin')) {
        this.hideSponsors = true; // 神業ミュージアムのプロジェクトの時だけはフッターの協賛企業を非表示に
      } else {
        this.hideSponsors = false;
      }

      const hidePageNames = ['payment'];
      hidePageNames.some((key) => {
        if (to.name.includes(key)) {
          this.hideSponsors = true;
          return true;
        }
        return false;
      });
    },
  },
  methods: {
    getSales() {
      this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
      })
        .then((response) => {
          const res = response.data;
          this.usersRawData = res.users;
          this.lineUpUsers();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    lineUpUsers() {
      // returnのタイプを格納
      //　0 -> 個人支援
      // 1 -> 企業支援
      // 2 -> 団体支援
      const returnTypes = {};
      this.$store.state.helper.master.projects.forEach((project) => {
        project.returns.forEach((row) => {
          returnTypes[row.id] = row.type;
        });
      });
      // 企業支援としてカウントするreturn＿type
      // 団体支援は企業支援としてカウント
      const corpReturnTypes = [1, 2];

      //console.log(returnTypes);

      // 除外するアカウントを抽出
      const excludeAccounts = [];
      const pids = Object.keys(this.$store.state.helper.master.manualLogos);
      pids.forEach((pid) => {
        excludeAccounts.push(this.$store.state.helper.master.manualLogos[pid].excludeAccount);
      });

      this.usersRawData.forEach((user) => {
        // 過去に支払い履歴がある場合
        if (
          user.customer
          && user.customer.intents.length
          && !excludeAccounts.includes(user.email)) {

          // 必要な項目
          const data = {
            corporate: {
              amount: 0,
              date: null,
            },
            personal: {
              amount: 0,
              date: null,
            },
          };

          const personalList = [];
          user.customer.intents.forEach((intent) => {
            if (intent.sales) {
              if (corpReturnTypes.includes(returnTypes[intent.sales.return_id])) {
                data.corporate.amount += intent.sales.amount;
                if (!data.corporate.date) data.corporate.date = intent.created_at;
              } else if (intent.sales.displayname) { // 同じ名前での支援が二回以上あったらamountを加算
                if (personalList.filter((elem) => elem.name === intent.sales.displayname.displayname).length) {
                  data.personal.amount += intent.sales.amount;
                  data.personal.date = intent.created_at;
                  if (!data.personal.date) data.personal.date = intent.created_at;
                } else {
                  personalList.push({
                    name: intent.sales.displayname.displayname,
                    amount: intent.sales.amount,
                    latest: intent.sales.created_at,
                  });
                  data.personal.amount = intent.sales.amount;
                }
              }
            }
          });

          personalList.forEach((personalData) => {
            if (personalData.name !== 'ST company') {
              if (personalData.name === 'しみずや') {
                this.personalSponsors.push({
                  name: '手打ちうどん　しみずや',
                  amount: personalData.amount,
                  latest: personalData.latest,
                });
              } else {
                this.personalSponsors.push({
                  name: personalData.name,
                  amount: personalData.amount,
                  latest: personalData.latest,
                });
              }
            }
          });

          // 企業
          if (data.corporate.amount) {
            const insertData = {
              name: user.corporatename,
              link: user.corporateurl,
              img: user.urls.logo.length ? user.urls.logo[0].url : null,
              alt: user.corporatename,
              amount: data.corporate.amount,
              latest: data.corporate.date,
            };

            const sizeNames = Object.keys(this.corporateSponsors);
            sizeNames.forEach((sizeName) => {
              this.corporateSponsors[sizeName].some((viewData, index) => {
                if (insertData.name === viewData.name) {
                  // corporatenameが重複してる場合
                  insertData.amount += viewData.amount;
                  this.corporateSponsors[sizeName].splice(index, 1);
                  return true;
                }
                return false;
              });
            });

            if (insertData.amount >= this.sizeChangeLine.LL) this.corporateSponsors.logo_LL.push(insertData);
            else if (insertData.amount >= this.sizeChangeLine.L) this.corporateSponsors.logo_L.push(insertData);
            else if (insertData.amount >= this.sizeChangeLine.M) this.corporateSponsors.logo_M.push(insertData);
            else this.corporateSponsors.logo_S.push(insertData);
          }
        }
      });

      // 手動ロゴの追加
      pids.forEach((pid) => {
        const plogos = this.$store.state.helper.master.manualLogos[pid];
        const logoTypes = Object.keys(plogos.logos);
        logoTypes.forEach((type) => {
          // 配列のマージ
          if (plogos.logos[type] && plogos.logos[type].length) {
            this.corporateSponsors[type] = this.corporateSponsors[type].concat(plogos.logos[type]);
          }
        });
      });

      // 金額が大きい順に並べ替え
      // （同額の場合、支援日時が最近のものが前に）
      this.corporateSponsors.logo_LL.sort((a, b) => {
        if (a.sum > b.sum) return -1;
        if (a.sum < b.sum) return 1;
        if (a.latest > b.latest) return -1;
        return 1;
      });
      this.corporateSponsors.logo_L.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest > b.latest) return -1;
        return 1;
      });
      this.corporateSponsors.logo_M.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest > b.latest) return -1;
        return 1;
      });
      this.corporateSponsors.logo_S.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest > b.latest) return -1;
        return 1;
      });
      this.corporateSponsorsText.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest > b.latest) return -1;
        return 1;
      });
      this.personalSponsors.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest > b.latest) return -1;
        return 1;
      });
    },
  },
};
</script>
