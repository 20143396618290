<template>
  <div id="edit-top" class="editAddresses edit">
    <div class="container">
      <h2 class="title-page lg">お届け先</h2>

      <form
        class="form horizontal"
        @submit.prevent="updateFlagConfirm"
        autocomplete="off">

        <Addresses
          :items="items"
          :isConfirm="flag.confirm"
          @sendValue="receiveValue" />

        <div class="form-contents mt-sm">
          <ul class="btn-lists horizontal">
            <li v-if="!flag.confirm">
              <button
                type="submit"
                id="confirm"
                class="btn btn__primary sm"
                :disabled="!flag.readySubmit"
                >確認</button>
            </li>
            <li v-if="!flag.confirm">
              <router-link
                to="/account/"
                class="btn btn__tertiary sm"
                >戻る</router-link>
            </li>
            <li v-if="flag.confirm">
              <div
                id="submit"
                class="btn btn__primary sm"
                @click="submit">登録</div>
            </li>
            <li v-if="flag.confirm">
              <div
                class="btn btn__tertiary sm"
                id="back"
                @click="updateFlagConfirm">編集画面に戻る</div>
            </li>
          </ul>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Addresses } from '@/components/parts/Form';
import cf from '@/mixins/commonFunctions';


export default {
  name: 'edit-addresses',
  mixins: [cf],
  components: {
    Addresses,
  },
  data() {
    return {
      flag: {
        confirm: false,
        readySubmit: false,
      },
      address: {},

      items: [
        {
          name: 'name',
          type: 'text',
          label: 'お届け先名',
          value: null,
          required: true,
        },
        {
          name: 'telNumber',
          type: 'text',
          label: '電話番号',
          value: null,
          required: true,
          placeholder: '09012345678',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '3760031',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: '群馬県',
          options: null,
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '桐生市本町',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: true,
          placeholder: '6丁目2番地',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          value: null,
        },
      ],
    };
  },
  created() {
    if (this.helper.master.prefs) {
      this.setPrefOptions();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.setPrefOptions();
        }
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
    submitFlag() {
      return false;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.address = values.address;
    },

    /** 確認フラグ更新 */
    updateFlagConfirm(e) {
      const id = e.currentTarget.id;
      const next = id && id === 'back';
      this.flag.confirm = !next;
      if (next) {
        this.$scrollTo('#edit-top');
      }
    },
    /** state.helper.master.prefsを'pref'のoptionsにセット */
    setPrefOptions() {
      this.items.some((item) => {
        if (item.name === 'pref') {
          item.options = this.$store.state.helper.master.prefs;
          return true;
        }
        return false;
      });
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();

      const data = {
        id: null, // 新規登録時はnull
        flag: 1,
        user_id: this.user.id,
        is_primary: 1,
      };
      // 取得した住所情報をdataに格納
      Object.keys(this.address).forEach((key) => {
        if (key !== 'email' && key !== 'displayname') {
          data[key] = this.address[key];
        }
      });
      if (this.user.addresses.main.length) data.id = this.user.addresses.main[0].id;

      // TODO: 複数住所対応時に変更する必要あり
      const endpoint = data.id ? 'updater' : 'register';
      const type = data.id ? '更新' : '登録';

      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          this.flag.confirm = false;
          // stateアップデート
          this.$store.dispatch('user/update', null, { root: true });
          alert(`お届け先を${type}しました`);
        })
        .catch((error) => {
          alert('住所の登録に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
