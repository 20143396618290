<template>
  <div class="registMail edit">
    <div id="edit-top" class="container">
      <h1 class="title-page mb-5">メール送信</h1>

      <form
        class="form horizontal"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label for="form-label">対象プロジェクト</label>
            </dt>
            <dd class="detail select">
              <div class="form-parts">
                <select
                  name="project_id"
                  id="project_id"
                  v-on:change="updateValue"
                  :disabled="flag.isConfirm"
                  v-if="helper.master.projects">
                  <option value="0">選択してください</option>
                  <option
                    v-for="project in helper.master.projects"
                    :key="project.id"
                    :value="project.id">{{ project.id }}：{{ project.title }}</option>
                </select>
              </div>
            </dd>
          </dl>

          <dl class="form-group">
            <dt class="label">
              <label for="form-label">タイトル</label>
            </dt>
            <dd class="detail text">
              <div class="form-parts">
                <input
                  type="text"
                  name="title"
                  id="title"
                  v-on:change="updateValue"
                  :disabled="flag.isConfirm">
              </div>
            </dd>
          </dl>

          <dl class="form-group">
            <dt class="label">
              <label for="form-label">内容</label>
            </dt>
            <dd class="detail">
              <div class="form-parts">
                <Editor
                  v-model="form.content"
                  v-bind:api-key="TINYMCE_API_KEY"
                  v-bind:init="editorConf"
                  :disabled="flag.isConfirm" />
              </div>

              <input
                type="file"
                name="file"
                accept=".jpg, .jpeg, .png, .gif"
                id="input-file"
                class="hidden"
                v-if="validate.view">
            </dd>
          </dl>
        </div>


        <div class="form-contents">
          <ul class="btn-lists horizontal center">
            <li>
              <div
                class="btn btn-primary btn__medium"
                id="btn_check"
                v-if="!flag.isConfirm"
                v-on:click="confirmInfo"
                >確認</div>
            </li>

            <li>
              <router-link
                class="btn btn__primary btn__medium"
                to="/admin/mail/"
                v-if="!flag.isConfirm">戻る</router-link>
            </li>

            <li>
              <div
                class="btn btn-primary btn__medium"
                id="btn_submit"
                v-if="flag.isConfirm"
                v-on:click="submit"
                >送信</div>
            </li>

            <li>
              <div
                class="btn btn__tertiary btn__medium"
                id="btn_back"
                v-if="flag.isConfirm"
                v-on:click="updateFlagConfirm"
                >編集画面に戻る</div>
            </li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import cf from '@/mixins/commonFunctions';

const TINYMCE_API_KEY = process.env.VUE_APP_TINYMCE_API_KEY;

export default {
  name: 'Admin-Mail-Register',
  mixins: [cf],
  components: {
    Editor,
  },
  data() {
    return {
      TINYMCE_API_KEY,
      flag: {
        isConfirm: false,
      },
      validate: {
        view: true,
      },
      form: {
        project_id: null,
        title: null,
        content: null,
      },
      files: [],
      editorConf: {
        selector: 'textarea',
        content_css: '/editor/css/editor.css',
        language: 'ja',
        language_url: '/editor/langs/ja.js',
        menubar: false,
        plugins: 'lists link image code',
        toolbar: 'styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | image | undo redo | code',
        // image_title: true,
        automatic_uploads: true,
        file_picker_types: 'image',
        file_picker_callback: (cb) => {
          const input = window.document.getElementById('input-file');
          console.log(input);

          input.onchange = (e) => {
            const files = e.target.files || e.dataTransfer.files;
            const file = files[0];
            const reader = new FileReader();
            reader.onload = () => {
              const id = `blobid${(new Date()).getTime()}`;
              const blobCache = window.tinymce.activeEditor.editorUpload.blobCache;
              const base64Original = reader.result;
              const base64 = base64Original.split(',')[1];
              const blobInfo = blobCache.create(id, file, base64);
              blobCache.add(blobInfo);
              // file保存・置換用
              this.files.push({
                file,
                base64: base64Original,
              });
              // input[type=file]のリセット
              this.validate.view = false;
              this.$nextTick(() => { this.validate.view = true; });
              cb(blobInfo.blobUri(), { title: file.name });
            };
            reader.readAsDataURL(file);
          };
          input.click();
        },
      },
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  created() {
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    confirmInfo() {
      if (!this.form.title) {
        alert('タイトルを入力してください。');
      } else if (!this.form.content) {
        alert('内容を入力してください。');
      } else {
        this.flag.isConfirm = true;
        this.form.project_id = Number(this.form.project_id);
      }
    },

    updateFlagConfirm() {
      this.flag.isConfirm = false;
    },

    updateValue(e) {
      this.form[e.target.name] = e.target.value;
    },

    submit(e) {
      e.preventDefault();
      this.showLoading();
      const data = this.form;
      data.user_id = this.user.id;
      // ユーザのセグメントを指定する場合
      const segment = {
        // flags: [1],
      };

      console.log(data);
      this.axios({
        method: 'POST',
        url: '/v1/mail/set/register',
        data: {
          data,
          segment,
        },
      })
        .then((response) => {
          this.flag.isConfirm = false;
          this.form = { project_id: null, title: null, content: null };
          let mails = '';
          if (response.data.emails && response.data.emails.length) {
            response.data.emails.forEach((email) => { mails += `\n${email}`; });
          }
          alert(`メール送信が完了しました。\n${mails}`);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          alert('登録に失敗しました。');
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>


<style module lang="scss">
.btnsWrap {
  margin-top: 5rem;
}
</style>
