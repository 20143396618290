<template>
  <div class="step5">
    <div
      v-if="paymentType === 1
        || (paymentType === 2 && latestIntent && latestIntent.sales.status === 'succeeded')">
      <div class="payment-header">
        <p>お支払いが完了しました。</p>
      </div>

      <div class="payment-body">
        <p>この度は「ファンディング・桐生」を通してプロジェクトへご支援いただき誠にありがとうございました。</p>
        <p>登録いただいているメールアドレスに完了メールを送信いたしました。</p>
        <p>不明な点などがございましたら、メール本文中の「お問い合わせ先」までご連絡ください。</p>
        <p>また、プロジェクトのリターンに関しましては、プロジェクトが達成し次第別途メールにてご連絡させていただきます。少々お待ちください。</p>
        <p>みなさまのご支援で桐生に新たな活気が生まれるよう、運営チーム一同尽力してまいります。</p>
        <p>この度はご支援誠にありがとうございました。</p>
      </div>
    </div>


    <div
      class="loader-wrapper"
      v-else-if="paymentType === 2">
      <div
        class="loaded-content"
        v-if="!flag.isLoader">
        <div class="payment-header">
          <p>下記口座へのお振込をお願いします</p>
        </div>

        <div class="payment-body" :class="$style.confirmArea">
          <div :class="$style.notice">
            <!-- <p>ファンディング桐生では、決済代行サービス【Stripe】を使用しており、ご支援者様のお振込はStripeを通じて安全にプロジェクト実行者へ支援されます。</p> -->
            <ul class="notice-lists">
              <li>お振込が完了しましたら、メールにてご案内を送信させていただきます。</li>
              <li>またリターン数在庫の関係上、お振込は3営業日以内にお願い申し上げます。<br>お振込が一定期間されない場合はキャンセルとさせていただく場合がございます。予めご了承ください。</li>
            </ul>
          </div>

          <dl>
            <dt>お振込金額</dt>
            <dd>{{ addComma(transfer.amount_remaining) }}円</dd>
            <dt>銀行名</dt>
            <dd>{{ bank.bank_name }}</dd>
            <!-- <dt>銀行コード</dt>
            <dd>{{ bank.bank_code }}</dd> -->
            <dt>支店名</dt>
            <dd>{{ bank.branch_name }}</dd>
            <!-- <dt>支店コード</dt>
            <dd>{{ bank.branch_code }}</dd> -->
            <dt>口座種別</dt>
            <dd>{{ accountTypes[bank.account_type] }}</dd>
            <dt>口座番号</dt>
            <dd>{{ bank.account_number }}</dd>
            <dt>口座名義</dt>
            <dd>{{ bank.account_holder_name }}</dd>
            <dt v-if="transfer.reference">お振込時の備考欄にご入力ください</dt>
            <dd v-if="transfer.reference">{{ transfer.reference }}</dd>
          </dl>
        </div>
      </div>

      <div
        class="loader-simple"
        v-if="flag.isLoader"></div>
    </div>

    <div :class="$style.btnsWrap">
      <ul class="btn-lists horizontal center">
        <li>
          <router-link
            class="btn btn__tertiary"
            to="/">トップへ戻る</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import cf from '@/mixins/commonFunctions';

// stripe
const STRIPE_PUB_KEY = cf.getStripePubKey();
const stripePromise = loadStripe(STRIPE_PUB_KEY, {
  apiVersion: '2020-08-27;customer_balance_payment_method_beta=v2',
  betas: ['customer_balance_beta_1'],
  locale: 'ja',
});

export default {
  name: 'PaymentStep4',
  mixins: [cf],
  props: ['project', 'returnItem', 'slugs'],
  components: {
  },
  data() {
    return {
      latestIntent: null,
      transfer: null,
      bank: null,
      paymentType: null,
      flag: {
        isLoader: true,
      },
    };
  },
  computed: {
    ...mapState(['user']),
    accountTypes() {
      const result = {
        futsu: '普通',
      };
      return result;
    },
  },
  created() {
    this.moveToStep(5);
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'user/setUserData'
        || mutation.type === 'user/update') {
        // 直前のconfirm.vueで本ページへの遷移前に
        // sales登録後にstore.user.updateしてるため
        // user/updateも含める
        this.initialize();
      }
    });
  },
  methods: {
    ...mapActions('payment', [
      'moveToStep',
    ]),

    initialize() {
      this.latestIntent = this.user.customer.intents[0];
      // 支払い方法（1=>card / 2=>bank）
      this.paymentType = this.latestIntent.sales.payment_type;
      if (this.latestIntent.sales.status !== 'succeeded') {
        // 最新の支払いが銀行振込で支払い待ち
        this.getCustomerBalancePayment();
      } else {
        // ローダー非表示
        this.$emit('loader', false);
      }
    },

    async getCustomerBalancePayment() {
      const stripe = await stripePromise;

      await stripe.confirmCustomerBalancePayment(
        this.latestIntent.client_secret, {
          payment_method: {
            customer_balance: {},
          },
          payment_method_options: {
            customer_balance: {
              funding_type: 'bank_transfer',
              bank_transfer: {
                type: 'jp_bank_transfer',
              },
            },
          },
        }, {
          handleActions: false,
        },
      )
        .then(({ error, paymentIntent }) => {
          if (error) {
            alert(error.message);
            console.log(error);
            this.$emit('invalid', true);
            this.$emit('loader', false);
          } else if (paymentIntent
            && (paymentIntent.status === 'requires_action'
              || paymentIntent.status === 'partially_funded')) {
            const nextAction = paymentIntent.next_action;
            if (nextAction.type === 'display_bank_transfer_instructions') {
              this.transfer = nextAction.display_bank_transfer_instructions;
              if (this.transfer.financial_addresses.length) {
                // 銀行口座情報
                const bank = this.transfer.financial_addresses[0];
                this.bank = bank[bank.type];
                // ローダー非表示
                this.$emit('loader', false);
                this.flag.isLoader = false;
              }
            }
          }
        });
    },
  },
};
</script>


<style module lang="scss">
.btnsWrap {
  margin-top: 5rem;
}

.notice {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  padding: 1em;
  background-color: #efefef;
}

.confirmArea {
  padding: 1em;
  margin-top: 2rem;
  background-color: white;
  dl {
    dt {
      font-weight: bold;
      &:not(:first-of-type) {
        margin-top: 1em;
      }
    }
    dd {
      padding: .5em;
      margin-top: .5em;
      background-color: #efefef;
    }
  }
}
</style>
