<template>
  <div class="step1">
    <div class="payment-header">
      <p>選択した支援の内容を確認する</p>
    </div>

    <div :class="$style.returnItem">
      <return-item
        :return-item="returnItem"
        :show-btn="false"
        :isDonation="project.isDonation"
        :taxIncludes="project.taxIncludes" />
    </div>

    <div :class="$style.lot">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label required">
                <label class="form-label">個数を選択してください</label>
              </dt>
              <dd class="detail select">
                <div class="form-parts">
                  <select
                    @change="updateLot"
                    :disabled="returnItem.lotDisable">
                    <option
                      v-for="i in 10"
                      :key="i"
                      :value="i">{{ i }}</option>
                  </select>
                </div>
              </dd>
              <p
                class="form-assistance"
                v-if="returnItem.lotDisable">このリターンは口数を選択できません。</p>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot" v-if="returnItem.type === 0">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label required">
                <label class="form-label">表示名</label>
              </dt>
              <dd class="detail text">
                <div class="form-parts">
                  <input
                    id="displayname"
                    type="text"
                    name="displayname"
                    autocomplete="off"
                    :value="values.displayname"
                    v-on:input="updateValue">
                </div>
                <p class="form-assistance">プロジェクトページに表示されます。</p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot" v-if="returnItem.type === 1">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label required">
                <label class="form-label">企業名</label>
              </dt>
              <dd class="detail text">
                <div class="form-parts">
                  <input
                    id="corporatename"
                    type="text"
                    name="corporatename"
                    autocomplete="off"
                    :value="values.corporatename"
                    v-on:input="updateValue">
                </div>
                <p class="form-assistance">企業ロゴの登録がない場合、企業名がプロジェクトページに表示されます。</p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

        <div :class="$style.lot" v-if="returnItem.type === 2">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label required">
                <label class="form-label">団体名</label>
              </dt>
              <dd class="detail text">
                <div class="form-parts">
                  <input
                    id="corporatename"
                    type="text"
                    name="corporatename"
                    autocomplete="off"
                    :value="values.corporatename"
                    v-on:input="updateValue">
                </div>
                <p class="form-assistance">団体ロゴの登録がない場合、団体名がプロジェクトページに表示されます。</p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot" v-if="returnItem.type === 1">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label">
                <label class="form-label">企業ロゴ</label>
              </dt>
              <dd class="detail text">
                <div v-if="!values.logo.s3 && !values.logo.preview">
                  <input
                    id="logo"
                    type="file"
                    name="logo"
                    v-on:change="updateFile">
                </div>
                <div
                  v-if="values.logo.s3 || values.logo.preview">
                  <div class="preview" v-if="values.logo.s3" :style="`background-image: url(${values.logo.s3})`">
                    <div class="delete_btn" v-on:click="deleteLogo()">
                      <div class="cross"></div>
                    </div>
                  </div>
                  <div class="preview" v-if="values.logo.preview" :style="`background-image: url(${values.logo.preview})`">
                    <div class="delete_btn" v-on:click="deleteLogo()">
                      <div class="cross"></div>
                    </div>
                  </div>
                </div>
                <p class="form-assistance">
                  プロジェクトページに表示されます。
                  <span v-if="user.login.isLogin">変更はマイページから可能です。</span>
                  <span v-else>以後、変更したい場合は本登録が必要です。</span>
                  <span>
                    <p>ファイルタイプ：.jpg, .jpeg, .png, .gif</p>
                  </span>
                </p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot" v-if="returnItem.type === 2">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label">
                <label class="form-label">団体ロゴ</label>
              </dt>
              <dd class="detail text">
                <div v-if="!values.logo.s3 && !values.logo.preview">
                  <input
                    id="logo"
                    type="file"
                    name="logo"
                    v-on:change="updateFile">
                </div>
                <div
                  v-if="values.logo.s3 || values.logo.preview">
                  <div class="preview" v-if="values.logo.s3" :style="`background-image: url(${values.logo.s3})`">
                    <div class="delete_btn" v-on:click="deleteLogo()">
                      <div class="cross"></div>
                    </div>
                  </div>
                  <div class="preview" v-if="values.logo.preview" :style="`background-image: url(${values.logo.preview})`">
                    <div class="delete_btn" v-on:click="deleteLogo()">
                      <div class="cross"></div>
                    </div>
                  </div>
                </div>
                <p class="form-assistance">
                  プロジェクトページに表示されます。
                  <span v-if="user.login.isLogin">変更はマイページから可能です。</span>
                  <span v-else>以後、変更したい場合は本登録が必要です。</span>
                  <span>
                    <p>ファイルタイプ：.jpg, .jpeg, .png, .gif</p>
                  </span>
                </p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot" v-if="returnItem.type === 1">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label">
                <label class="form-label">企業サイトURL</label>
              </dt>
              <dd class="detail text">
                <div class="form-parts">
                  <input
                    type="text"
                    name="corporateurl"
                    id="corporateurl"
                    class=""
                    v-model.trim="$v.corporateurl.$model"
                    v-on:input="invalidsCheck('corporateurl', $v.corporateurl.$invalid)">
                </div>
                <p class="form-assistance">プロジェクトページに表示された企業ロゴクリック時、このURLに飛びます。</p>
                <p
                  class="form-text"
                  v-if="$v.corporateurl.$invalid">正しいURLの形式で入力してください。</p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot" v-if="returnItem.type === 2">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label">
                <label class="form-label">団体サイトURL</label>
              </dt>
              <dd class="detail text">
                <div class="form-parts">
                  <input
                    type="text"
                    name="corporateurl"
                    id="corporateurl"
                    class=""
                    v-model.trim="$v.corporateurl.$model"
                    v-on:input="invalidsCheck('corporateurl', $v.corporateurl.$invalid)">
                </div>
                <p class="form-assistance">プロジェクトページに表示された団体ロゴクリック時、このURLに飛びます。</p>
                <p
                  class="form-text"
                  v-if="$v.corporateurl.$invalid">正しいURLの形式で入力してください。</p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot" v-if="returnItem.additionalData && returnItem.additionalData[0].formLine">
      <form class="form vertical">
        <div
          class="form-contents"
          v-for="(additional, i) in project.additionalData"
          :key="i">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label required">
                <label class="form-label">{{ returnItem.additionalData[0].label }}</label>
              </dt>
              <dd
                class="detail text"
                v-if="returnItem.additionalData[i].formLine !== 1">
                <div class="form-parts"
                  :class="$style.additional_data"
                  v-for="i in returnItem.additionalData[i].formLine" :key="i">
                  <input
                    type="text"
                    id="additional_data"
                    name="additional_data"
                    autocomplete="off"
                    v-on:change="changeAdditionalData($event, i)">
                </div>
                <p class="form-assistance">{{ project.additionalData[i].description }}</p>
              </dd>
              <dd class="detail text" v-else>
                <div class="form-parts">
                  <input
                    type="text"
                    id="additional_data"
                    name="additional_data"
                    autocomplete="off"
                    v-on:change="changeAdditionalData">
                </div>
                <p class="form-assistance" v-html="project.additionalData[i].description"></p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div :class="$style.lot">
      <form class="form vertical">
        <div class="form-contents">
          <div class="form-contents">
            <dl class="form-group">
              <dt class="label">
                <label class="form-label">応援コメント</label>
              </dt>
              <dd class="detail text">
                <div class="form-parts">
                  <input
                    id="comment"
                    type="text"
                    name="comment"
                    autocomplete="off"
                    v-on:input="updateValue">
                </div>
                <p class="form-assistance">応援コメントをいただけると励みになります！</p>
              </dd>
            </dl>
          </div>
        </div>
      </form>
    </div>

    <div
      :class="$style.userInfo">
      <!-- TODO: 送り先の必要有無はリターンによる -->
      <div v-if="!flag.isEdit">

        <!-- TODO: 複数お届け先ロジック -->
        <!-- <p>お届け先を選択してください</p> -->

        <div
          class="card"
          v-if="user.email">
          <div class="card-body">
            <div class="form">
              <div class="form-contents">
                <dl class="form-group">
                  <dt class="label fw-bold">メールアドレス</dt>
                  <dd class="detail">{{ user.email }}</dd>
                </dl>
                <!-- <dl class="form-group">
                  <dt class="label fw-bold">表示名</dt>
                  <dd class="detail">{{ user.displayname }}</dd>
                </dl> -->
              </div>
            </div>
            <!-- <p>{{ user.addresses.main[0].name }}</p>
            <p>{{ user.addresses.main[0].telNumber }}</p>
            <p>{{ filterZip(user.addresses.main[0].zip) }}</p>
            <p>{{ user.addresses.main[0].pref }}{{ user.addresses.main[0].city }}{{ user.addresses.main[0].address }} {{ user.addresses.main[0].building }}</p> -->

            <!-- <ul class="d-flex justify-content-center">
              <li><button class="btn btn__secondary">編集</button></li>
              <li><button class="btn btn__secondary">削除</button></li>
            </ul> -->
          </div>
          <!-- <button class="btn btn__primary">お届け先情報を追加する</button> -->
        </div>
      </div>

      <div
        id="edit-top"
        class="form vertical"
        v-if="flag.isEdit">
        <!-- TODO: 送り先の必要有無はリターンによる -->
        <div :class="$style.lot" v-if="returnItem.addressRequired">
          <label class="form-label" id="address-required">{{ returnItem.addressRequired.label }}</label>
        </div>

        <Addresses
          :items="items"
          :isConfirm="flag.confirm"
          @sendValue="receiveValue" />

        <div class="form-contents mt-sm">
          <ul class="btn-lists horizontal center">
            <li v-if="!flag.confirm">
              <button
                type="submit"
                id="confirm"
                class="btn btn__primary sm"
                :disabled="!flag.readySubmit"
                @click="updateFlagConfirm"
                >確認</button>
            </li>
            <li v-if="!flag.confirm && this.user.login.isLogin && this.user.addresses && this.user.addresses.main.length">
              <button
                class="btn btn__tertiary sm"
                @click="updateIsEdit"
                >戻る</button>
            </li>
            <li v-if="flag.confirm">
              <div
                id="submit"
                class="btn btn__primary sm"
                @click="submit">登録</div>
            </li>
            <li v-if="flag.confirm">
              <div
                class="btn btn__tertiary sm"
                id="back"
                @click="updateFlagConfirm">編集画面に戻る</div>
            </li>
          </ul>
        </div>
      </div>

      <div :class="$style.btnsWrap">
        <ul
          class="btn-lists horizontal center"
          v-if="!flag.isEdit">
          <li>
            <button
              class="btn btn__primary"
              @click="typeCheck">支援に必要な情報を登録・変更する</button>
          </li>
        </ul>
      </div>
    </div>

    <div :class="$style.notice">
      <p>事前に必ずご一読ください<br>
      （詳細は<router-link to="/terms/">利用規約</router-link>をご確認願います。）</p>
      <p>原則として、決済完了後はご自身の都合によるキャンセルを受け付けておりません。</p>
      <p>本契約はプロジェクト実行者とサポーター間の契約となり、ファンディング桐生は契約当事者ではありませんので、予めご了承願います。</p>
      <p>本プロジェクトに関する詳細説明、トラブル、返金、リターン返品等は、契約当事者であるプロジェクト実行者の責任において行われます。そのため、当該事項に関するお問い合わせはプロジェクト実行者へ直接お問い合わせ願います。</p>
      <p>ファンディング桐生は、本プロジェクトが必ず成功することや、リターンのあるプロジェクトではプロジェクトのリターンの完成、品質、リターン内容を保証するものではありません。<br>
      在庫数に限りのあるリターンは、申込みが殺到した場合、申込みが完了したとしても「在庫切れ」となり入金できない、またはキャンセルとなる場合があります。予めご了承ください。</p>
      <p>通常のECサイトとは異なり、支援後の配送までに数カ月要する事があります。</p>
    </div>

    <div :class="$style.check">
      <form class="form vertical">
        <div class="form-check mt-3">
          <input
            id="agreeTerms"
            type="checkbox"
            name="agreeTerms"
            class="form-check-input"
            v-model="agreeTerms">
          <label for="agreeTerms">利用規約に同意します</label>
        </div>
        <div class="form-check mt-3">
          <input
            id="notAnti"
            type="checkbox"
            name="notAnti"
            class="form-check-input"
            v-model="notAnti">
          <label for="notAnti">私は反社会的勢力ではありません</label>
        </div>
      </form>
    </div>

    <div :class="$style.btnsWrap">
      <ul class="btn-lists horizontal center flex-row-reverse">
        <li>
          <!-- TODO: リターンによって必要な情報が変わる -->
          <button
            @click="requiredCheck"
            class="btn btn__primary"
            :disabled="!user.email || !notAnti || !agreeTerms || (!submitFlag && corporateurl !== '')">お支払い方法の選択へ進む</button>
        </li>
        <li>
          <router-link
            class="btn btn__tertiary"
            :to="`/projects/${slugs.project}/`">戻る</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { url } from '@vuelidate/validators';
import { Addresses } from '@/components/parts/Form';
import { ReturnItem } from '@/components/parts/Project';
import cf from '@/mixins/commonFunctions';


export default {
  name: 'PaymentStep1',
  mixins: [cf],
  props: ['project', 'returnItem', 'slugs', 'prefs'],
  components: {
    Addresses,
    ReturnItem,
  },
  data() {
    return {
      localstorage: null,
      values: {
        lot: 1,
        displayname: '',
        corporatename: '',
        logo: {
          file: null,
          id: null,
          s3: null,
          preview: null,
        },
        additional_data: null,
        comment: '',
      },
      agreeTerms: false,
      notAnti: false,
      flag: {
        confirm: false,
        readySubmit: false,
        isEdit: false,
        userAddress: false,
      },
      validationFlags: {
        corporateurl: false,
      },
      invalids: {
        corporateurl: false,
      },
      items: [
        {
          name: 'mail',
          type: 'email',
          label: 'メールアドレス',
          value: null,
          required: true,
          isGuest: true,
        },
        {
          name: 'displayname',
          type: 'text',
          label: '表示名',
          required: true,
          value: null,
          comment: '掲載等する際の表示名として使用されます。また、デフォルトの表示名として設定されます。',
        },
        {
          name: 'name',
          type: 'text',
          label: 'お届け先名',
          value: null,
          required: true,
        },
        {
          name: 'telNumber',
          type: 'text',
          label: '電話番号',
          value: null,
          required: true,
          placeholder: '09012345678',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'zip',
          type: 'text',
          label: '郵便番号',
          value: null,
          required: true,
          placeholder: '3760031',
          comment: '半角・ハイフンなしで入力してください',
        },
        {
          name: 'pref',
          type: 'select',
          label: '都道府県',
          value: '群馬県',
          options: this.prefs,
          required: true,
          comment: '',
        },
        {
          name: 'city',
          type: 'text',
          label: '市区町村',
          value: null,
          required: true,
          placeholder: '桐生市本町',
        },
        {
          name: 'address',
          type: 'text',
          label: '番地',
          value: null,
          required: true,
          placeholder: '6丁目2番地',
        },
        {
          name: 'building',
          type: 'text',
          label: '建物名・部屋番号',
          value: null,
        },
      ],
      address: {},
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.corporateurl;
    },
  },
  setup() {
    const corporateurl = ref('');

    const rules = {
      corporateurl: { url },
    };

    const $v = useVuelidate(rules, { corporateurl });

    return { corporateurl, $v };
  },
  created() {
    if (this.returnItem.additionalData) {
      if (this.returnItem.additionalData[0].formLine !== 1) {
        this.values.additional_data = [];
        for (let i = 0; i < this.returnItem.additionalData[0].formLine; i += 1) this.values.additional_data[i] = null;
      }
    }
    const ls = cf.getLocalStorage('fk');
    this.localstorage = ls;
    // ローダー非表示
    this.$emit('loader', false);

    // 初期実装対応 (userデータ参照するので分岐あり・リロード対応)
    if (this.user.email) {
      this.adjustItems();
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.adjustItems();
          this.initialize();
        }
      });
    }
    this.moveToStep(1);
  },
  methods: {
    ...mapActions('payment', [
      'moveToStep',
    ]),

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    initialize() {
      if (this.user.addresses.main.length) { // 住所が登録済みだったら住所登録フラグを更新
        this.flag.userAddress = true;
      }

      if (this.user.login.isLogin) {
        if (this.user.displayname) this.values.displayname = this.user.displayname;
        if (this.user.urls.logo.length) {
          this.values.logo = {
            file: null,
            id: this.user.urls.logo[0].id,
            s3: this.user.urls.logo[0].url,
            preview: null,
          };
        }
        if (this.user.corporatename) this.values.corporatename = this.user.corporatename;
        if (this.user.corporateurl) this.corporateurl = this.user.corporateurl;
      }
    },

    /**
     * 初期アドホック対応
     */
    adjustItems() {
      const adhocItems = [];
      if (this.returnItem.addressRequired) { // 住所登録が必須のリターンであればログインユーザーにも住所を表示
        this.items.forEach((item) => {
          adhocItems.push(item);
        });
      } else {
        this.items.forEach((item) => {
          if (item.name === 'mail') {
            adhocItems.push(item);
          }
        });
      }
      this.items = adhocItems;

      if (this.localstorage.displayname) { // 戻る、別プロジェクト・リターンにした時はlocalstorageから参照
        this.displayname = this.localstorage.displayname;
      } else if (this.user.displayname) { // 新規のときはuserから参照
        this.displayname = this.user.displayname;
      }
    },

    /** supportTypeがguestでなければemailを削除 */
    _adjustItems() {
      if (this.user.login.isLogin) {
        const excludeEmailItems = [];
        this.items.forEach((item) => {
          if (item.name !== 'mail' && item.name !== 'displayname') excludeEmailItems.push(item);
        });
        this.items = excludeEmailItems;
      }
    },

    /** サポートタイプのチェック */
    typeCheck() {
      if (this.localstorage.supportType === 'login'
        && !this.user.login.isLogin) {
        // ログイン（新規登録）を促す
        alert('ログインして支援するにはログインしている必要があります。\n新規登録がお済みでない場合は登録を行ってください。');
        this.$router.push({ path: '/login/' });
      } else if ((this.localstorage.supportType === 'login'
        && this.user.login.isLogin)
        || this.localstorage.supportType === 'guest') {
        // フォームの表示
        this.flag.isEdit = true;
      }
    },

    /** 子から値を受け取る */
    receiveValue(values) {
      this.flag.readySubmit = values.readySubmit;
      this.flag.userAddress = values.readySubmit;
      this.address = values.address;
    },

    /** 確認フラグ更新 */
    updateFlagConfirm(e) {
      e.preventDefault();
      const id = e.currentTarget.id;
      this.flag.confirm = id !== 'back';

      if (id === 'confirm' || id === 'back') {
        this.$scrollTo('#edit-top', 800, { offset: -100 });
      }
    },

    /** 住所編集画面表示非表示フラグ */
    updateIsEdit() {
      this.flag.isEdit = !this.flag.isEdit;
    },

    /**
     * 住所登録サブミット
     * ログインしていて住所登録済
     *  → 更新
     * ログインしていて住所未登録
     *  → user.idあり、住所のみ登録
     * ログインしないで住所未登録
     *  → user.idなし、ユーザー仮登録して住所登録
     */
    submit(e) {
      e.preventDefault();
      this.showLoading();

      // if (this.localstorage.supportType === 'guest'
      //   && !this.user.login.isLogin) {
      //   // ゲスト支援の場合は先にユーザ登録
      //   this.guestRegistUser();
      // } else if (this.localstorage.supportType === 'login'
      //   && this.user.login.isLogin) {
      //   // ログイン済みの場合は住所登録
      //   this.saveAddress(this.user.id);
      // }

      // ユーザ情報の登録・変更
      this.guestRegistUser();
    },

    /** ゲストのユーザ仮登録 */
    guestRegistUser() {
      const data = {
        id: null,
        flag: 200,
        isGuest: true,
        email: this.address.email,
        displayname: this.address.displayname,
      };

      if (this.user.id) {
        data.id = this.user.id;
        // フラグは引き継ぎ（ログインユーザは1）
        data.flag = this.user.flag;
      }

      const isRegist = !data.id;
      const endpoint = isRegist ? 'register' : 'set/update';

      this.axios({
        method: 'POST',
        url: `/v1/user/${endpoint}`,
        data,
      })
        .then((response) => {
          const res = response.data;
          if (isRegist && res.exists) {
            // ゲスト登録済み
            alert('ご指定のメールアドレスはすでにゲスト登録されています。');
            cf.saveLocalStorage({ user_id: res.userID }, 'fk');
            this.hideLoading();
          } else {
            // 初期実装では住所登録しない
            // this.saveAddress(res.userID);
            // リターン送付のため住所登録が必須であれば住所を登録する
            if (this.returnItem.addressRequired) {
              this.saveAddress(res.userID);
            }
            // 初期実装対応
            const type = !isRegist ? '更新' : '登録';
            alert(`情報を${type}しました`);

            if (isRegist) { // 登録の際はlsにidを保存
              cf.saveLocalStorage({ user_id: res.userID }, 'fk');
            }
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          alert('ゲスト登録に失敗しました。お手数ですが管理者までお知らせください');
          this.hideLoading();
        })
        // 初期実装対応
        .finally(() => {
          this.$store.dispatch('user/update', null, { root: true });
          this.flag.confirm = false;
          this.flag.isEdit = false;
          this.hideLoading();
        });
    },

    /** 住所登録 */
    saveAddress(userId) {
      const data = {
        id: null, // 新規登録時はnull
        flag: 1,
        user_id: userId,
        is_primary: 1,
      };
      // 取得した住所情報をdataに格納
      Object.keys(this.address).forEach((key) => { data[key] = this.address[key]; });
      // 住所の更新有無
      if (this.user.addresses && this.user.addresses.main.length) data.id = this.user.addresses.main[0].id;
      // emailとdisplaynameはaddress登録に不要
      delete data.email;
      delete data.displayname;

      // // TODO: 複数住所対応時に変更する必要あり
      const endpoint = data.id ? 'updater' : 'register';

      this.axios({
        method: 'POST',
        url: `/v1/address/set/${endpoint}`,
        data,
      })
        .then(() => {
          this.flag.confirm = false;
          // stateアップデート
          this.$store.dispatch('user/update', null, { root: true });
          this.flag.isEdit = false;
        })
        .catch((error) => {
          alert('住所の登録に失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },

    /** 口数の更新 */
    updateLot(e) {
      const value = e.currentTarget.value;
      this.values.lot = Number(value);
    },

    updateValue(e) {
      this.values[e.currentTarget.id] = e.currentTarget.value;
    },

    changeAdditionalData(e, index) {
      // 再度追加データへの入力があった場合、最後の変更のみを残す
      if (!this.values.additional_data) this.values.additional_data = [];
      if (this.returnItem.type !== 2) {
        this.values.additional_data[0] = e.currentTarget.value;
      } else {
        this.values.additional_data[index - 1] = e.currentTarget.value;
      }
    },

    updateFile(e) {
      this.values[e.currentTarget.id] = {
        file: e.currentTarget.files,
        id: null,
        s3: null,
        preview: window.URL.createObjectURL(e.currentTarget.files[0]),
      };
    },

    deleteLogo() {
      this.values.logo = {
        file: null,
        id: null,
        s3: null,
        preview: null,
      };
    },

    async requiredCheck() {
      if (this.returnItem.addressRequired && !this.flag.userAddress) { // リターン送付のため住所の入力チェック
        alert(`${this.returnItem.addressRequired.label}を入力してください`);
        await this.typeCheck();
        this.$scrollTo('#address-required', 800, { offset: -100 });
        return true;
      }

      if (this.project.additionalData && this.returnItem.additionalData && this.returnItem.additionalData[0].label) {
        if (this.returnItem.type === 0) {
          if (this.values.displayname) { // 表示名あり
            if (this.values.additional_data) { // 追加データあり
              this.saveLocalStorage(null);
            } else alert(`${this.returnItem.additionalData[0].alert}`);
          } else alert('表示名を入力してください。');
        }

        if (this.returnItem.type === 1) {
          if (this.values.corporatename) { // 企業名あり
            if (this.returnItem.additionalData[0].formLine === 1) {
              if (this.values.additional_data) { // 追加データあり
                this.uploadFile();
              } else alert(`${this.returnItem.additionalData[0].alert}`);
            }
          } else alert('企業名を入力してください。');
        }

        if (this.returnItem.type === 2) {
          if (this.values.corporatename) {
            if (this.values.additional_data
              && !(this.values.additional_data.some((value) => value === null))) {
              this.uploadFile();
            } else alert(`${this.returnItem.additionalData[0].alert}`);
          } else alert('団体名を入力してください。');
        }

      /** その他のプロジェクト用判定 */
      } else {
        if (this.returnItem.type === 0) {
          if (this.values.displayname) this.saveLocalStorage(null);
          else alert('表示名を入力してください。');
        }

        if (this.returnItem.type === 1) {
          if (this.values.corporatename) this.uploadFile();
          else alert('企業名を入力してください。');
        }

        if (this.returnItem.type === 2) {
          if (this.values.corporatename) this.uploadFile();
          else alert('団体名を入力してください。');
        }
      }
    },

    uploadFile() {
      if (this.values.logo.file) {
        const form = new FormData();
        form.append('file', this.values.logo.file[0]);

        this.axios({
          method: 'POST',
          url: '/v1/user/set/uploadfile',
          data: form,
          params: {
            id: this.user.id,
            environment: this.helper.env.name,
          },
        })
          .then((response) => {
            const res = response.data.data;
            this.values.logo.s3 = res.uploaded.s3Path;
            this.saveLocalStorage();
          })
          .catch((error) => {
            alert('プロフィール画像のアップロードに失敗しました。');
            if (error.response) console.log(error.response.data);
            else console.log(error);
            this.hideLoading();
          });
      } else {
        this.saveLocalStorage();
      }
    },

    /** 口数をlocalstorageへ保存してページ遷移 */
    saveLocalStorage() {
      if (this.returnItem.additionalData) {
        cf.saveLocalStorage({
          lot: this.values.lot,
          comment: this.values.comment,
          additionaldata: this.values.additional_data,
          displayname: this.values.displayname,
          corporatename: this.values.corporatename,
          corporateurl: this.corporateurl,
          logo: { s3: this.values.logo.s3, url_id: this.values.logo.id },
          label: this.returnItem.additionalData[0].label,
          // displayname: this.displayname,
        }, 'fk');
      } else {
        cf.saveLocalStorage({
          lot: this.values.lot,
          comment: this.values.comment,
          additionaldata: this.values.additional_data,
          displayname: this.values.displayname,
          corporatename: this.values.corporatename,
          corporateurl: this.corporateurl,
          logo: { s3: this.values.logo.s3, url_id: this.values.logo.id },
          label: null,
          // displayname: this.displayname,
        }, 'fk');
      }
      this.$router.push({
        path: `/payment/method/${this.slugs.project}/${this.slugs.return}/`,
      });
    },
  },
};
</script>


<style module lang="scss">
.returnItem {
}

.returnImage {
}

.lot {
  margin-top: 5rem;
}

.comment {
  margin-top: 5rem;
}

.additional_data {
  margin: 10px 0;
}

.notice {
  margin-top: 5rem;
  padding: 1em;
  background-color: #efefef;
  p {
    &:not(:first-child) {
      margin-top: 1em;
    }
  }
}

.btnsWrap {
  margin-top: 5rem;
}

.userInfo {
  margin-top: 5rem;
}

.check {
  margin-left: .5em;
}
</style>
