<template>
  <div class="editCorporate edit">
    <div id="edit-top" class="container">
      <h1 class="title-page mb-4">企業情報編集</h1>
      <form
        class="form horizontal"
        v-if="formItems">
        <div
          v-for="(item, i) in formItems"
          v-bind:key="i"
          class="form-contents">
          <dl class="form-group" v-if="item.type === 'file'">
            <dt class="label form-label">{{ item.label }}</dt>
            <dd
              class="detail file">
              <div
                class="form-parts-file"
                v-if="!values[item.name].preview">
                <input
                  :id="item.name"
                  :type="item.type"
                  :name="item.name"
                  accept=".jpg, .jpeg, .png, .gif"
                  v-on:change="updateFile">
                <p class="form-assistance sm"
                  >推奨サイズ：110 x 110<br>ファイルタイプ：.jpg, .jpeg, .png, .gif</p>
              </div>
              <div
                class="preview"
                v-if="values[item.name].preview"
                :style="`background-image: url(${values[item.name].preview})`">
                <div class="delete_btn" v-on:click="deleteFile(item.name)">
                  <div class="cross"></div>
                </div>
              </div>
            </dd>
          </dl>

          <dl
            class="form-group" v-if="item.type === 'text'">
            <dt class="label">
              <label
                class="form-label"
                v-bind:for="item.name">{{ item.label }}</label>
            </dt>
            <dd
              class="detail"
              v-bind:class="item.type">
              <div class="form-parts">
                <input
                  :id="item.name"
                  :type="item.type"
                  :name="item.name"
                  :value="values[item.name]"
                  v-if="item.type === 'text'"
                  v-on:input="updateValue">
              </div>
            </dd>
          </dl>
        </div>

        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">企業サイトURL</dt>
            <dd class="detail">
              <div class="form-parts">
                <input
                  type="text"
                  name="corporateurl"
                  id="corporateurl"
                  class=""
                  v-model.trim="$v.corporateurl.$model"
                  v-on:input="invalidsCheck('corporateurl', $v.corporateurl.$invalid)">
              </div>
              <p
                class="form-text"
                v-if="$v.corporateurl.$invalid">正しいURLの形式で入力してください。</p>
            </dd>
          </dl>
        </div>


        <div class="form-contents">
          <div class="form-group mt-sm">
            <ul class="btn-lists horizontal">
              <li>
                <button
                  type="button"
                  class="btn btn__primary sm"
                  id="btn_check"
                  :disabled="!submitFlag && corporateurl !== ''"
                  v-on:click="submit"
                  >確認</button>
              </li>
              <li>
                <router-link
                  to="/account/"
                  class="btn btn__tertiary sm">戻る</router-link>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { url } from '@vuelidate/validators';

export default {
  name: 'edit-corporate',
  data() {
    return {
      validationFlags: {
        corporateurl: false,
      },
      invalids: {
        corporateurl: false,
      },
      formItems: [
        {
          name: 'corporatename',
          label: '企業名',
          type: 'text',
        },
        {
          name: 'logo',
          label: '企業ロゴ',
          type: 'file',
        },
      ],
      values: {
        corporatename: null,
        logo: {
          file: null,
          s3: null,
          preview: null,
          updated: false,
        },
      },
    };
  },
  created() {
    if (this.user.email) {
      this.setCorporateData();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') {
          this.setCorporateData();
        }
      });
    }
  },
  setup() {
    const corporateurl = ref('');

    const rules = {
      corporateurl: { url },
    };

    const $v = useVuelidate(rules, { corporateurl });

    return { corporateurl, $v };
  },
  computed: {
    ...mapState(['user', 'helper']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.corporateurl;
    },
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    setCorporateData() {
      this.values.corporatename = this.user.corporatename;
      const path = this.user.urls.logo.length
        ? this.user.urls.logo[0].url
        : null;
      this.values.logo = {
        file: null,
        s3: path,
        preview: path,
      };
      this.corporateurl = this.user.corporateurl;
    },

    updateValue(e) {
      this.values[e.target.id] = e.target.value;
    },

    updateFile(e) {
      this.values[e.target.id] = {
        file: e.target.files,
        s3: null,
        preview: window.URL.createObjectURL(e.target.files[0]),
        updated: true,
      };
    },

    deleteFile(id) {
      this.values[id] = {
        file: null,
        s3: null,
        preview: null,
        updated: true,
      };
    },

    submit() {
      if (!this.values.corporatename) {
        alert('企業名を入力してください。');
        return;
      }

      this.showLoading();

      // 新規画像の登録がある場合
      if (this.values.logo.file) {
        this.uploadFile();
      } else {
        this.updateData();
      }
    },

    uploadFile() {
      const form = new FormData();
      form.append('file', this.values.logo.file[0]);

      this.axios({
        method: 'POST',
        url: '/v1/user/set/uploadfile',
        data: form,
        params: {
          id: this.user.id,
          environment: this.helper.env.name,
        },
      })
        .then((response) => {
          const res = response.data.data;
          this.values.logo.s3 = res.uploaded.s3Path;
          this.updateData();
        })
        .catch((error) => {
          alert('プロフィール画像のアップロードに失敗しました。');
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    updateData() {
      const id = this.user.urls.logo.length ? this.user.urls.logo[0].id : null;

      const data = {
        id: this.user.id,
        corporatename: this.values.corporatename,
        corporateurl: this.corporateurl,
        url_id: id,
        logo: this.values.logo.s3,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateCorporateData',
        data,
      })
        .then(() => {
          alert('企業情報の登録が完了しました。');
          this.$store.dispatch('user/update', null, { root: true });
          this.$router.push('/account/');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },

};
</script>
