<template>
  <div id="login" class="login accounts">
    <div class="container-sm">
      <h2 class="title-page lg">ログイン</h2>

      <div
        class="expired-message box-shadow"
        v-if="isExpired">
        <p class="text-danger">ログイン有効期限が切れました。お手数ですが再度ログインしなおしてください。</p>
      </div>


      <form
        class="form vertical"
        v-on:submit="submit"
        v-bind:class="{ vibration: user.login.vibration }"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label
                for="mail"
                class="form-label">メールアドレス</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="mail"
                  class=""
                  type="email"
                  name="mail"
                  v-model.trim="$v.mail.$model"
                  v-bind:class="{ input_error: validationFlags.mail }"
                  v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.required.$invalid">メールアドレスを入力してください</p>

              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
            </dd>
          </dl>

          <dl class="form-group">
            <dt class="label">
              <label
                for="password"
                class="form-label">パスワード</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="password"
                  class=""
                  type="password"
                  name="password"
                  v-model.trim="$v.password.$model"
                  v-bind:class="{ input_error: validationFlags.password }"
                  v-on:input="invalidsCheck('password', $v.password.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.password.$dirty
                  && $v.password.required.$invalid">パスワードを入力してください</p>
            </dd>
          </dl>
          <p
            class="form-assistance error-text"
            v-if="user.login.isBanned">パスワードを10回間違えたため、{{ user.login.unbannedTime }}までログインが制限されています。<br>制限を解除したい場合は運営まで連絡してください。</p>

          <p
            class="form-assistance error-text"
            v-if="user.login.failedReason.passwordMismatch">パスワードが間違っています</p>
          
          <p
            class="form-assistance error-text"
            v-if="user.login.failedReason.userNotFound">登録されていないメールアドレスです。</p>

          <div class="form-group btns mt-sm">
            <ul class="btn-lists">
              <li>
                <button
                  type="submit"
                  class="btn btn__primary sm"
                  v-bind:disabled="!submitFlag">ログイン</button>
              </li>
            </ul>

            <div class="mt-4">
              <router-link
                to="/forgot-password/">パスワードをお忘れですか？</router-link>
            </div>

            <div class="mt-4">
              <router-link
                to="/signup/">新規登録はこちら</router-link>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'login',
  mixins: [cf],
  data() {
    return {
      validationFlags: {
        mail: false,
        password: false,
      },
      invalids: {
        mail: true,
        password: true,
      },
      // 有効期限切れでリダイレクトされた
      isExpired: false,
    };
  },
  created() {
    if (this.$route.query
      && this.$route.query.expired) this.isExpired = true;
    this.communicate();
  },
  setup() {
    const mail = ref('');
    const password = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
      // パスワードは必須
      password: { required },
    };

    const $v = useVuelidate(rules, { mail, password });

    return { mail, password, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !this.invalids.password;
    },
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;

      this.$store.dispatch('user/login/refresh', bool);
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      // validateチェックは入力時に行われてる
      const data = {
        mail: this.mail,
        password: this.password,
      };
      this.$store.dispatch('user/login/submit', data);
    },

    communicate() {
      this.axios({
        method: 'GET',
        url: '/v1/connection/test',
        params: {},
      })
        .then((response) => {
          console.log(`status: ${response.data.status}: ${response.data.userTotal}`);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

