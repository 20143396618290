<template>
  <div class="payment">
    <div class="loader-wrapper">
      <div
        class="loaded-content"
        v-show="!flag.isLoader">

        <contents-container
          :size="'sm'"
          v-if="!flag.notFound && !flag.invalid">
          <div :class="$style.progressWrap">
            <h1 :class="$style.stepTitle">{{stepTitles[payment.step - 1]}}</h1>
            <div :class="$style.progressBar">
              <div :class="[$style.paymentStep, $style[`step${payment.step}`]]">
              </div>
            </div>
          </div>
          <router-view
            v-if="project && returnItem"
            :project="project"
            :returnItem="returnItem"
            :slugs="slugs"
            :prefs="helper.master.prefs"
            @invalid="updateInvalid"
            @loader="updateLoader" />
        </contents-container>

        <NotFound
          v-if="flag.notFound && !flag.invalid" />

        <Invalid
          v-if="flag.invalid && !flag.notFound" />
      </div>

      <div
        class="loader-simple"
        v-if="flag.isLoader"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NotFound from '@/views/404.vue';
import { ContentsContainer } from '@/components/parts/Container';
import cf from '@/mixins/commonFunctions';
import Invalid from '../Invalid.vue';

export default {
  name: 'PaymentDetail',
  mixins: [cf],
  components: {
    NotFound,
    ContentsContainer,
    Invalid,
  },
  data() {
    return {
      slugs: {
        project: null,
        return: null,
      },
      stepTitles: [
        'リターン内容の確認',
        'お支払い方法の選択',
        'お支払い情報の入力',
        'ご確認',
        'ご支援ありがとうございます！',
      ],
      flag: {
        isLoader: true,
        notFound: false,
        invalid: false,
      },
      project: null,
      returnItem: null,
    };
  },
  computed: {
    ...mapState(['user', 'payment', 'helper']),
  },
  created() {
    if (this.helper.master.projects) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.initialize();
        }
      });
    }

  },
  watch: {
    $route() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      // paymentトップは使用しないため404
      if (this.$route.name === 'payment') {
        this.flag.notFound = true;
        this.flag.isLoader = false;
        return;
      }

      this.flag.isLoader = true;
      // ログインなのかゲストなのか
      const ls = cf.getLocalStorage('fk');
      if (!ls || !ls.supportType) { // 直リンクでlsがない場合はNG
        this.flag.notFound = true;
        this.flag.isLoader = false;
        return;
      }

      if (this.$route.params.project && this.$route.params.return) {
        this.slugs = {
          project: this.$route.params.project,
          return: this.$route.params.return,
        };
      } else {
        this.flag.notFound = true;
      }

      this.getProjectDetail();
      this.getStepNum();
    },

    getProjectDetail() {
      const detail = cf.getProjectDetailFromYaml(this.slugs, this.helper.master.projects);
      if (!detail.project || !detail.return) {
        this.flag.notFound = true;
      }
      this.project = detail.project;
      this.returnItem = detail.return;
    },

    // プログレスバーの進行度
    getStepNum() {
      const split = this.$route.name.split('-');
      this.step = split[split.length - 1];
    },

    // 子コンポーネントからloaderフラグ受け取り
    updateLoader(bool) {
      this.flag.isLoader = bool;
    },

    // 子コンポーネントからinvalidフラグ受け取り
    updateInvalid(value) {
      this.flag.invalid = value;
    },
  },
};
</script>


<style module lang="scss">
.progressWrap {
  margin-bottom: 4em;
}

.stepTitle {
  margin: 20px 0;
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}

.progressBar {
  background-color: rgba(black, .12);
  height: 10px;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
}

.paymentStep {
  position: relative;
  height: 100%;
  background-color: #1da6f2;
  border-radius: 5px;
  transition: width 800ms ease-in-out;
  will-change: width;

  &::after {
    $size: 1.4em;
    content: "";
    width: $size;
    height: $size;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -($size / 2);
    margin: auto;
    background-color: #1da6f2;
    border-radius: 100%;
  }

  // stepの総数
  $stepLen: 5;
  @for $i from 1 through $stepLen {
    &.step#{$i} {
      width: (100% / $stepLen) * $i;
    }
  }
}

@media screen and (max-width: 576px) {
  .stepTitle {
    font-size: 23px;
  }
}

@media screen and (max-width: 369px) {
  .stepTitle {
    font-size: 19px;
  }
}
</style>
