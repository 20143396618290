<template>
  <div class="">
    <div class="title">
      <p>申し込み内容確認</p>
    </div>

    <div class="">
      <dl class="mt-4">
        <dt class="label">
          <label class="">サブスクリプション</label>
        </dt>
        <dd :class="$style.detail">
          <div class="">
            <label for="default-card">{{ data.product.name }}</label>
          </div>
        </dd>
      </dl>
      <dl class="mt-4">
        <dt class="label">
          <label class="">プラン</label>
        </dt>
        <dd :class="$style.detail">
          <div class="">
            <label for="default-card">{{ data.price.nickname }}</label>
          </div>
        </dd>
      </dl>
      <dl class="mt-4">
        <dt class="label">
          <label class="">支払い方法</label>
        </dt>
        <dd :class="$style.detail">
          <div :class="$style.cardWrap">
            <div :class="$style.card">
              <div
                :style="`background-image: url(/img/cards/${cards[card.brand]}.png);`"
                :class="$style.icon"></div>
            </div>
            <label for="default-card">**** **** **** {{ card.last4 }}</label>
          </div>
        </dd>
        <p :class="$style.assistant">サブスクリプションはデフォルトに指定されているカードからのお支払いとなります。カードの変更はマイページから可能です。</p>
      </dl>
    </div>

    <div>
      <ul class="btn-lists horizontal mt-5">
        <li v-if="paymentType === 'regist'">
          <button
            class="btn btn__primary"
            v-on:click="regist">次へ</button>
        </li>
        <li v-if="paymentType !== 'regist'">
          <button
            class="btn btn__primary"
            v-on:click="confirm">確定</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'formsubscription',
  mixins: [cf],
  props: ['data'],
  components: {
  },
  data() {
    return {
      card: {},
      cards: {
        Visa: 'visa',
        MasterCard: 'mastercard',
        'American Express': 'amex',
        JCB: 'jcb',
        'Diners Club': 'diners-club',
        Discover: 'discover', 
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    if (this.user.cards.flag) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/cards/putAllData') {
          this.initialize();
        }
      });
    }
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    initialize() {
      this.user.cards.cards.forEach((card) => {
        if (card.id === this.user.cards.default) this.card = card;
      });
    },

    regist() {
      const args = {
        modalName: 'formSubscription',
        data: this.data,
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    confirm() {
      if (!confirm('お支払いを確定します。よろしいですか？')) return;

      this.showLoading();
      
      const data = {
        isExist: true,
        user_id: this.user.id,
        productObj: this.data.product,
        priceObj: this.data.price,
        taxObj: this.data.tax,
        customer_id: this.user.customer.customer_id,
        card_id: this.card.id,
        customer: {
          email: this.user.email,
          name: this.user.username,
        },
      };
      // 支払いを実行
      try {
        this.axios({
          method: 'POST',
          url: '/v1/stripe/subscription/set/register',
          data,
        })
          .then(() => {
            const customerData = {
              customer: { customer_id: this.user.customer.customer_id },
            };
            this.$store.dispatch('user/cards/getCardData', customerData, { root: true });
            this.$store.dispatch('user/subscriptions/getSubscriptions', customerData, { root: true });
            alert('お支払い情報の登録が完了しました。');
          })
          .catch((error) => {
            if (error.response) console.log(error.response.data);
            else console.log(error);
          })
          .finally(() => {
            this.$store.dispatch('modal/contents/hideModal', null, { root: true });
            this.hideLoading();
          });
      } catch (err) {
        if (err.response) console.log(err.response.data);
        else console.log(err);
        alert('お支払い情報の登録に失敗しました。\nお手数ですが管理者までお問い合わせください');
        this.hideLoading();
        this.$store.dispatch('modal/contents/hideModal', null, { root: true });
      }
    },
  },
};
</script>


<style lang="scss" module>
.list {
  margin: .3em 0;
}

.cardWrap {
  display: flex;
  align-items: center;
}

.card {
  width: 2.4em;
  border-radius: 2px;
  overflow: hidden;
  margin: auto 5px auto 0;
}

.icon {
  padding-top: percentage(142/220);
  background-size: cover;
}

.assistant {
  margin-top: .5em;
  color: #777;
  font-size: 12px;
  line-height: 18px;
}

.detail {
  background-color: #eeeeee;
  padding: .7em;
  border-radius: .5em;
}
</style>
