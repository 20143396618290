<template>
  <div class="ball-scale-ripple-multiple">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'modalLoadingBallScaleRippleMultiple',
};
</script>
