<template>
  <div class="account accounts">
    <div class="container">
      <h2 class="title-page lg">アカウント</h2>
      <div class="loader-wrapper">
        <div
          class="loaded-contents"
          v-show="!flag.loader">
          <div
            class="account-categories"
            v-if="categories.length">
            <dl
              class="category"
              v-for="(category, i) in categories"
              v-bind:key="i">
              <dt>{{ category.label }}</dt>
              <dd>
                <div
                  class="items"
                  v-if="category.items.length">
                  <div
                    class="item"
                    v-for="(item, j) in category.items"
                    v-bind:key="`${i}-${j}`">

                    <!-- お届け先住所以外 -->
                    <div
                      class="registed-value"
                      v-if="values[item.name] !== 'hidden'
                        && category.name !== 'mainAddress' && item.name !== 'default_card' && item.name !== 'subscription'">
                      <p>
                        <span v-if="item.name !== 'ageGroup'">{{ values[item.name] }}</span>
                        <span v-if="item.name === 'ageGroup'
                          && helper.master
                          && helper.master.ages">{{ filterAgeGroup(helper.master.ages, values[item.name]) }}</span>
                        <span v-if="item.label">（{{ item.label }}）</span></p>
                    </div>

                    <!-- お届け先住所 -->
                    <div
                      class="registed-value n2br"
                      v-else-if="values[item.name] !== 'hidden' && item.name !== 'default_card' && item.name !== 'subscription'">
                      <p>{{ values.mainAddress }}</p>
                    </div>

                    <!-- サブスクリプション -->
                    <div
                      class="registed-value n2br"
                      v-else-if="values[item.name] !== 'hidden' && item.name !== 'default_card'">
                      <p>{{ user.subscriptions.subscriptionIds.length }}件</p>
                    </div>


                    <!-- カード情報 -->
                    <div
                      class="registed-value"
                      v-else-if="values[item.name] !== 'hidden'">
                      <div :class="$style.cardwrap">
                        <div :class="$style.card" v-if="user.cards.cards.length">
                          <div
                            :style="`background-image: url(/img/cards/${cards[cardType]}.png);`"
                            :class="$style.icon"></div>
                        </div>
                        <p><span>{{ values[item.name] }}</span></p>
                      </div>
                    </div>

                    <div
                      class="link"
                      v-if="item.change">
                      <p>
                        <router-link v-bind:to="item.change.link">{{ item.change.label }}</router-link>
                      </p>
                    </div>
                  </div>
                </div>
              </dd>
            </dl>
          </div>
        </div>
        <div
          class="loader-simple"
          v-if="flag.loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'Account',
  mixins: [cf],
  data() {
    return {
      categories: [
        {
          label: 'メンバーシップ',
          name: 'membership',
          items: [
            {
              name: 'email',
              change: {
                link: '/edit/email/',
                label: 'メールアドレスの変更',
              },
            },
            {
              name: 'password',
              change: {
                link: '/edit/password/',
                label: 'パスワードの変更',
              },
            },
          ],
        },
        {
          label: 'プロフィール',
          name: 'profile',
          items: [
            {
              name: 'username',
              label: 'お名前',
              change: {
                link: '/edit/profile/',
                label: 'プロフィールの変更',
              },
            },
            {
              name: 'displayname',
              label: '表示名',
            },
            {
              name: 'ageGroup',
              label: '年代',
            },
          ],
        },
        {
          label: '企業情報',
          name: 'corporate',
          items: [
            {
              name: 'corporatename',
              change: {
                link: '/edit/corporate/',
                label: '企業に関する情報の変更',
              },
            },
          ],
        },
        {
          label: 'お届け先',
          name: 'mainAddress',
          items: [
            {
              name: 'mainAddress',
              change: {
                link: '/edit/addresses/',
                label: 'お届け先の変更',
              },
            },
          ],
        },
        // {
        //   label: 'サブスクリプション',
        //   name: 'subscription',
        //   items: [
        //     {
        //       name: 'subscription',
        //       change: {
        //         link: '/edit/subscription/',
        //         label: 'プランの変更・解約',
        //       },
        //     },
        //   ],
        // },
        {
          label: 'お支払い回数',
          name: 'payment',
          items: [
            {
              name: 'default_card',
              change: {
                link: '/edit/payment/',
                label: 'お支払い方法の変更',
              },
            },
            {
              name: 'payment',
              change: {
                link: '/edit/payment-list/',
                label: '履歴一覧',
              },
            },
          ],
        },
      ],
      values: {},
      cardType: null,
      cards: {
        Visa: 'visa',
        MasterCard: 'mastercard',
        'American Express': 'amex',
        JCB: 'jcb',
        'Diners Club': 'diners-club',
        Discover: 'discover',
      },
      flag: {
        loader: true,
      },
    };
  },
  created() {
    if (this.user.email) {
      this.getUserValues();
    } else {
      this.$store.subscribe((mutation) => {
        // ユーザ情報を取得してvaluesを定義
        if (mutation.type === 'user/setUserData') this.getUserValues();
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  methods: {
    getUserValues() {
      // TODO: store整備後に再度確認！！！

      // categories.items[n].nameに応じたvalueを取得
      this.categories.forEach((category) => {
        if (category.items && category.name !== 'mainAddress') { // 住所以外
          category.items.forEach((item) => {
            if (this.user[item.name]) { // 一階層目にあればそのまま
              this.values[item.name] = this.user[item.name];
            } else if (item.name === 'password') { // なければ個別に
              this.values.password = '********';
            } else if (item.name === 'payment') {
              if (this.user.customer && this.user.customer.intents) {
                this.values.payment = `${this.user.customer.intents.length}件`;
              } else {
                this.values.payment = '0件';
              }
            // } else if (item.name === 'subscriptions') {
            } else if (item.name === 'default_card') {
              if (this.user.customer) {
                if (this.user.cards.flag) {
                  this.getCardData();
                } else {
                  this.$store.subscribe((mutation) => {
                    // ユーザ情報を取得してvaluesを定義
                    if (mutation.type === 'user/cards/putAllData') this.getCardData();
                  });
                }
              } else {
                this.values.default_card = '未設定';
              }
              // const intentCount = this.user.customer && this.user.customer.intents.length ? this.user.customer.intents.length : 0;
              // this.values.payment = `${intentCount}件`;
            } else {
              this.values[item.name] = '未設定';
            }
          });
        } else { // 住所関連
          this.values.mainAddress = '未設定';
          if (this.user.addresses.main.length) { // メイン住所登録済み
            const main = this.user.addresses.main[0];
            this.values.mainAddress = `${main.name}\n${main.telNumber}\n${cf.methods.filterZip(main.zip)}\n${main.pref}${main.city}${main.address}`;
            if (main.building) {
              this.values.mainAddress += `\n${main.building}`;
            }
          }
        }
      });

      this.flag.loader = false;
    },

    getCardData() {
      const params = {
        customer_id: this.user.customer.customer_id,
      };
      this.axios({
        method: 'GET',
        url: 'v1/stripe/customer/get/stripeData',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.setCardData(res.customer);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {});
    },

    setCardData(customer) {
      if (this.user.cards.cards.length) {
        this.user.cards.cards.forEach((card) => {
          if (card.id === customer.default_source) {
            this.values.default_card = `**** **** **** ${card.last4}`;
            this.cardType = card.brand;
          } else {
            this.values.default_cards = '未設定';
          }
        });
      } else {
        this.values.default_cards = '未設定';
      }
    },
  },
};
</script>


<style lang="scss" module>
.list {
  margin: .3em 0;
}

.cardwrap {
  display: flex;
}

.card {
  display: inline-block;
  width: 2.4em;
  border-radius: 2px;
  overflow: hidden;
  margin: auto 5px auto 0;
}

.icon {
  padding-top: percentage(142/220);
  background-size: cover;
}
</style>
