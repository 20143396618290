import { createStore } from 'vuex';
import modal from './modules/modal';
import layout from './modules/layout';
import helper from './modules/helper';
import page from './modules/page';
import user from './modules/user';
import payment from './modules/payment';

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    modal,
    layout,
    helper,
    page,
    user,
    payment,
  },
});
