<template>
  <div class="editEmail edit">
    <div class="container">
      <h2 class="title-page lg">メールアドレス更新</h2>

     <form
        class="form vertical"
        v-on:submit="submit"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label
                for="mail"
                class="form-label">メールアドレス</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="mail"
                  type="email"
                  name="mail"
                  v-model.trim="$v.mail.$model"
                  v-bind:class="{ input_error: validationFlags.mail }"
                  v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
                  >
              </div>
              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.required.$invalid">メールアドレスを入力してください</p>
              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
            </dd>
          </dl>

          <div class="form-group mt-sm">
            <ul class="btn-lists horizontal">
              <li>
                <button
                  class="btn btn__primary sm"
                  type="submit"
                  v-bind:disabled="!submitFlag">メールアドレス更新</button>
              </li>
              <li>
                <router-link
                  to="/account/"
                  class="btn btn__tertiary sm">戻る</router-link>
              </li>
            </ul>

            <div class="messages mt-4">
              <p
                v-if="!flg.success
                  && !flg.userNotFound
                  && !flg.duplicate
                  && !flg.error">更新したいメールアドレスを入力して送信してください。</p>
              <p
                v-if="flg.success">更新するメールアドレス宛に確認メールを送信しました。<br>現在は更新完了していません。<br>メールに記載されたURLからアクセスし、メールアドレスの更新を完了してください。</p>
              <p
                v-if="flg.userNotFound">ユーザが見つかりませんでした。</p>
              <p
                v-if="flg.duplicate">送信いただいた更新メールアドレスはすでに別アカウントで登録済みです。<br>メールアドレスの重複登録はできません。<br>別のメールアドレスでお試しください。</p>
              <p
                v-if="flg.error">メールアドレスの更新に失敗しました。お手数ですがリロードして再度お試しください。</p>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-email',
  mixins: [cf],
  data() {
    return {
      flg: {
        success: false,
        userNotFound: false,
        duplicate: false,
        error: false,
      },
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
    };
  },
  created() {
  },
  setup() {
    const mail = ref('');

    const rules = {
      // 必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });
    return { mail, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      return !this.invalids.mail && !this.flg.success;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      this.showLoading();
      // 初期化
      this.flg.success = false;
      this.flg.userNotFound = false;
      this.flg.duplicate = false;
      this.flg.error = false;

      const data = {
        id: this.user.id,
        email: this.mail,
      };

      this.axios({
        method: 'POST',
        url: '/v1/user/set/updateEmail',
        data,
      })
        .then((response) => {
          const res = response.data;
          if (res.duplicate) this.flg.duplicate = true;
          if (res.notFound) this.flg.notFound = true;
          if (!res.duplicate && !res.notFound) this.flg.success = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.flg.error = true;
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
