<template>
  <section class="intro-tab">
    <div class="intro-tab_content" :class="$style.content" v-html="project.content" />

    <section>
      <p :class="$style.headline">新着情報</p>
      <news-tab :project="project" @setNewsData="updateNewsData"/>
    </section>

    <section>
      <p :class="$style.headline">応援コメント</p>
      <comment-tab :project="project" />
    </section>

    <section class="intro-tab_sponsors_wrap">
      <div
        v-if="!manual && corporateSponsors.logo_LL.length + corporateSponsors.logo_L.length + corporateSponsors.logo_M.length + corporateSponsors.logo_S.length"
        class="intro-tab_sponsors">
        <p class="intro-tab_sponsors_title">企業協賛</p>

        <ul
          class="intro-tab__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-for="(row, i) in corporateSponsors.logo_LL"
            :key="i"
            class="logo_LL">
              <component
              v-if="row.img"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              :style="`background-image: url(${row.img})`"
              class="intro-tab__logo"
              target="_blank">
            </component><!--画像のない企業もフッター以外ではここに載せたいのでアイコンなしならここに載せる-->
            <div v-else class="alternative text_logo_LL">
              <!--ロゴなし企業でもリンクがあればリンク先に飛ばす-->
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
            <div
              class="intro-tab__logo__list__name"
              v-if="row.img">{{ row.name }}</div>
          </li>
        </ul>

        <ul
          class="intro-tab__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-for="(row, i) in corporateSponsors.logo_L"
            :key="i"
            class="logo_L">
              <component
              v-if="row.img"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              :style="`background-image: url(${row.img})`"
              class="intro-tab__logo"
              target="_blank">
            </component>
            <div v-else class="alternative text_logo_L">
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
            <div
              class="intro-tab__logo__list__name"
              v-if="row.img">{{ row.name }}</div>
          </li>
        </ul>

        <ul
          class="intro-tab__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-for="(row, i) in corporateSponsors.logo_M"
            :key="i"
            class="logo_M">
              <component
              v-if="row.img"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              :style="`background-image: url(${row.img})`"
              class="intro-tab__logo"
              target="_blank">
            </component>
            <div v-else class="alternative text_logo_M">
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
            <div
              class="intro-tab__logo__list__name"
              v-if="row.img">{{ row.name }}</div>
          </li>
        </ul>

        <ul
          class="intro-tab__logo__list"
          :class="{ 'mgn-b' : corporateSponsorsText.length }">
          <li
            v-for="(row, i) in corporateSponsors.logo_S"
            :key="i"
            class="logo_S">
              <component
              v-if="row.img"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              :style="`background-image: url(${row.img})`"
              class="intro-tab__logo"
              target="_blank">
            </component>
            <div v-else class="alternative text_logo_S">
              <a
                v-if="row.link"
                :href="row.link">{{row.name}}</a>
              <div v-else>{{row.name}}</div>
            </div>
            <div
              class="intro-tab__logo__list__name"
              v-if="row.img">{{ row.name }}</div>
          </li>
        </ul>

        <div
          class="intro-tab__p"
          v-if="corporateSponsorsText.length">
          <p
            v-for="(row, i) in corporateSponsorsText"
            :key="i">
            <component
              :is="row.link ? 'a' : 'span'"
              :href="row.link ? row.link : ''"
              :target="row.link ? '_blank' : ''"
            >{{ row.name }}</component>
          </p>
        </div>
      </div>

      <div
        class="intro-tab_sponsors"
        v-if="!manual && personalSponsors.length">
        <p class="intro-tab_sponsors_title">個人協賛</p>
        <div
          class="intro-tab__p"
          v-if="personalSponsors.length">
          <p
            v-for="(row, i) in personalSponsors"
            :key="i">{{ row.name }}</p>
        </div>
      </div>

      <div
        v-if="manual"
        class="intro-tab_sponsors">
        <p class="intro-tab_sponsors_title">企業協賛</p>
        <ul
          class="intro-tab_sponsors_corporate_wrapper intro-tab__logo__list"
          style="margin-bottom: 4em;">
          <li
            v-for="(row, i) in project.corporateSponsors.courseA"
            :key="i"
            class="logo_M"
            style="padding: 0 0.5em 0 0.5em;">
            <component
              class="intro-tab__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              >
              <div
                class="intro-tab__logo"
                :style="`background-image: url(${row.src}); background-position: center;`"></div>
            </component>
          </li>
          <li
            v-for="(row, i) in project.corporateSponsors.courseB"
            :key="i"
            class="logo_M"
            style="padding: 0 0.5em 0 0.5em;">
            <component
              class="intro-tab__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              >
              <div
                class="intro-tab__logo"
                :style="`background-image: url(${row.src}); background-position: center;`"></div>
            </component>
          </li>
          <li
            v-for="(row, i) in project.corporateSponsors.courseC"
            :key="i"
            class="logo_M"
            style="padding: 0 0.5em 0 0.5em;">
            <component
              class="intro-tab__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              >
              <div
                class="intro-tab__logo"
                :style="`background-image: url(${row.src}); background-position: center;`"></div>
            </component>
          </li>
        </ul>

          <p class="intro-tab_sponsors_title">個人協賛</p>
          <div
            class="intro-tab__p"
            v-if="project.personalSponsors.length">
            <p
              v-for="(row, i) in project.personalSponsors"
              :key="i">{{ row }}</p>
          </div>
        </div>

      <div
        v-if="manual"
        class="intro-tab_sponsors"
        style="margin-top: 4em;">
        <p class="intro-tab_sponsors_title">設営協力</p>
        <ul
          class="intro-tab_sponsors_corporate_wrapper intro-tab__logo__list"
          style="margin-bottom: 4em;">
          <li
            v-for="(row, i) in project.corporateSupporters"
            :key="i"
            class="logo_M"
            style="padding: 0 0.5em 0 0.5em;">
            <component
              class="intro-tab__logo__wrap"
              :is="row.link ? 'a' : 'div'"
              :href="row.link ? row.link : ''"
              >
              <div
                class="intro-tab__logo"
                :style="`background-image: url(${row.src}); background-position: center;`"></div>
            </component>
          </li>
        </ul>

        <div
          class="intro-tab__p"
          v-if="project.personalSponsors.length">
          <p
            v-for="(row, i) in project.personalSupporters"
            :key="i">{{ row }}</p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import NewsTab from './NewsTab.vue';
import CommentTab from './CommentTab.vue';

export default {
  components: {
    NewsTab,
    CommentTab,
  },
  name: 'IntroTab',
  props: ['project'],
  data() {
    return {
      // ---------- 暫定 ---------------
      manual: false,
      // -------------------------
      sales: [],
      corporateSponsors: {
        logo_LL: [],
        logo_L: [],
        logo_M: [],
        logo_S: [],
      },
      corporateSponsorsText: [],
      personalSponsors: [],
    };
  },
  computed: {
    ...mapState(['helper']),
  },
  created() {
    if (this.helper.master.manualLogos) {
      this.getSales();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.getSales();
        }
      });
    }
    /** ロゴのない企業も金額に応じてcorporateSponsorsに追加 */
    this.switchManual();
  },
  methods: {
    switchManual() {
      if (this.project.id === 1) this.manual = true;
      else this.manual = false;
    },

    getSales() {
      const params = {
        project_id: this.project.id,
      };
      this.axios({
        method: 'GET',
        url: '/v1/sales/get/list',
        params,
      })
        .then((response) => {
          const res = response.data;
          this.sales = res.data;
          this.adjustSponsors();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    adjustSponsors() {
      // 除外アカウントと手動表示ロゴ
      const plogos = this.helper.master.manualLogos[this.project.id];

      const sponsoredUserIds = [];
      const sponsoredUser = [];
      // sales毎にuser抽出
      if (plogos) {
        // 手動ロゴの表示がある場合
        this.sales.forEach((sale) => {
          // 手動ロゴを表示するため代理で購入したアカウントを除外
          if (sale.users && plogos && (sale.users.email !== plogos.excludeAccount)) {
            if (!sponsoredUserIds.includes(sale.user_id)) {
              // 重複がない場合にはsponsoredUserにデータを追加
              sponsoredUserIds.push(sale.user_id);
              sponsoredUser.push({
                id: sale.user_id,
                userData: sale.users,
                sales: [sale],
              });
            } else {
              sponsoredUser.forEach((user) => {
                if (user.id === sale.user_id) {
                  user.sales.push(sale);
                }
              });
            }
          }
        });
      } else {
        this.sales.forEach((sale) => {
          if (sale.users) {
            if (!sponsoredUserIds.includes(sale.user_id)) {
              // 重複がない場合にはsponsoredUserにデータを追加
              sponsoredUserIds.push(sale.user_id);
              sponsoredUser.push({
                id: sale.user_id,
                userData: sale.users,
                sales: [sale],
              });
            } else {
              sponsoredUser.forEach((user) => {
                if (user.id === sale.user_id) {
                  user.sales.push(sale);
                }
              });
            }
          }
        });
      }

      sponsoredUser.forEach((user) => {
        let userAmount = 0;
        let latestDate = null;
        let corporateFlag = false;
        let logoSize = 1;

        user.sales.forEach((sale) => {
          // 支援額加算
          userAmount += sale.amount;

          // 最新支援日時格納
          if (!latestDate || latestDate < sale.created_at) latestDate = sale.created_at;

          // リターンのタイプによって企業か個人か判定
          const corporateTypes = [1, 2];
          this.project.returns.forEach((row) => {
            if (row.id === sale.return_id && corporateTypes.includes(row.type)) corporateFlag = true;
          });

          if (corporateFlag) {
            if (userAmount >= 50000) logoSize = 3;
            else if (userAmount >= 30000) logoSize = 2;
          }
        });

        // 企業での支援が一度でもある場合、企業協賛として表示
        // ロゴの登録の有無で追加する配列分ける
        if (corporateFlag && user.userData) {
          // if (user.sales.length >= 2) { // 同一アカウントで複数購入
          //   user.sales.forEach((sale) => {
          //     if (sale.displayname) { // 同意アカウントで個人支援がある
          //       this.personalSponsors.push({
          //         name: sale.displayname.displayname,
          //         amount: sale.amount,
          //         latest: sale.created_at,
          //       });
          //     }
          //   });
          // }
          if (user.sales.length >= 2) { // 同一アカウントで複数購入
            const personalList = [];
            user.sales.forEach((sale) => {
              if (sale.displayname) { // 同意アカウントで個人支援がある
                if (personalList.filter((elem) => elem.name === sale.displayname.displayname).length) {
                  personalList[0].amount += sale.amount;
                  personalList[0].latest = sale.created_at;
                } else {
                  personalList.push({
                    name: sale.displayname.displayname,
                    amount: sale.amount,
                    latest: sale.created_at,
                  });
                }
              }
            });
            personalList.forEach((personalsponsor) => {
              this.personalSponsors.push(personalsponsor);
            });
          }

          const data = {
            name: user.userData.corporatename,
            link: user.userData.corporateurl,
            amount: userAmount,
            latest: latestDate,
          };
          if (user.userData.urls && user.userData.urls.logo.length && user.userData.urls.logo[0].url) {
            data.img = user.userData.urls.logo[0].url;
            data.alt = user.userData.name;
            data.size = logoSize;
            if (data.amount >= 500000) {
              this.corporateSponsors.logo_LL.push(data);
            } else if (data.amount >= 50000) {
              this.corporateSponsors.logo_L.push(data);
            } else if (data.amount < 50000 && data.amount >= 30000) {
              this.corporateSponsors.logo_M.push(data);
            } else {
              this.corporateSponsors.logo_S.push(data);
            }
          } else {
            data.img = '';
            data.alt = '';
            data.size = '';
            if (data.amount >= 500000) {
              this.corporateSponsors.logo_LL.push(data);
            } else if (data.amount >= 50000) {
              this.corporateSponsors.logo_L.push(data);
            } else if (data.amount < 50000 && data.amount >= 30000) {
              this.corporateSponsors.logo_M.push(data);
            } else {
              this.corporateSponsors.logo_S.push(data);
            }
            // this.corporateSponsorsText.push(data);
          }

        } else if (user.sales.length >= 2) { // 個人協賛の配列に必要項目追加
          const personalList = [];

          user.sales.forEach((sale) => {
            if (sale.displayname.displayname !== 'ST company') {
              const registData = {
                name: sale.displayname.displayname,
                amount: sale.amount,
                latest: sale.created_at,
              };
              if ((personalList.filter((elem) => elem.name === registData.name)).length) { // 同じ名前で複数支援があったらamountを加算
                personalList[0].amount += sale.amount;
                personalList[0].latest = sale.created_at;
              } else if (personalList.indexOf(sale) === -1) {
                personalList.push(registData);
              }
            }
          });

          personalList.forEach((saleData) => { // 同じアカウントで別名で複数回支援があったとき
            this.personalSponsors.push({
              name: saleData.name,
              amount: saleData.amount,
              latest: saleData.latest,
            });
          });
        } else { // 一つのアカウトで一回の支援ししてくれた人をpersonalSponsorsに追加
          let userDisplayname = null;
          user.sales.forEach((sale) => {
            if (sale.created_at === latestDate) {
              if (sale.displayname) userDisplayname = sale.displayname.displayname;
              else userDisplayname = user.displayname;
            }
          });
          if (userDisplayname) {
            this.personalSponsors.push({
              name: userDisplayname,
              amount: userAmount,
              latest: latestDate,
            });
          }
        }
      });

      // 手動ロゴの追加
      if (plogos) {
        const logoTypes = Object.keys(plogos.logos);
        logoTypes.forEach((type) => {
          // 配列のマージ
          if (plogos.logos[type] && plogos.logos[type].length) {
            this.corporateSponsors[type] = this.corporateSponsors[type].concat(plogos.logos[type]);
          }
        });
      }

      // 金額(同じだった場合最新支援日時)で並べ替え
      this.corporateSponsors.logo_LL.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest < b.latest) return -1;
        return 1;
      });
      this.corporateSponsors.logo_L.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest < b.latest) return -1;
        return 1;
      });
      this.corporateSponsors.logo_M.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest < b.latest) return -1;
        return 1;
      });
      this.corporateSponsors.logo_S.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest < b.latest) return -1;
        return 1;
      });
      this.corporateSponsorsText.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest < b.latest) return -1;
        return 1;
      });
      this.personalSponsors.sort((a, b) => {
        if (a.amount > b.amount) return -1;
        if (a.amount < b.amount) return 1;
        if (a.latest < b.latest) return -1;
        return 1;
      });
      //匿名アカウントの手動追加
      if (plogos) {
        const sponsors = plogos.personalsponsors;
        if (sponsors && sponsors.length) {
          sponsors.forEach((sponsor) => {
            this.personalSponsors.push({
              name: sponsor.name,
              amount: sponsor.amount,
              latest: sponsor.latest,
            });
          });
        }
      }
    },
  },
};
</script>

<style module>
.content {
  padding: 1px;
}
.headline {
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
  font-weight: bold;
  font-size: 1.2em;
}
</style>
