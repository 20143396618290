<template>
  <div :class="boxClassName">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RoundedBox',
  props: ['gutter', 'has-shadow', 'radius'],
  data() {
    return {};
  },
  computed: {
    boxClassName() {
      const names = [this.$style.box];
      if (this.hasShadow) names.push(this.$style.hasShadow);
      if (this.gutter) names.push(this.$style[`gutter--${this.gutter}`]);
      if (this.radius) names.push(this.$style[`radius--${this.radius}`]);
      return names;
    },
  },
};
</script>

<style module>
.box {
  display: block;
  background-color: #fff;
  overflow: hidden;
}

.gutter--sm {
  padding: 16px;
}

.gutter--md {
  padding: 32px;
}

.gutter--lg {
  padding: 40px;
}

.radius--sm {
  border-radius: 8px;
}

.radius--md {
  border-radius: 17px;
}

.radius--lg {
  border-radius: 24px;
}

.hasShadow {
  box-shadow: 0 0px 20px rgba(0, 0, 0, 0.12);
}

@media screen and (max-width: 1023px) {
  .gutter--sm {
    padding: 12px;
  }

  .gutter--md {
    padding: 24px;
  }

  .gutter--lg {
    padding: 32px;
  }
}

@media screen and (max-width: 767px) {
  .gutter--sm,
  .gutter--md {
    padding: 16px;
  }

  .gutter--lg {
    padding: 20px;
  }

  .radius--sm {
    border-radius: 4px;
  }

  .radius--md {
    border-radius: 4px;
  }

  .radius--lg {
    border-radius: 4px;
  }
}
</style>
