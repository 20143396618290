const actions = {
  moveToNextStep({ commit, state }) {
    commit('updateStep', state.step + 1);
  },
  moveToPrevStep({ commit, state }) {
    commit('updateStep', state.step - 1);
  },
  moveToStep({ commit }, args) {
    commit('updateStep', args);
  },
};

const mutations = {
  updateStep(state, args) {
    state.step = args;
  },
};

const state = {
  step: 1,
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
