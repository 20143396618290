<template>
  <div class="supportType">
    <div :class="$style.supportTypes">
      <dl class="text-center">
        <dt>ログイン/新規登録して支援</dt>
        <dd>
          <p>登録して返礼品を選ぶ</p>
          <div class="mt-4">
            <button @click="handleClick('login')" class="btn btn__primary">ログイン</button>
            <p class="form-assistance">すでにアカウントをお持ちの方はログインしてください。</p>
          </div>
          <div class="mt-4">
            <button @click="handleClick('signup')" class="btn btn__primary">新規登録</button>
            <p class="form-assistance">新たにアカウントを作成する場合は新規登録を行ってください。</p>
          </div>
        </dd>
      </dl>

      <dl class="text-center">
        <dt>ゲストとして支援</dt>
        <dd>
          <p>登録せずに返礼品を選ぶ</p>
          <div class="mt-4">
            <button @click="handleClick('payment')" class="btn btn__primary">ゲストとして支援する</button>
            <p class="form-assistance">アカウントを作成せずに支援できます。</p>
          </div>
        </dd>
      </dl>

      <dl
        v-if="data.anonymousBank"
        class="text-center">
        <dt>匿名で支援</dt>
        <dd>
          <p>銀行振込で支援する</p>
          <div class="box-notice">
            <p>銀行名：{{ anonymousbankData.bankAccount }}</p>
            <p>支店名：{{ anonymousbankData.branch }}</p>
            <p>口座種別：{{ anonymousbankData.kinds }}</p>
            <p>口座番号：{{ anonymousbankData.number }}</p>
            <p>口座名義：{{ anonymousbankData.nominee }}</p>
          </div>
        </dd>
      </dl>


      <div class="mt-4">
        <ul class="btn-lists horizontal center">
          <li><button
            @click="handleClick('close')"
            class="btn btn__tertiary">閉じる</button></li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import cf from '@/mixins/commonFunctions';

export default {
  name: 'modalMessagesSupportType',
  mixins: [cf],
  props: ['data'],
  data() {
    return {
      anonymousbankData: this.data.anonymousBank,
    };
  },
  created() {
  },
  methods: {
    handleClick(to) {
      // モーダル閉じ
      this.$store.dispatch('modal/messages/hideModal', null, { root: true });
      if (to === 'close') return;

      // リンク先
      const toPayment = `/payment/info/${this.data.slug.project}/${this.data.slug.return}/`;
      // ゲストの場合支払いページへ、ログイン・登録はそれぞれ
      const path = to === 'payment' ? toPayment : `/${to}/`;
      const ref = { path: toPayment };
      // ログイン後に支援ページへ遷移させるためpathとqueryを保持しておく
      cf.saveLocalStorage({
        loginRef: JSON.stringify(ref),
        supportType: to === 'payment' ? 'guest' : 'login',
      }, 'fk');

      this.$router.push({
        path,
      });
    },
  },
};
</script>


<style module lang="scss">
.supportTypes {
  > * {
    &:not(:first-child) {
      margin-top: 8rem;
    }
    dt {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1em;
    }
  }

  button {
    margin-top: 1rem;
  }
}
</style>
