<template>
  <div class="step3">
    <div class="payment-header">
      <p v-if="user.cards.cards.length">クレジットカードの情報を確認してください</p>
      <p v-if="!user.cards.cards.length">クレジットカードの情報を入力してください</p>
    </div>

    <div class="backgroundcolor">
      <form
        id="payment-form"
        class="form vertical payment mt-5">
        <div class="form-contents">

          <dl class="form-group">
            <dt class="label">
              <label class="form-label">支払い方法</label>
            </dt>
            <dd class="detail">
              <div v-if="user.cards.cards.length && !user.cards.temporary.id">
                <div :class="$style.flex">
                  <div class="mt-4" :class="$style.cardWrap">
                    <div :class="$style.card">
                      <div
                        :style="`background-image: url(/img/cards/${cards[defaultCard.brand]}.png);`"
                        :class="$style.icon"></div>
                    </div>
                    <label for="default-card">**** **** **** {{ defaultCard.last4 }}（デフォルト）</label>
                  </div>
                  <div :class="$style.btn" v-on:click="selectCard">変更する</div>
                </div>
              </div>
              <div v-if="user.cards.cards.length && user.cards.temporary.id">
                <div :class="$style.flex">
                  <div class="mt-4" :class="$style.cardWrap">
                    <div :class="$style.card">
                      <div
                        :style="`background-image: url(/img/cards/${cards[user.cards.temporary.brand]}.png);`"
                        :class="$style.icon"></div>
                    </div>
                    <label for="default-card">**** **** **** {{ user.cards.temporary.last4 }}</label>
                  </div>
                  <div :class="$style.btn" v-on:click="selectCard">変更する</div>
                </div>
              </div>
              <div class="mt-3" v-if="!user.cards.cards.length">
                <div v-if="paymentType === 'regist'">
                  <p class="form-assistance">ファンディング桐生ではカード情報を保持しません。<br>カード情報は暗号化し決済サービスのStripeへ送信されます。</p>
                  <CardRegist
                    @sendRegistFlag="receiveRegistFlag"
                    ref="cardRegist" />
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </form>
    </div>


    <div
      id="card-errors"
      v-if="flag.cardError"
      v-html="message.cardError"></div>

    <div :class="$style.btnsWrap">
      <ul class="btn-lists horizontal center flex-row-reverse">
        <li v-if="user.cards.cards.length">
          <button
            class="btn btn__primary"
            @click="goNext">次へ</button>
        </li>
        <li v-if="!user.cards.cards.length">
          <button
            class="btn btn__primary"
            :disabled="paymentType === 'regist' && !flag.regist"
            @click="regist">カード登録</button>
        </li>
        <li>
          <router-link
            class="btn btn__tertiary"
            :to="`/payment/method/${slugs.project}/${slugs.return}/`">戻る</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CardRegist } from '@/components/parts/Form';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'PaymentStep3',
  mixins: [cf],
  props: ['project', 'returnItem', 'slugs'],
  components: {
    CardRegist,
  },
  data() {
    return {
      customer: null,
      paymentType: 'regist',
      defaultCard: {},
      flag: {
        regist: false,
        cardError: false,
      },
      message: {
        cardError: '',
      },
      cards: {
        Visa: 'visa',
        MasterCard: 'mastercard',
        'American Express': 'amex',
        JCB: 'jcb',
        'Diners Club': 'diners-club',
        Discover: 'discover',
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    const ls = cf.getLocalStorage('fk');
    if (!ls || !ls.lot) { this.$emit('invalid', true); return; }

    this.moveToStep(3);
    if (this.user.email) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') this.initialize();
      });
    }
    if (this.user.cards.flag) {
      this.setInitialValue();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/cards/putAllData') this.setInitialValue();
      });
    }
  },
  methods: {
    ...mapActions('payment', [
      'moveToStep',
    ]),
    initialize() {
      this.getCustomer();
    },

    setInitialValue() {
      if (this.user.cards.cards.length) {
        this.user.cards.cards.forEach((card) => {
          if (this.user.cards.default === card.id) {
            this.defaultCard = card;
          }
        });
      }
    },

    /** DBに保存されているstripe_customer情報を取得 */
    getCustomer() {
      this.axios({
        method: 'GET',
        url: '/v1/stripe/customer/get/byUser',
        params: {
          user_id: this.user.id,
        },
      })
        .then((response) => {
          const res = response.data;
          // stripeの顧客情報・intent登録済み
          if (res.customer) {
            this.customer = res.customer;

            // TODO: カードを取得して支払いカードを選択または登録

          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          // ローダー非表示
          this.$emit('loader', false);
        });
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 登録フラグの受け取り */
    receiveRegistFlag(data) {
      this.flag.regist = data.completed;
    },

    selectCard() {
      const args = {
        modalName: 'selectCard',
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    goNext() {
      this.$router.push({
        path: `/payment/confirm/${this.slugs.project}/${this.slugs.return}/`,
        query: { paymentType: 1 },
      });
    },

    /** 登録 */
    async regist() {
      // 実行前の確認
      if (!confirm('お支払い情報を登録します。よろしいですか？')) return;

      this.showLoading();

      /**
       * 顧客およびカード情報の登録・更新
       * customer未登録の場合は登録・存在する場合は更新
       */
      const isRegist = !this.customer;
      const endpoint = isRegist ? 'register' : 'updater';
      let data = {};
      const customerId = this.customer
        ? this.customer.customer_id
        : null;

      try {
        if (this.paymentType === 'regist') {
          // クレジットカードのトークン取得
          const card = await this.$refs.cardRegist.getToken();
          // エラー文言の初期化
          this.flag.cardError = false;
          this.message.cardError = '';
          if (card.error) {
            // エラー表示
            this.flag.cardError = true;
            this.message.cardError = card.error.message;
            this.hideLoading();
            return;
          }

          // 登録時には追加の情報を登録
          if (isRegist) {
            data = {
              customer_id: customerId,
              customerData: {
                email: this.user.email,
                name: this.user.username,
                source: card.token.id,
                metadata: { user_id: this.user.id },
                // 領収書の日本語化
                preferred_locales: ['ja'],
              },
            };
          } else {
            data = {
              customer_id: customerId,
              source: card.token.id,
              isCardRegist: true,
            };
          }
        } else {
          data = {
            customer_id: customerId,
            card_id: this.paymentType,
            isCardRegist: false,
          };
        }

        this.axios({
          method: 'POST',
          url: `/v1/stripe/customer/set/${endpoint}`,
          data,
        })
          .then(() => {
            // storeユーザー情報更新
            this.$store.dispatch('user/update', null, { root: true });
            alert('お支払い情報の登録が完了しました。');
            this.$router.push({
              path: `/payment/confirm/${this.slugs.project}/${this.slugs.return}/`,
              query: { paymentType: 1 },
            });
          })
          .catch((error) => {
            if (error.response) {
              const res = error.response.data;
              console.log(res);
              if (res.alert_message) alert(res.alert_message);
            } else {
              console.log(error);
              alert('お支払い情報の登録に失敗しました。\nお手数ですが管理者までお問い合わせください');
            }
          })
          .finally(() => {
            this.hideLoading();
            this.$store.dispatch('user/update', null, { root: true });
          });
      } catch (error) {
        if (error.response) console.log(error.response.data);
        else console.log(error);
        alert('お支払い情報の登録に失敗しました。\nお手数ですが管理者までお問い合わせください');
        this.hideLoading();
      }
    },
  },
};
</script>


<style module lang="scss">
.btnsWrap {
  margin-top: 5rem;
}

.list {
  margin: .3em 0;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.cardWrap {
  display: flex;
  align-items: center;
}

.card {
  width: 2.4em;
  border-radius: 2px;
  overflow: hidden;
  margin: auto 5px auto 0;
}

.icon {
  padding-top: percentage(142/220);
  background-size: cover;
}

.btn {
  cursor: pointer;
  margin-top: 1em;
  color: #777;
  text-decoration: underline #777;

  &:hover {
    color: #aaa;
    text-decoration: underline #aaa;
  }
}
</style>
