<template>
  <div>
    <div class="refund-top">
      <p>返金するプロジェクトを選択してください</p>
      <ul
        v-if="projects"
        class="list-group">
        <li
          v-for="project in projects"
          :key="project"
          class="card mt-2">
          <router-link :to="`/admin/refund/${project.slug}`" class="text-body">
            <div class="card-hader fw-bold">{{ project.title }}</div>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'adomin-refund',
  data() {
    return {
      flag: {},
      projects: null,
    };
  },
  created() {
    if (this.helper.master.projects) {
      this.projects = [...this.helper.master.projects];
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.projects = [...this.helper.master.projects];
        }
      });
    }
  },
  computed: {
    ...mapState(['helper']),
  },
  methods: {},
};
</script>

<style>
</style>
