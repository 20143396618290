<template>
  <div class="regist-card">
    <div>新規クレジットカード登録</div>
    <!-- <pre>{{ user }}</pre> -->
    <p class="form-assistance">ファンディング桐生ではカード情報を保持しません。<br>カード情報は暗号化し決済サービスのStripeへ送信されます。</p>
    <CardRegist
      @sendRegistFlag="receiveRegistFlag"
      ref="cardRegist" />
    <div>
      <button
        type="button"
        class="btn btn__primary mt-5"
        :disabled="!flag.regist"
        v-on:click="regist">登録</button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { CardRegist } from '@/components/parts/Form';

export default {
  name: 'regist-card',
  props: ['data'],
  components: {
    CardRegist,
  },
  data() {
    return {
      flag: {
        regist: false,
        cardError: false,
      },
      message: {
        cardError: '',
      },
    };
  },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 登録フラグの受け取り */
    receiveRegistFlag(data) {
      this.flag.regist = data.completed;
    },

    async regist() {
      this.showLoading();
      const isRegist = !this.user.customer;
      const endpoint = isRegist ? 'register' : 'updater';
      let data = {};
      const customerId = this.user.customer ? this.user.customer.customer_id : null;

      try {
        // クレジットカードのトークン取得
        const card = await this.$refs.cardRegist.getToken();
        // エラー文言の初期化
        this.flag.cardError = false;
        this.message.cardError = '';
        if (card.error) {
          // エラー表示
          this.flag.cardError = true;
          this.message.cardError = card.error.message;
          this.hideLoading();
          return;
        }
        
        if (isRegist) {
          data = {
            customer_id: customerId,
            customerData: {
              email: this.user.email,
              name: this.user.username,
              source: card.token.id,
              metadata: { user_id: this.user.id },
              // 領収書の日本語化
              preferred_locales: ['ja'],
            },
          };
        } else {
          data = {
            customer_id: customerId,
            source: card.token.id,
            isCardRegist: true,
          };
        }

        this.axios({
          method: 'POST',
          url: `/v1/stripe/customer/set/${endpoint}`,
          data,
        })
          .then((response) => {
            const res = response.data;
            // storeユーザー情報更新
            this.$store.dispatch('user/update', null, { root: true });
            const sendData = {
              customer: {},
            };
            sendData.customer.customer_id = isRegist ? res.customer.id : customerId;
            this.$store.dispatch('user/cards/getCustomer', sendData, { root: true });
            alert('クレジットカードの登録が完了しました。');
            const args = {
              modalName: 'paymentSubscription',
            };
            this.$store.dispatch('modal/contents/showModal', args, { root: true });
          })
          .catch((error) => {
            if (error.response) console.log(error.response.data);
            else console.log(error);
          })
          .finally(() => {
            this.hideLoading();
          });

      } catch (error) {
        if (error.response) console.log(error.response.data);
        else console.log(error);
        alert('クレジットカード情報の登録に失敗しました。\nお手数ですが管理者までお問い合わせください');
        this.hideLoading();

      }
    },
  },
};
</script>
