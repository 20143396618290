<template>
  <header :class="[$style.header, headerTranparentClass]">
    <div :class="$style.contents">
      <router-link to="/" :class="$style.logo" @click="closeMenu">
        <logo-svg :color="logoColor"/>
      </router-link>
      <div v-if="false" :class="$style.header__disc">
        <router-link to="/about/" :class="$style.header__link">
          ファンディング桐生とは？
        </router-link>
        <button @click="logout" v-if="user.login.isLogin" :class="$style.header__link">
          ログアウト
        </button>
        <router-link to="/login/" :class="$style.header__btn">
          ログイン・新規登録
        </router-link>
      </div>
    </div>
    <hamburger-menu />
  </header>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LogoSvg from '@/components/parts/common/LogoSvg.vue';
import HamburgerMenu from './HamburgerMenu.vue';

export default {
  name: 'GlobalHeader',
  components: {
    HamburgerMenu,
    LogoSvg,
  },
  data() {
    return {
      spMenu: false,
    };
  },
  created() {
  },
  computed: {
    ...mapState(['page', 'user', 'layout']),
    logoColor() {
      return this.layout.header.isTransparent && !this.layout.menu.isOpen ? '#fff' : '#000';
    },
    headerTranparentClass() {
      return this.layout.header.isTransparent && !this.layout.menu.isOpen ? this.$style.isTransparent : '';
    },
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
      'layout',
    ]),
    ...mapActions('layout/menu', [
      'closeMenu',
    ]),
  },
};
</script>

<style module lang="scss">
@import '../../assets/scss/mixins';
@import '../../assets/scss/variables';

.header {
  position: fixed;
  z-index: 9;
  left: 0;
  right: 0;
  display: flex;
  height: var(--header-height);
  background-color: #fff;
  transform: translate3d(0, 0, 0);
  transition: background-color var(--header-transition-duration) ease-in-out;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
}

.contents {
  z-index: 93;
  display: flex;
  justify-content: space-between;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 20px;
  height: 100%;
}

.logo {
  display: flex;
  align-items: center;
  width: 265px;
}

.header__disc {
  display: flex;
  margin-left: auto;
  transition: 0.3s;
}

.header__link {
  margin: auto 24px;
  color: $primary-label;
  font-size: 1.6rem;
  transition: 0.3s;
  &:hover {
    text-decoration: underline;
  }
}

.header__btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 191px;
  height: 100%;
  background-color: $primary-surface;
  color: $primary-label;
  font-weight: bold;
  font-size: 1.6rem;
  transition: 0.3s;
  &:hover {
    color: white;
    background-color: #1DA6F2;
  }
}
.header.isTransparent {
  background-color: transparent;
  box-shadow: none;
}

@media (max-width: 768px) {
  .contents {
    max-width: 100%;
    padding: 0 0 0 20px;
  }

  .logo {
    z-index: 200;
  }

  .header__disc {
    display: none;
  }
}

@media (max-width: 576px) {
  .logo {
    width: percentage(208/375);
  }
}
</style>
