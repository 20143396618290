<template>
  <div id="signup" class="signup accounts">
    <div class="container-sm">
      <h2 class="title-page lg">会員登録</h2>

      <form
        class="form vertical"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label
                for="mail"
                class="form-label">メールアドレス</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="mail"
                  class=""
                  type="email"
                  name="mail"
                  v-model.trim="$v.mail.$model"
                  v-bind:class="{ input_error: validationFlags.mail }"
                  v-bind:disabled="flg.regist"
                  v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
                  >
              </div>
              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.required.$invalid">メールアドレスを入力してください</p>
              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
            </dd>
          </dl>

          <div class="form-group btns mt-sm">
            <ul class="button_list">
              <li>
                <button
                  class="btn btn__primary sm"
                  type="submit"
                  v-bind:disabled="!submitFlag"
                  v-on:click="submit($event, false)"
                  v-if="!flg.exists && !flg.regist"
                  >メールアドレス登録</button>
              </li>

              <li>
                <button
                  class="btn btn__primary sm"
                  type="submit"
                  v-on:click="submit($event, true)"
                  v-if="flg.exists && flg.regist"
                  >本登録に進む</button>
              </li>
            </ul>

            <div class="messages mt-4">
              <p
                v-if="!flg.exists
                  && !flg.regist
                  && !flg.error"
                >登録済みの方は<a href="/login/" class="link">こちら</a>からログインしてください。</p>

              <p
                v-if="!flg.exists && flg.regist"
                >ご登録のメールアドレスに登録完了メールを送信しました。<br>メールに記載されたURLからパスワードを設定してください。</p>

              <p
                v-if="flg.exists && !flg.regist"
                >送信いただいたメールアドレスは登録済みです。<a href="/login/" class="link">ログインページ</a>よりログインしてください。<br>パスワード設定がまだの場合は、メールアドレス登録時に送信されたメールに記載のURLよりパスワード設定を行ってください。</p>

              <p
                v-if="flg.exists && flg.regist"
                >このメールアドレスはゲストで登録済みです。</p>
              </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'signup',
  mixins: [cf],
  data() {
    return {
      flg: {
        exists: false,
        regist: false,
        error: false,
      },
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
    };
  },
  created() {
  },
  // https://qiita.com/tsuji_masayuki/items/2250f87b391a0bd374cc
  setup() {
    const mail = ref('');

    const rules = {
      // 必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });
    return { mail, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      return !this.invalids.mail && !this.flg.regist && !this.flg.exists;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e, buttonStatus) {
      e.preventDefault();
      this.flg.error = false;

      this.showLoading();

      const data = {
        flag: 100,
        email: this.mail,
      };

      if (!buttonStatus) {
        this.axios({
          method: 'POST',
          url: '/v1/user/register',
          data,
        })
          .then((response) => {
            const res = response.data;
            console.log(res);

            // 初期化
            this.flg.regist = false;
            this.flg.exists = false;

            if (res.userFlag === 100) {
              // 初期登録
              this.flg.exists = false;
              this.flg.regist = true;
            } else if (res.userFlag === 200) {
              // 仮登録
              this.flg.exists = true;
              this.flg.regist = true;
            } else if (res.userFlag === 1) {
              // 登録済み
              this.flg.exists = true;
              this.flg.regist = false;
            } else {
              console.log('users flag is invalid');
            }
          })
          .catch((error) => {
            if (error.response) console.log(error.response.data);
            else console.log(error);
            this.flg.error = true;
          })
          .finally(() => {
            this.hideLoading();
          });
      } else if (buttonStatus) {
        this.axios({
          method: 'POST',
          url: '/v1/user/reRegister',
          data,
        })
          .then((response) => {
            const res = response.data;
            console.log(res);

            // 初期化
            this.flg.regist = true;
            this.flg.exists = true;

            if (res.userFlag === 100) {
              // 本登録ボタンが押された場合
              this.flg.exists = false;
              this.flg.regist = true;
            } else {
              console.log('users flag is invalid');
            }
          })
          .catch((error) => {
            if (error.response) console.log(error.response.data);
            else console.log(error);
            this.flg.error = true;
          })
          .finally(() => {
            this.hideLoading();
          });
      }
    },
  },
};
</script>
