
import axios from '@/plugins/axios';
// import cf from '@/mixins/commonFunctions';


const actions = {
  getSubscriptions({ dispatch }, data) {
    const params = {
      customer_id: data.customer.customer_id,
    };

    axios({
      method: 'GET',
      url: '/v1/stripe/subscription/get/list',
      params,
    })
      .then((response) => {
        const res = response.data;
        dispatch('getSubscriptionIds', res.subscriptions);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  getSubscriptionIds({ commit, dispatch }, data) {
    const subscriptionIds = [];
    data.forEach((subscription) => {
      subscriptionIds.push(subscription.subscription_id);
    });
    commit('setSubscriptionIds', subscriptionIds);
    if (subscriptionIds.length) dispatch('getSubscriptionDetails', subscriptionIds);
    else commit('resetData');
  },

  getSubscriptionDetails({ dispatch }, data) {
    const params = {
      subscriptionIds: data,
    };
    
    axios({
      method: 'GET',
      url: '/v1/stripe/subscription/get/detailLists',
      params,
    })
      .then((response) => {
        const res = response.data;
        dispatch('getPlanIds', res.subscriptions);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },

  getPlanIds({ commit }, data) {
    const planIds = [];
    data.forEach((subscription) => {
      planIds.push(subscription.plan.id);
    });
    commit('setPlanIds', planIds);
  },

  resetAll({ commit }) {
    commit('resetData');
    commit('resetFlag');
  },
};

const mutations = {
  setSubscriptionIds(state, data) {
    state.subscriptionIds = data;
    state.flag.subscription = true;
  },

  setPlanIds(state, data) {
    state.planIds = data;
    state.flag.plan = true;
  },

  resetData(state) {
    state.subscriptionIds = [];
    state.planIds = [];
  },

  resetFlag(state) {
    state.flag.subscription = false;
    state.flag.plan = false;
  },
};

const state = {
  subscriptionIds: [],
  planIds: [],
  flag: {
    subscription: false,
    plan: false,
  },
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
