<template>
  <div
    class="wrapper"
    v-bind:class="wrapperClass">
    <GlobalHeader />
    <main id="main" class="main-contents">
      <router-view/>
    </main>
    <GlobalFooter />
    <ContentsModal />
    <LoadingsModal />
    <MessagesModal />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { GlobalHeader, GlobalFooter } from '@/components/layouts';
import { ContentsModal, LoadingsModal, MessagesModal } from '@/components/parts/Modal';

export default {
  name: 'App',
  components: {
    GlobalHeader,
    GlobalFooter,
    ContentsModal,
    LoadingsModal,
    MessagesModal,
  },
  created() {
    // ページ読み込み時のログインチェック
    this.$store.dispatch('user/login/check');
    // backend共通マスターデータ取得
    this.$store.dispatch('helper/getMaster');
  },
  mounted() {
    this.setMetas(this.$route, this.$store);
  },
  watch: {
    $route(to) {
      // メタ情報の仕込み
      this.setMetas(to, this.$store);
    },
  },
  computed: {
    ...mapState([
      'user',
      'page',
    ]),
    hiddenHeader() {
      const pageName = this.$router.currentRoute.value.name;
      return pageName === 'Login'
        || pageName === 'Signup'
        || pageName === 'ForgotPassword'
        || pageName === 'ResetPassword';
    },
    isModal() {
      const contents = this.$store.state.modal.contents.modalName !== '';
      const loadings = this.$store.state.modal.loadings.modalName !== '';
      const messages = this.$store.state.modal.messages.modalName !== '';
      return contents || loadings || messages;
    },
    wrapperClass() {
      let classStr = '';
      // ページ情報
      classStr = this.$store.state.page.name
        ? `page-name-${this.$store.state.page.name.toLowerCase()}`
        : classStr;
      // ログイン状態
      const login = this.$store.state.user.login.isLogin;
      classStr += login ? ' isLogin' : ' nonLogin';
      // 言語
      const language = this.$store.state.user.language;
      classStr += ` lang-${language}`;
      return classStr;
    },
  },
  methods: {
    setMetas: (route, store) => {
      const meta = {};
      // 個別設定がない場合のデフォルト設定
      const defaults = {
        title: 'ファンディング桐生',
        description: 'ファンディング桐生は地域密着型クラウドファンディングです',
        og_image: 'https://kiryu.fund/img/og.png',
      };

      meta.name = route.name;
      meta.title = route.meta.title ? `${route.meta.title} | ${defaults.title}` : defaults.title;
      meta.description = route.meta.description || defaults.description;
      meta.og_image = route.meta.og_image || defaults.og_image;
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}`;
      meta.type = route.name === 'Home' ? 'website' : 'article';

      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:type']").setAttribute('content', meta.type);
      document.querySelector("meta[property='og:image']").setAttribute('content', meta.og_image);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // store.pageに保持させる
      store.dispatch('page/setPageInfo', meta);

      // canonicalタグの動的削除
      const canonical = document.querySelector("link[rel='canonical']");
      // 存在する場合はリセットのため削除
      if (canonical) canonical.remove();
    },
  },
};
</script>

<style lang="scss">
/**
 * ここでimportしているscssは
 * 全てのページに適用されます
 */
@import './assets/scss/_variables.scss';
@import './assets/scss/_transition.scss';
@import './assets/scss/_keyframes.scss';
@import './assets/scss/_mixins.scss';
@import './assets/scss/_reset.scss';
@import './assets/scss/_fonts.scss';
@import '../node_modules/loaders.css/loaders.min.css';
@import './assets/scss/style.scss';
</style>
