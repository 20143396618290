<template>
  <div class="ball-scale-ripple">
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'modalLoadingBallScaleRipple',
};
</script>
