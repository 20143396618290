<template>
  <div :class="$style.wrapper">
    <contents-container>
      <hero-slider :images="project.slider" />
      <div :class="$style.project">
        <div :class="$style.header">
          <h1 :class="$style.title">{{project.title}}</h1>
          <!-- <p :class="$style.description">{{project.description}}</p> -->
          <p :class="$style.description" v-html="project.description"></p>
          <div v-if="project.ImgUrlLink">
            <a
              :href="project.ImgUrlLink"
              target="_blank">
              <img :class="$style.honorImg" :src="project.honorImgUrl" alt="">
            </a>
          </div>
          <div v-else>
            <img :class="$style.honorImg" :src="project.honorImgUrl" alt="">
          </div>
<!--
          <ul :class="$style.socialList">
            <li :class="$style.socialList__item">
              <social-icon href="#" type="fb" />
            </li>
            <li :class="$style.socialList__item">
              <social-icon href="#" type="tw" />
            </li>
            <li :class="$style.socialList__item">
              <social-icon href="#" type="ig" />
            </li>
          </ul>
-->
        </div>
        <div :class="$style.progress">
          <rounded-box :gutter="'lg'" :radius="'lg'">
            <div :class="$style.progress__inner">
              <dl :class="$style.total">
                <dt :class="$style.label">支援総額</dt>
                <dd
                  :class="`${$style.value} ${$style.lg}`"
                  v-if="salesTotal">
                  {{ addComma(salesTotal.amount) }}<span :class="$style.valueUnit">円</span>
                </dd>
                <p :class="$style.target">目標金額 : {{ addComma(project.target) }}円</p>
              </dl>
              <div
                :class="$style.progress__circle"
                v-if="salesTotal">
                <progress-circle :progress="getPercentage(salesTotal.amount, project.target)"/>
              </div>
            </div>
            <div :class="$style.progress__inner">
              <dl :class="$style.patron">
                <dt :class="$style.label">支援者</dt>
                <dd
                  :class="$style.value"
                  v-if="salesTotal">
                  {{ addComma(salesTotal.lot) }}人
                </dd>
              </dl>
              <dl :class="$style.remainingDays">
                <div v-if="getRemainingToday(project.period) > 0">
                  <dt :class="$style.label">募集終了まで</dt>
                  <dd :class="$style.value">
                    {{getRemainingToday(project.period)}}<span :class="$style.valueUnit">日</span>
                  </dd>
                </div>
                <div v-else-if="getRemainingToday(project.period) === 0">
                  <dd :class="$style.value">終了</dd>
                </div>
              </dl>
            </div>
            <button v-scroll-to="{
              el: '#returnList',
              offset: -100,
            }" :class="$style.button">支援する</button>
          </rounded-box>
        </div>
      </div>
    </contents-container>
  </div>
</template>
<script>
import { ContentsContainer, RoundedBox } from '@/components/parts/Container';
// import { SocialIcon } from '@/components/parts/icon';
import { ProgressCircle } from '@/components/parts/Project';
import cf from '@/mixins/commonFunctions';
import HeroSlider from './HeroSlider.vue';

export default {
  name: 'FirstView',
  mixins: [cf],
  props: ['project'],
  components: {
    ContentsContainer,
    RoundedBox,
    // SocialIcon,
    ProgressCircle,
    HeroSlider,
  },
  salesTotal: {}, // 初期実装時
  data() {
    return {
      salesTotal: null,
    };
  },
  created() {
    this.getProjectSalesData();
  },
  methods: {
    // 初期実装時: プロジェクトの購入データ
    getProjectSalesData() {
      this.axios({
        method: 'GET',
        url: '/v1/sales/get/sum',
        params: {
          type: 'project',
          id: this.project.id,
        },
      })
        .then((response) => {
          const data = response.data;
          this.salesTotal = data.result;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },
  },
};
</script>

<style module>
.wrapper {
  background-color: #e5e5e5;
  padding-bottom: 80px;
}

.project {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 32px;
}

.header {
  flex-basis: 474px;
  flex-shrink: 3;
  flex-grow: 1;
}

.title {
  font-size: 40px;
  line-height: 1.6;
  font-weight: 700;
}

.description {
  margin-top: 24px;
  font-size: 20px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.64);
}

.honorImg {
  margin-top: 2em;
  max-height: 10em;
  max-width: 100%;
}

.socialList {
  display: flex;
  margin-top: 32px;
}

.socialList__item + .socialList__item {
  margin-left: 32px;
}

.progress {
  flex-basis: 474px;
  margin-left: calc(min(40px, 10px + 3vw));
  flex-shrink: 2;
  flex-grow: 1;
}

.progress__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progress__inner + .progress__inner {
  margin-top: 24px;
}

.progress__circle {
  width: 100%;
  max-width: 160px;
  margin-left: 16px;
}

.target {
  margin-top: 12px;
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.64);
}

.label {
  display: block;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.64);
}

.value {
  display: flex;
  align-items: baseline;
  margin-top: 12px;
  font-size: clamp(24px, calc(4px + 3.5vw), 40px);
  line-height: 1;
  letter-spacing: -0.5px;
  font-weight: bold;
  white-space: pre-line;
}

.value.lg {
  font-size: clamp(32px, calc(4px + 3.5vw), 48px);
}

.valueUnit {
  margin-left: 8px;
  font-size: 24px;
  line-height: 1;
}

.patron {
  width: 50%;
}

.remainingDays {
  width: 50%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  margin-top: 32px;
  border: none;
  border-radius: 32px;
  background-color: #000;
  color: #fff;
  appearance: none;
  box-shadow: none;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 1px;
}

@media screen and (max-width: 1023px) {
  .title {
    font-size: 30px;
  }

  .description {
    margin-top: 20px;
    font-size: 16px;
  }

  .progress__inner + .progress__inner {
    margin-top: 32px;
  }

  .target {
    font-size: 12px;
  }

  .button {
    height: 48px;
    font-size: 18px;
  }
}

@media screen and (max-width: 767px) {
  .wrapper {
    padding-bottom: 24px;
  }

  .project {
    display: block;
  }

  .header {
    width: 100%;
  }

  .socialList {
    margin-top: 24px;
  }

  .socialList__item + .socialList__item {
    margin-left: 16px;
  }

  .progress {
    width: 100%;
    max-width: 474px;
    margin: 24px auto 0;
  }

  .progress__circle {
    max-width: 88px;
  }
}
</style>
