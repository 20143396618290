<template>
  <div class="editPayment edit">
    <div id="edit-top" class="container">
      <h1 class="title-page mb-4">お支払い方法編集</h1>
      <form class="form">

        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label class="form-label">登録カード一覧</label>
            </dt>
            <dd class="detail">
              <div
                class="form-check mt-4"
                v-for="(card, i) in user.cards.cards"
                v-bind:key="card">
                <input
                  class="form-check-input"
                  type="radio"
                  :id="`default-card-${i}`"
                  name="payment-type"
                  :value="card.id"
                  v-model="paymentType">
                <div :class="$style.card">
                  <div
                    :style="`background-image: url(/img/cards/${cards[card.brand]}.png);`"
                    :class="$style.icon"></div>
                </div>
                <label :for="`default-card-${i}`">**** **** **** {{ card.last4 }}<span v-if="user.cards.default === card.id">（デフォルト）</span></label>
              </div>
            </dd>
          </dl>
        </div>

        <div class="form-contents">
          <div class="form-group mt-sm">
            <ul class="btn-lists horizontal">
              <li>
                <button
                  type="button"
                  class="btn btn__primary sm"
                  id="btn_check"
                  v-on:click="registCard"
                  >新規カード登録</button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn btn__primary sm"
                  :disabled="user.cards.default === paymentType"
                  v-on:click="deleteCard">カード削除</button>
              </li>
              <li>
                <button
                  type="button"
                  class="btn btn__primary sm"
                  id="btn_submit"
                  :disabled="user.cards.default === paymentType"
                  v-on:click="changeDefault">デフォルトのカードに設定</button>
              </li>
            </ul>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-profile',
  mixins: [cf],
  data() {
    return {
      paymentType: null,
      cards: {
        Visa: 'visa',
        MasterCard: 'mastercard',
        'American Express': 'amex',
        JCB: 'jcb',
        'Diners Club': 'diners-club',
        Discover: 'discover',
      },
    };
  },
  created() {
    if (this.user.cards.flag) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/cards/putAllData') this.initialize();
      });
    }
  },
  computed: {
    ...mapState(['user']),
    updateCard() {
      return this.user.cards.cards;
    },
  },
  watch: {
    updateCard() {
      this.paymentType = this.user.cards.default;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    initialize() {
      this.user.cards.cards.forEach((card) => {
        if (this.user.cards.default === card.id) this.paymentType = card.id;
      });
    },

    registCard() {
      // 情報入力モーダル開く
      const args = {
        modalName: 'registCard',
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },

    deleteCard() {
      // 確認モーダル開く
      const data = {
        card_id: this.paymentType,
        customer_id: this.user.customer.customer_id,
      };
      const args = {
        modalName: 'deleteCard',
        data,
      };
      this.$store.dispatch('modal/messages/showModal', args, { root: true });
    },

    changeDefault() {
      this.showLoading();
      const data = {
        customer_id: this.user.customer.customer_id,
        card_id: this.paymentType,
      };

      this.axios({
        method: 'POST',
        url: '/v1/stripe/customer/set/changeDefaultCard',
        data,
      })
        .then(() => {
          // storeユーザー情報更新
          this.$store.dispatch('user/update', null, { root: true });
          const sendData = {
            customer: {
              customer_id: this.user.customer.customer_id,
            },
          };
          this.$store.dispatch('user/cards/getCustomer', sendData, { root: true });
          alert('デフォルトのクレジットカードを変更しました。');
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          alert('デフォルトのクレジットカードの変更に失敗しました。お手数ですが、再度やり直してください。');
        })
        .finally(() => {
          this.hideLoading();
          this.$store.dispatch('modal/contents/hideModal', null, { root: true });
        });
    },
  },
};
</script>

<style lang="scss" module>
.list {
  margin: .3em 0;
}
.card {
  display: inline-block;
  width: 2.4em;
  border-radius: 2px;
  overflow: hidden;
  margin: auto 5px auto 0;
}

.icon {
  padding-top: percentage(142/220);
  background-size: cover;
}
</style>
