<template>
  <div class="admin">
    <div class="loader-wrapper">
      <div
        class="loaded-content"
        v-show="!flag.isLoader">

        <contents-container
          :size="'md'"
          v-if="!flag.notFound && !flag.invalid">
          <router-view
            v-if="!flag.isTop"
            @invalid="updateInvalid"
            @loader="updateLoader" />
          <AdminTop
            v-if="flag.isTop" />
        </contents-container>

        <NotFound
          v-if="flag.notFound && !flag.invalid" />

        <Invalid
          v-if="flag.invalid && !flag.notFound" />
      </div>

      <div
        class="loader-simple"
        v-if="flag.isLoader"></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AdminTop from '@/views/Admin/Top.vue';
import NotFound from '@/views/404.vue';
import { ContentsContainer } from '@/components/parts/Container';
import cf from '@/mixins/commonFunctions';
import Invalid from '../Invalid.vue';

export default {
  name: 'AdminWrapper',
  mixins: [cf],
  components: {
    NotFound,
    ContentsContainer,
    Invalid,
    AdminTop,
  },
  data() {
    return {
      flag: {
        isLoader: true,
        notFound: false,
        invalid: false,
        isTop: false,
      },
    };
  },
  computed: {
    ...mapState(['user', 'helper']),
  },
  created() {
    if (this.user.email) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putEmails') {
          this.initialize();
        }
      });
    }
  },
  watch: {
    $route() {
      this.initialize();
    },
  },
  methods: {
    initialize() {
      this.flag.isLoader = true;
      this.flag.isTop = false;
      if (!this.helper.emails.includes(this.user.email)) this.flag.notFound = true;
      if (this.$route.name === 'admin') this.flag.isTop = true;
      this.flag.isLoader = false;
    },

    // 子コンポーネントからloaderフラグ受け取り
    updateLoader(bool) {
      this.flag.isLoader = bool;
    },

    // 子コンポーネントからinvalidフラグ受け取り
    updateInvalid(value) {
      this.flag.invalid = value;
    },
  },
};
</script>


<style module lang="scss">
</style>
