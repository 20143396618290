<template>
  <div class="invalid">
    <div class="container">
      <h1 class="title-page lg">Invalid</h1>

      <div class="mt-5">
        <p>無効なURLまたは操作です。<br>お手数ですが最初からやりなおしてください。</p>
      </div>

      <div :class="$style.btnsWrap">
        <ul class="btn-lists horizontal center">
          <li>
            <router-link
              class="btn btn__tertiary"
              :to="'/'">トップへ戻る</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Invalid',
};
</script>


<style module lang="scss">
.btnsWrap {
  margin-top: 5rem;
}
</style>
