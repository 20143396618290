<template>
  <div class="account accounts">
    <div class="container">
      <h2 class="title-page mb-4">お支払い履歴</h2>

      <div class="loader-wrapper">
        <div
          class="loaded-contents"
          v-show="!flag.loader">
          <div v-if="sales.length && helper.master.projects">
            <dl class="support-record"
                v-for="sale in sales"
                :key="sale.id">
              <dt>
                <div class="support-record_date">
                  {{ formatTimestamp(sale.created_at, 'YYYY.MM.DD') }}
                </div>
              </dt>
              <dd>
                <table>
                  <tr>
                    <td class="table-left">プロジェクト</td>
                    <td><div class="support-record_contents">{{ getProject(sale.project_id).title }}</div></td>
                  </tr>
                  <tr>
                    <td class="table-left">リターン</td>
                    <td>
                      <div class="support-record_contents record-wrap">
                        <div>{{ `${getReturn(sale.return_id).title}${getReturn(sale.return_id).course}` }}</div>
                        <div>（¥{{ addComma(sale.price + sale.tax) }}）</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-left">お支払い方法</td>
                    <td>
                      <div class="support-record_contents">
                        {{ paymentType[`type${sale.payment_type}`] }}
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="sale.payment_type === 2">
                    <td class="table-left">支払い状況</td>
                    <td>
                      <div class="support-record_contents">
                        {{ paymentStatus[sale.status] }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="table-left">表示名</td>
                    <td>
                      <div
                        class="support-record_contents record-wrap"
                        v-if="!flag.editing || editingSalesId !== sale.id">
                        <div
                          v-if="sale.displayname">
                          {{ sale.displayname.displayname }}
                          <i
                            class="editIcon fas fa-pencil-alt"
                            v-on:click="changeEditFlag(sale, 2)"></i>
                        </div>
                        <div
                          v-if="!sale.displayname && user.displayname">
                          {{ user.displayname }}
                          <i
                            class="editIcon fas fa-pencil-alt"
                            v-on:click="changeEditFlag(sale, 1)"></i>
                        </div>
                        <div
                          v-if="!sale.displayname && !user.displayname">
                          未設定
                          <i
                            class="editIcon fas fa-pencil-alt"
                            v-on:click="changeEditFlag(sale, 0)"></i>
                        </div>
                      </div>
                      <div
                        class="support-record_contents record-wrap"
                        v-if="flag.editing && editingSalesId === sale.id">
                        <form
                          action="">
                          <input
                            class="form-control"
                            type="text"
                            :value="this.updateDisplayname"
                            v-on:change="updateValue">
                        </form>
                        <button
                          class="editBtn"
                          v-on:click="update(sale)"
                          v-if="sale.displayname">登録</button>
                        <button
                          class="editBtn"
                          v-on:click="regist(sale)"
                          v-if="!sale.displayname">登録</button>
                        <button
                          class="editBtn"
                          v-on:click="cancelEdit"
                          >キャンセル</button>
                      </div>
                    </td>
                  </tr>
                </table>
              </dd>
            </dl>
          </div>

          <div v-else><p>現在お支払いの履歴はございません。</p></div>
        </div>

        <div
          class="loader-simple"
          v-if="flag.loader"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'edit-payment-list',
  mixins: [cf],
  data() {
    return {
      sales: [],
      projectsDetail: [],
      updateDisplayname: null,
      editingSalesId: null,
      flag: {
        editing: false,
        loader: true,
      },
      paymentType: {
        type1: 'クレジットカード決済',
        type2: '銀行振込',
      },
      paymentStatus: {
        succeeded: 'お支払い済み',
        requires_action: 'お支払い待ち',
      },
    };
  },
  created() {
    if (this.user.email) {
      this.getSalesList();
    } else {
      this.$store.subscribe((mutation) => {
        // ユーザ情報を取得してvaluesを定義
        if (mutation.type === 'user/setUserData') this.getSalesList();
      });
    }
  },
  computed: {
    ...mapState(['user', 'page', 'helper']),
  },
  methods: {
    getSalesList() {
      const params = {
        user_id: this.user.id,
      };

      this.axios({
        method: 'GET',
        url: '/v1/sales/get/list',
        params,
      })
        .then((response) => {
          this.sales = response.data.data;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });

      this.flag.loader = false;
    },

    changeEditFlag(sale, i) {
      this.flag.editing = true;
      this.editingSalesId = sale.id;
      if (i === 2) this.updateDisplayname = sale.displayname.displayname;
      else if (i === 1) this.updateDisplayname = this.user.displayname;
    },

    cancelEdit() {
      this.flag.editing = false;
      this.updateDisplayname = null;
    },

    updateValue(e) {
      this.updateDisplayname = e.target.value;
    },

    update(sale) {
      if (this.updateDisplayname === null || this.updateDisplayname === '') {
        alert('表示名を入力してください。');
        return;
      }

      const data = {
        id: sale.displayname.id,
        displayname: this.updateDisplayname,
      };

      this.axios({
        method: 'POST',
        url: '/v1/displayname/set/update',
        data,
      })
        .then(() => {
          this.updateDisplayname = null;
          this.getSalesList();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {

        });

      this.flag.editing = false;
    },

    regist(sale) {
      if (this.updateDisplayname === null || this.updateDisplayname === '') {
        alert('表示名を入力してください。');
        return;
      }

      const data = {
        id: null,
        flag: 1,
        user_id: this.user.id,
        project_id: sale.project_id,
        return_id: sale.return_id,
        displayname: this.updateDisplayname,
        sales_id: sale.id,
      };

      this.axios({
        method: 'POST',
        url: '/v1/displayname/set/register',
        data,
      })
        .then(() => {
          this.updateDisplayname = null;
          this.getSalesList();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {

        });

      this.flag.editing = false;
    },

    /**
     * project_idから該当のプロジェクトを返却
     */
    getProject(projectId) {
      let result = null;
      this.helper.master.projects.some((p) => {
        if (p.id === projectId) {
          result = p;
          return true;
        }
        return false;
      });
      return result;
    },

    /**
     * project_idから該当のプロジェクトを返却
     */
    getReturn(returnId) {
      let result = null;
      this.helper.master.projects.some((p) => {
        let flag = false;
        p.returns.some((r) => {
          if (r.id === returnId) {
            result = r;
            flag = true;
            return true;
          }
          return false;
        });
        if (flag) return true;
        return false;
      });
      return result;
    },
  },
};
</script>
