<template>
  <div v-if="completion">
    <template v-if="!flag.notFound && project">
      <first-view :project="project" />
      <main-contents :project="project" />
    </template>
    <NotFound v-else />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { FirstView, MainContents } from '@/components/pages/projects/detail';
import NotFound from '@/views/404.vue';
import cf from '@/mixins/commonFunctions.js';

export default {
  name: 'ProjectDetail',
  mixins: [cf],
  components: {
    FirstView,
    MainContents,
    NotFound,
  },
  computed: {
    ...mapState(['helper']),
  },
  data() {
    return {
      project: null,
      slug: null,
      flag: {
        notFound: false,
      },
      completion: false,
    };
  },
  created() {
    if (!this.$route.params.slug) {
      this.flag.notFound = true;
      return;
    }
    this.slug = this.$route.params.slug;

    if (this.helper.master.projects) {
      this.getProjectDetail();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'helper/putmaster') {
          this.getProjectDetail();
        }
      });
    }
  },
  mounted() {
    setTimeout(() => { this.anchor(); }, 200);
  },
  methods: {
    /**
     * 実際にはAPIで取得
     */
    getProjectDetail() {
      const slugs = this.helper.master.projects.map((r) => { const slug = r.slug; return slug; });
      if (!slugs.includes(this.slug)) { this.completion = true; this.flag.notFound = true; return; }
      this.$store.state.helper.master.projects.some((r) => {
        if (this.slug === r.slug) {
          this.project = r;
          /**
           * ローカル・dev環境ではコネクトアカウントを固定
           */
          if (this.$store.state.helper.env.name !== 'production') {
            this.project.connectedAccountId = 'acct_1Jp3xnPEsH6PE97m';
          }
          this.completion = true;
          this.setMetas();
          return true;
        }
        return false;
      });
    },


    // HTML形式の文字列からタグ類を除去して返す
    transPlainString(htmlString) {
      const regex = /(<([^>]+)>)/ig;
      return htmlString.replace(regex, '');
    },

    async setMetas() {
      const defaults = {
        title: 'ファンディング桐生',
        description: 'ファンディング桐生は地域密着型クラウドファンディングです',
        og_image: 'https://kiryu.fund/img/og.png',
      };

      const meta = {};
      const item = cf.methods.getClone(this.project);

      // プロジェクトタイトル、プロジェクト詳細があればセット
      meta.title = `${item.title} | ${defaults.title}`;
      // meta.description = await this.transPlainString(item.description) || defaults.description; // 本文もクロールされるようになったらこちらに変更
      meta.description = await this.transPlainString(item.content) || defaults.description;
      const split = location.pathname.split('/');
      const trailingSlash = split[split.length - 1] !== '' ? '/' : '';
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}${trailingSlash}`;
      meta.type = 'article';
      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // canonicalタグの動的生成
      const canonical = document.querySelector("link[rel='canonical']");
      if (canonical) {
        // 存在する場合は書き換え
        canonical.href = meta.og_url;
      } else {
        // 存在しない場合は生成
        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = meta.og_url;
        document.head.appendChild(linkTag);
      }
    },

    anchor() {
      const hash = this.$route.hash;
      if (hash && hash.match(/^#.+$/)) {
        this.$scrollTo(hash);
      }
    },
  },
};
</script>
