/**
* ログイン管理
*/

import axios from '@/plugins/axios';
import router from '@/router';
import cf from '@/mixins/commonFunctions';

// 閲覧にログインが必須のページ名（配下含め）
const requiredLoginPageNames = [
  'member',
  'admin',
  'account',
  'edit',
];


const actions = {
  /** state.isLoginの変更 */
  changeLoginStatus({ commit }, bool) {
    commit('changeLoginStatus', bool);
  },


  /** ログインしているかのチェック */
  check({ commit, dispatch, state }) {
    const ls = cf.getLocalStorage('fk');
    // 現在の環境チェック
    dispatch('helper/checkEnv', null, { root: true });

    const currentPath = router.options.history.location;

    // localStorageが存在しない（未サインナップ）
    if (!ls || !ls.jwt) {
      commit('changeCheckedStatus', true);
      dispatch('user/setUserData', { hasntJWT: true }, { root: true });

      let isRedirect = false;
      requiredLoginPageNames.some((pageName) => {
        if (currentPath.includes(pageName)) { isRedirect = true; return true; }
        return false;
      });

      // ログインページへリダイレクト
      if (isRedirect) {
        alert('本ページを閲覧するにはログインしている必要があります。\nログインページへリダイレクトします。');
        const current = router.options.history;
        const ref = { path: current.location };
        // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
        cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, 'fk');
        dispatch('redirect2Login');
      }

      // guestユーザ情報の取得格納
      if (ls?.user_id) dispatch('user/update', null, { root: true });

      return;
    }

    // ログインstateがなく、かつJWTがあれば認証APIを叩く
    if (!state.isLogin && ls.jwt) {
      dispatch('authCheck', {
        type: 'check',
        jwt: ls.jwt,
      });
    }
  },


  /**
   * ログインページのsubmit押下
   * API通信してIDとPASSをpost
   * 認証に成功したらJWT(token)をlocalStorageへ保存
   * @param obj data
   *   email:    str
   *   password: str
   */
  submit({ commit, dispatch }, data) {
    axios({
      method: 'post',
      url: '/v1/user/login',
      data,
    })
      .then((response) => {
        const res = response.data;
        if (res && res.token) {
          // tokenをlocalStorageに保存
          cf.saveLocalStorage({
            jwt: res.token,
            supportType: 'login',
          }, 'fk');

          // 取得したtokenを元にログインチェック
          dispatch('authCheck', {
            type: 'login',
            isRegist: data.isRegist,
            jwt: res.token,
          });
        }
      })
      .catch((error) => {
        dispatch('loginFailed');
        if (error.response) {
          const errRes = error.response.data;
          console.log(errRes);
          if (errRes.isBanned) {
            commit('changeBannedStatus', { isBanned: errRes.isBanned, unbannedTime: errRes.unbannedTime });
          } else if (errRes.failedStatus) {
            commit('changeBannedStatus', { isBanned: errRes.failedStatus.isBanned, unbannedTime: errRes.failedStatus.unbannedTime });
          } else {
            commit('changeBannedStatus', { isBanned: false, unbannedTime: null });
          }
          if (errRes.detail.includes('E_USER_NOT_FOUND')) {
            commit('changefailedReasonStatus', { userNotFound: true });
          } else if (errRes.detail.includes('E_PASSWORD_MISMATCH')) {
            commit('changefailedReasonStatus', { passwordMismatch: true });
          } 
        } else console.log(error);    
      });
  },


  /**
   * auth情報を使ってログインチェックし
   * 取得したユーザーデータをstateへ格納
   * @param obj data
   *   jwt: obj
   *   type: 'check' || 'login'
   * logincheckではlocalstorageから
   * loginsubmitでは取得したjwtをdataに格納して渡す
   */
  authCheck({ commit, dispatch }, data) {
    const ls = cf.getLocalStorage('fk');
    /** jwtからauthorization生成 */
    const Authorization = `${data.jwt.type} ${data.jwt.token}`;
    axios({
      method: 'GET',
      url: '/v1/user/loginCheck',
      headers: { Authorization },
    })
      .then((response) => {
        // user.flagが1以外はログイン無効
        const res = response.data.data;
        if (res.flag !== 1) {
          // ログイン時には失敗の挙動
          if (data.type === 'login') dispatch('loginFailed');
          // localStorageのjwtを削除
          cf.deleteLocalStorage('fk', 'jwt');
          commit('changeLoginStatus', false);
          return;
        }

        // authcheck後にstateを更新
        commit('changeLoginStatus', true);
        // ユーザー情報をstate.userにセット（user/index.js）
        dispatch('user/setUserData', res, { root: true });
        // ユーザーの支払い方法データ
        if (res.customer) {
          dispatch('user/cards/getCustomer', res, { root: true });
          dispatch('user/subscriptions/getSubscriptions', res, { root: true });
        }
        // システムマスターの判別
        dispatch('helper/checkSystemMaster', null, { root: true });
        // ログイン制限state解除
        commit('changeBannedStatus', { isBanned: false, unbannedTime: null });

        // ログイン時
        if (data.type === 'login') {
          // リファラの有無でリダイレクト先が変わる
          if (ls && ls.loginRef) {
            const ref = JSON.parse(ls.loginRef);
            router.push(ref);
          } else {
            alert('ログインに成功しました。\n自動的にリダイレクトします');

            // TODO: ログイン前のページへリダイレクト

            router.push({ path: '/account/' });
          }
        }
      })
      .catch((error) => {
        console.log('ログイン認証エラー');
        if (error.response) console.log(error.response.data);
        else console.log(error);
        // ログイン画面へリダイレクトするかのフラグ
        let isRedirect2Login = false;
        if (data.type === 'check') {
          // ログインチェック時
          const status = error.response.status;
          if (status === 401) { // 期限切れ
            // ログインチェックが実行されたURL
            const originURL = location.href;
            // URLに指定ページ名が含まれていればフラグ有効化
            requiredLoginPageNames.some((pageName) => {
              if (originURL.includes(pageName)) {
                isRedirect2Login = true;
                return true;
              }
              return false;
            });

            if (isRedirect2Login) { // ログイン画面へリダイレクト
              alert('ログイン有効期限が切れています。\nログイン画面へリダイレクトします');
              const current = router.options.history;
              const ref = { path: current.location };
              // ログイン後に元いたページへ戻すためpathとqueryを保持しておく
              cf.saveLocalStorage({ loginRef: JSON.stringify(ref) }, 'fk');
              dispatch('redirect2Login');
            } else { // 非ログインとしてログインページへリダイレクト
              dispatch('redirect2Login');
              cf.deleteLocalStorage('fk', 'loginRef');
            }

            // localStorageのjwtを削除
            cf.deleteLocalStorage('fk', 'jwt');
          } else if (status === 403) {
            // DBとの接続に失敗
            alert('データの取得に失敗しました。リロードをお試しください');
          }
        } else if (data.type === 'login') {
          // ログイン直後（取得直後のjwtなので論理的には失敗する事ないはず）
          alert('ログインで問題が発生しました\nお手数ですが管理者へお知らせください');
          dispatch('redirect2Login');
          // localStorageのjwtを削除
          cf.deleteLocalStorage('fk', 'jwt');
        }
      })
      .finally(() => {
        commit('changeCheckedStatus', true);
      });
  },


  /** ログイン失敗 */
  loginFailed({ commit }) {
    commit('changeVibrationStatus', true);
    setTimeout(() => { commit('changeVibrationStatus', false); }, 500);
  },


  /** 有効期限切れ時のログイン画面リダイレクト */
  redirect2Login() {
    router.push({ path: '/login/' });
  },


  /** トップへリダイレクト */
  redirect2Top() {
    router.push({ path: '/' });
  },


  /** ログアウト */
  logout({ commit, dispatch }) {
    // localStorageの更新
    cf.deleteLocalStorage('fk', 'jwt');
    cf.deleteLocalStorage('fk', 'loginRef');
    cf.deleteLocalStorage('fk', 'lot');

    dispatch('helper/reset', null, { root: true });
    dispatch('user/cards/reset', null, { root: true });
    dispatch('user/subscriptions/resetAll', null, { root: true });
    commit('changeLoginStatus', false);

    // ログインページへリダイレクト
    router.push({ path: '/login/' });
  },
  // 入力し直しと同時にエラー表示を消す
  refresh({ commit }) {
    commit('changefailedReasonStatus', false);
  },
};

const mutations = {
  /** チェック済みステート */
  changeCheckedStatus(state, bool) {
    state.isChecked = bool;
  },

  /** ログインステート */
  changeLoginStatus(state, bool) {
    state.isLogin = bool;
  },

  /** ログイン失敗時のバイブフラグステート */
  changeVibrationStatus(state, bool) {
    state.vibration = bool;
  },

  /** ログイン制限時のフラグ・日時ステート */
  changeBannedStatus(state, { isBanned: bool, unbannedTime: datetime }) {
    state.isBanned = bool;
    state.unbannedTime = datetime;
  },
  /** ログイン失敗理由表示フラグステート */
  changefailedReasonStatus(state, { userNotFound: bool, passwordMismatch: data }) {
    state.failedReason.userNotFound = bool || false;
    state.failedReason.passwordMismatch = data || false;
  },
}; 

const state = {
  isChecked: false,
  isLogin: false,
  vibration: false,
  isBanned: false,
  unbannedTime: null,
  failedReason: {
    userNotFound: false,
    passwordMismatch: false,
  },  
};


export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
