<template>
  <div :class="$style.hamburgerMenu">
    <button
      @click="toggleMenu"
      :class="triggerClass"
    >
      <span></span>
    </button>
    <div :class="[$style.content, isOpenClass]">
      <div :class="[$style.content__inner, isOpenClass]">
        <ul>
          <template v-if='isLogin'>
            <li>
              <button
                @click="handleLogOut"
                :class="[$style['content__btn'], $style['content__btn--white']]">ログアウト</button>
            </li>
            <li>
              <router-link
                to="/account/"
                @click="closeMenu"
                :class="[$style['content__btn']]">マイページ</router-link>
            </li>
            <li v-if="helper.isSystemMaster">
              <router-link
                to="/admin/"
                @click="closeMenu"
                :class="[$style['content__btn'], $style['content__btn--white']]">Admin</router-link>
            </li>
          </template>
          <template v-else>
            <li>
              <router-link
                to="/login/"
                :class="[$style['content__btn'], $style['content__btn--white']]"
                @click="closeMenu">ログイン</router-link>
            </li>
            <li>
              <router-link
                to="/signup/"
                :class="$style['content__btn']"
                @click="closeMenu">
                新規登録</router-link>
            </li>
          </template>
          <!-- <li>
            <router-link
              to="/about/"
              :class="$style['content__link']"
              @click="closeMenu">クラウドファンディングとは？</router-link>
          </li> -->
        </ul>
      </div>
    </div>
    <teleport to="body">
      <transition
        :enter-from-class="$style['menuOverlay-enter-from']"
        :enter-active-class="$style['menuOverlay-enter-active']"
        :enter-to-class="$style['menuOverlay-enter-to']"
        :leave-from-class="$style['menuOverlay-leave-from']"
        :leave-active-class="$style['menuOverlay-leave-active']"
        :leave-to-class="$style['menuOverlay-leave-to']"
      >
        <button
          v-if="isOpen"
          @click='closeMenu'
          :class="[$style.overlay, isOpenClass]"
          />
      </transition>
    </teleport>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'HamburgerMenu',
  data() {
    return {
    };
  },
  computed: {
    ...mapState('user/login', ['isLogin']),
    ...mapState('layout/menu', ['isOpen']),
    ...mapState('layout/header', ['isTransparent']),
    ...mapState(['helper']),
    isOpenClass() {
      return this.isOpen ? this.$style.isOpen : '';
    },
    isTransparentClass() {
      return this.isTransparent ? this.$style.isTransparent : '';
    },
    triggerClass() {
      return {
        [this.$style.trigger]: true,
        [this.$style.isOpen]: this.isOpen,
        [this.$style.isTransparent]: this.isTransparent,
      };
    },
  },
  methods: {
    ...mapActions('user/login', [
      'logout',
    ]),
    ...mapActions('layout/menu', [
      'toggleMenu',
      'closeMenu',
      'openMenu',
    ]),
    handleLogOut() {
      this.closeMenu();
      this.logout();
    },
    // toggleMenu(logo = false, logout = false) {
    //   let next = !this.spMenu;
    //   // ロゴクリック時にオープンの場合は無視
    //   if (logo && next) next = false;
    //   this.spMenu = next;
    //   if (logout) this.$store.dispatch('user/login/logout', null, { root: true });
    // },
  },
};
</script>

<style module>
.hamburgerMenu {
  display: block;
  position: relative;
}

.hamburgerMenu__bg {
  height: 0;
  opacity: 0;
}

.hamburgerMenu__grayout {
  height: 0;
  opacity: 0;
}

.trigger {
  position: relative;
  display: flex;
  height: var(--header-height);
  width: var(--header-height);
  justify-content: center;
  align-items: center;
  z-index: 90;
  background-color: rgb(29, 166, 242);
  transition: background-color var(--header-transition-duration) ease-in-out;
}

.trigger.isTransparent {
  background-color: #000;
}

.trigger.isOpen {
  background-color: #fff;
}

.trigger span,
.trigger span::before,
.trigger span::after {
  content: '';
  display: block;
  height: 3px;
  width: 44.642857%;
  border-radius: 3px;
  background-color: #ffffff;
  position: absolute;
}

.trigger span::before,
.trigger span::after {
  width: 100%;
}

.trigger span::before {
  bottom: 8px;
}
.trigger span::after {
  top: 8px;
}

.trigger.isOpen span {
  background-color: rgba(255, 255, 255, 0);
}

.trigger.isOpen span::before {
  bottom: 0;
  transform: rotate(45deg);
  background-color: #000000;
}

.trigger.isOpen span::after {
  top: 0;
  transform: rotate(-45deg);
  background-color: #000000;
}

.content {
  position: absolute;
  width: 100vw;
  top: 100%;
  right: 0;
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 80px 0;
  transition: all var(--header-transition-duration) ease-in-out;
  opacity: 0;
  pointer-events: none;
  transform: translate3d(0, 0, 0);
}

.content.isOpen {
  opacity: 1;
  pointer-events: auto;
}

.content__inner {
  opacity: 0;
  transform: translate3d(0, -20px, 0);
  transition: all 400ms ease-in-out;
}

.content__inner.isOpen {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.content__btn {
  text-decoration: none;
  display: inline-block;
  width: 335px;
  max-width: calc(100% - 40px);
  height: 48px;
  background-color: #000;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 48px;
  border-radius: 114px;
  text-align: center;
  margin-bottom: 32px;
}

.content__btn--white {
  margin-bottom: 20px;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}

.content__btn__link {
  font-weight: bold;
  text-decoration: none;
  color: #000;
}

.overlay {
  position: fixed;
  top: var(--header-height);
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 2;
}

.menuOverlay-enter-active {
  transition: opacity 200ms 200ms ease-in-out;
}

.menuOverlay-leave-active {
  transition: opacity 100ms ease-in-out;
}

.menuOverlay-leave-to {
  transition-delay: 0;
}

.menuOverlay-enter-from,
.menuOverlay-leave-to {
  opacity: 0;
}

.menuOverlay-enter-to,
.menuOverlay-leave-from {
  opacity: 1;
}
</style>
