/**
* 共通ヘルパー
*/

import axios from '@/plugins/axios';

const actions = {
  /** ロケーションからenvを判別 */
  checkEnv({ commit, state }) {
    if (state.env.flag) return;

    const hostname = location.hostname;
    let env;
    switch (hostname) {
      case 'localhost': env = 'local'; break;
      case 'dev.kiryu.fund': env = 'develop'; break;
      case 'origin-dev.kiryu.fund': env = 'develop'; break;
      case 'kiryu.fund': env = 'production'; break;
      case 'origin.kiryu.fund': env = 'production'; break;
      default: env = 'production'; break;
    }

    const data = {
      flag: true,
      name: env,
    };

    commit('putEnv', data);
  },

  async checkSystemMaster({ commit, rootState }) {
    const masterUsers = await axios({
      method: 'get',
      url: '/v1/masterUser/get/list',
      params: { onlyEmail: true },
    });

    const flag = masterUsers.data.emails.includes(rootState.user.email);
    commit('isSystemMaster', flag);
    commit('putEmails', masterUsers.data.emails);
  },

  reset({ commit }) {
    commit('reset');
  },

  getMaster({ commit }) {
    axios({
      method: 'GET',
      url: '/v1/helper/get/master',
    })
      .then((response) => {
        commit('putmaster', response.data.master);
      })
      .catch((error) => {
        if (error.response) console.log(error.response.data);
        else console.log(error);
      });
  },
};


const mutations = {
  putEnv(state, data) {
    state.env.flag = data.flag;
    state.env.name = data.name;
  },

  isSystemMaster(state, bool) {
    state.isSystemMaster = bool;
  },

  reset(state) {
    state.isSystemMaster = false;
    state.env = {
      name: 'production',
      flag: false,
    };
  },

  putmaster(state, data) {
    state.master = data;
  },

  putEmails(state, emails) {
    state.emails = emails;
  },
};


const state = {
  emails: [],
  isSystemMaster: false,
  env: {
    name: 'production',
    flag: false,
  },
  master: {},
};

export default {
  namespaced: true,
  actions,
  mutations,
  state,
};
