<template>
  <div id="forgot-password" class="forgot-password accounts">
    <div class="container-sm">
      <h2 class="title-page lg">パスワード再発行</h2>

      <form
        class="form vertical"
        v-on:submit="submit"
        autocomplete="off">
        <div class="form-contents">
          <dl class="form-group">
            <dt class="label">
              <label
                for="mail"
                class="form-label">メールアドレス</label>
            </dt>
            <dd class="detail input">
              <div class="form-parts">
                <input
                  id="mail"
                  type="email"
                  name="mail"
                  v-bind:disabled="flag.sended && flag.updated"
                  v-model.trim="$v.mail.$model"
                  v-bind:class="{ input_error: validationFlags.mail }"
                  v-on:input="invalidsCheck('mail', $v.mail.$invalid)"
                  >
              </div>

              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.required.$invalid">メールアドレスを入力してください</p>

              <p
                class="form-text"
                v-if="$v.mail.$dirty
                  && $v.mail.email.$invalid">正しいメールアドレスの形式で入力してください</p>
            </dd>
          </dl>

          <div class="form-group btns mt-sm">
            <ul class="btn-lists">
              <li>
                <button
                  type="submit"
                  class="btn btn__primary sm"
                  v-bind:disabled="!submitFlag">送信</button>
              </li>
            </ul>

            <div class="messages mt-4">
              <p
                v-if="!flag.sended">ご登録のメールアドレスを入力・送信してください。</p>

              <p
                v-if="flag.sended
                  && !flag.updated">送信いただいたメールアドレスのご登録は存在しませんでした。ご登録のメールアドレスをご確認ください。</p>

              <p
                v-if="flag.sended
                  && flag.updated">ご登録のメールアドレスへパスワードリセット用のメールを送信しました。<br>メールに記載されているURLからパスワードのリセットを行ってください。</p>
            </div>
          </div>
        </div>
      </form>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { ref } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'forgot-password',
  mixins: [cf],
  data() {
    return {
      validationFlags: {
        mail: false,
      },
      invalids: {
        mail: true,
      },
      flag: {
        sended: false,
        updated: false,
      },
    };
  },
  created() {
  },
  setup() {
    const mail = ref('');

    const rules = {
      // メールは必須・email形式
      mail: { required, email },
    };

    const $v = useVuelidate(rules, { mail });

    return { mail, $v };
  },
  computed: {
    ...mapState(['user', 'page']),
    submitFlag() {
      // 無効フラグが全てfalseならばtrueを返却
      return !this.invalids.mail && !(this.flag.sended && this.flag.updated);
    },
  },
  methods: {
    /** 無効フラグの更新 */
    invalidsCheck(name, bool) {
      this.invalids[name] = bool;
    },

    /** サブミット */
    submit(e) {
      e.preventDefault();
      const data = { mail: this.mail };

      this.axios({
        method: 'POST',
        url: '/v1/user/forgotPassword',
        data,
      })
        .then((response) => {
          const resData = response.data;
          if (resData.updated) this.flag.updated = true;
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.flag.sended = true;
        });
    },
  },
};
</script>
