<template>
  <section class="commercial">
    <div class="commercial pages">
      <div class="pages_top">
        <p class="pages_top_title">特定商取引法に基づく表記</p>
        <div class="pages_section_description"></div>
      </div>
      <section class="pages_section">
        <div>
          <table class="commercial_table">
            <tr><td>販売業者の名称</td><td>株式会社シカク</td></tr>
            <tr><td>所在地</td><td>〒376-0031　群馬県桐生市本町６丁目２</td></tr>
            <tr><td>電話番号</td><td>090-9815-4128</td></tr>
            <tr><td>メールアドレス</td><td>service@kiryu.fund</td></tr>
            <tr><td>運営統括責任者</td><td>今氏一路</td></tr>
            <tr><td>追加手数料等の追加料金</td><td>基本的にリターンの配送があるプロジェクトではご支援金額に送料も含まれておりますが、例外的にリターンのサイズや特性上追加の送料が発生する場合がございます。詳しくは各プロジェクトページのプロジェクト内容とリターン内容をご確認ください。</td></tr>
            <tr><td>交換および返品（返金ポリシー） 　</td><td>ファンディング桐生ガバメントにおいてプロジェクトは全てAll in型となります。そのため、プロジェクト期間が終了するまでに寄附先が設定した目標金額を達成したか否かにかかわらず、プロジェクトにご支援いただいた時点で寄附者及び寄附先間でガバメント会員間契約が成立します。<br>そのため、プロジェクトの目標金額に未達成であっても返金はしておりませんので予めご了承ください。<br>また、プロジェクトごとに返金するケースを定めているプロジェクトもございますので、詳細はプロジェクトごとにご確認いただきますようお願いします。</td></tr>
            <tr><td>引渡時期</td><td>通常のECサイトとは異なり、ご支援後の配送までに数カ月要する事があります。</td></tr>
            <tr><td>受け付け可能な決済手段</td><td>クレジットカードまたは国内の銀行振込</td></tr>
            <tr><td>決済期間</td><td>クレジットカード決済の場合はただちに処理されますが、国内の銀行振込の場合はご支援の確定から 3 日以内にお振り込みいただく必要があります。</td></tr>
            <tr><td>販売価格</td><td>各プロジェクト・リターンのページに記載</td></tr>
            <tr><td>申込み期間の制限</td><td>各プロジェクトページに募集終了日を記載</td></tr>
            <tr><td>販売数量の制限</td><td>各プロジェクト・リターンのページに残りご支援可能数を記載</td></tr>
          </table>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">お問い合わせ</div>
          <div class="pages_section_description">その他ファンディング桐生に関するお問い合わせは、下記までお願い致します。</div>
        </div>
        <div class="support_info">
          <p>〒376-0031</p>
          <p> 群馬県桐生市本町６丁目２</p>
          <p>service@kiryu.fund</p>
          <p>株式会社シカク</p>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Commercial',
};
</script>
