<template>
  <div class="step3">
    <div class="payment-header">
      <p>銀行振り込み</p>
    </div>

    <div :class="$style.btnsWrap">
      <ul class="btn-lists horizontal center">
        <li v-if="!customer">
          <button
            class="btn btn__primary"
            @click="createCustomer"
            >銀行振込の準備を開始する</button>
        </li>
      </ul>
    </div>

    <pre>{{ customer }}</pre>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import cf from '@/mixins/commonFunctions';

// stripe
const STRIPE_PUB_KEY = cf.getStripePubKey();
const stripePromise = loadStripe(STRIPE_PUB_KEY, {
  apiVersion: '2020-08-27;customer_balance_payment_method_beta=v2',
  betas: ['customer_balance_beta_1'],
  locale: 'ja',
});

export default {
  name: 'PaymentStep3',
  mixins: [cf],
  props: ['project', 'returnItem', 'slugs'],
  components: {
  },
  data() {
    return {
      customer: null,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    const ls = cf.getLocalStorage('fk');
    if (!ls || !ls.lot) { this.$emit('invalid', true); return; }

    this.moveToStep(3);
    if (this.user.email) {
      this.initialize();
    } else {
      this.$store.subscribe((mutation) => {
        if (mutation.type === 'user/setUserData') this.initialize();
      });
    }
  },
  methods: {
    ...mapActions('payment', [
      'moveToStep',
    ]),
    /** 初期化 */
    initialize() {
      // ローダー非表示
      this.$emit('loader', false);
      this.getCustomer();
    },

    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },

    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    /**
     * DBに保存されているstripe_customer情報を取得
     */
    getCustomer() {
      this.axios({
        method: 'GET',
        url: '/v1/stripe/customer/get/byUser',
        params: {
          user_id: this.user.id,
        },
      })
        .then((response) => {
          const res = response.data;
          // stripeの顧客情報・intent登録済み
          if (res.customer) {
            this.customer = res.customer;
            this.getCustomerBalancePayment();
          }
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    async getCustomerBalancePayment() {
      const stripe = await stripePromise;

      await stripe.confirmCustomerBalancePayment(
        this.customer.intents[0].client_secret, {
          payment_method: {
            customer_balance: {},
          },
          // payment_method_options: {
          //   customer_balance: {
          //     funding_type: 'bank_transfer',
          //     bank_transfer: {
          //       // type: 'jp_bank_transfer',
          //       jp_bank_transfer: {
          //         country: 'JP',
          //       },
          //     },
          //   },
          // },
        }, {
          handleActions: false,
        },
      )
        .then(({ error, paymentIntent }) => {
          if (error) {
            console.log('error');
            // Inform the customer that there was an error.
            console.log(error);
          } else if (paymentIntent.status === 'requires_payment_method') {
            console.log('requires_payment_method');
            // If `payment_method_options.funding_type` wasn't set this
            // is where you would need to handle the insufficient customer
            // balance state.
            console.log(paymentIntent);
          } else if (paymentIntent.status === 'requires_action') {
            console.log('requires_action');
            // If the current customer balance is insufficient to cover
            // the amount, and you've passed
            // `payment_method_options.funding_type` for funding the
            // customer balance, you can display the bank transfer
            // instructions to your user.
            console.log(paymentIntent);
            if (paymentIntent.next_action.type === 'display_bank_transfer_instructions') {
              console.log('display_bank_transfer_instructions');
              // Bank transfer details can be found under:
              // paymentIntent.next_action.display_bank_transfer_instructions
              console.log(paymentIntent);
            }
          }
        });
      // this.axios({
      //   method: 'GET',
      //   url: '/v1/stripe/customer/get/balancePayment',
      //   params: {
      //     client_secret: this.customer.intents[0].client_secret,
      //   },
      // })
      //   .then((response) => {
      //     const res = response.data;
      //     console.log(res);
      //   })
      //   .catch((error) => {
      //     if (error.response) console.log(error.response.data);
      //     else console.log(error);
      //   });
    },

    /**
     * stripe_customer登録
     */
    createCustomer() {
      this.showLoading();

      const data = {
        customerData: {
          email: this.user.email,
          name: this.user.username,
          metadata: {
            user_id: this.user.id,
          },
        },
      };

      this.axios({
        method: 'POST',
        url: '/v1/stripe/customer/set/register',
        data,
      })
        .then((response) => {
          const res = response.data;
          this.customer = res.customer;
          this.createIntent();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
          this.hideLoading();
        });
    },

    /**
     * stripe_intent登録
     */
    createIntent() {
      const data = {
        intentData: {
          amount: this.returnItem.price, // TODO: 口数の乗算
          currency: 'jpy',
          customer: this.customer.id,
          payment_method_types: ['customer_balance'],
          payment_method_data: {
            type: 'customer_balance',
          },
          payment_method_options: {
            customer_balance: {
              funding_type: 'bank_transfer',
              bank_transfer: {
                type: 'jp_bank_transfer',
              },
            },
          },
          confirm: true,
        },
      };

      this.axios({
        method: 'POST',
        url: '/v1/stripe/intent/set/register',
        data,
      })
        .then((response) => {
          const res = response.data;
          console.log(res);
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>


<style module lang="scss">
.btnsWrap {
  margin-top: 5rem;
}
</style>
