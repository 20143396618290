<template>
  <section>
    <div class="terms pages">
      <div class="pages_top">
        <p class="pages_top_title">利用規約</p>
        <p>本利用規約は、株式会社シカクが運営する「ファンディング桐生」及び「ファンディング桐生」を通じて提供されるサービスについての利用条件を定めるものです（以下、「本利用規約」といいます。）。</p>
        <p>本サービスの利用希望者は、本利用規約の全てに同意いただき、当社が定める方法により会員登録をする必要があります。また、本サービスの利用により、会員は、本利用規約及び会員が利用するサービスごとに定められたガイドラインや注意事項に同意したものとみなされます。</p>
      </div>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第1条（定義）</div>
          <div class="pages_section_description">本利用規約において使用する用語の定義は、以下のとおりとします。</div>
        </div>
        <ol class="bracketslists">
          <li><p>「当社」-株式会社シカクをいいます。</p></li>
          <li><p>「ファンディング桐生」-当社が運営するアタラシイものや体験の応援購入サイトの総称をいいます。</p></li>
          <li><p>「本サービス」-「ファンディング桐生」を通じて提供される一切のサービスをいいます。会員は本サービスを利用してサポーターとしてプロジェクトに応援購入したり、プロジェクト実行者として、プロジェクトを企画し、サポーターを募集したり、応援購入や協力などを呼びかけたりすることができます。</p></li>
          <li><p>「利用希望者」-本サービスの利用を希望し、会員登録をしようとする者をいいます。</p></li>
          <li>
            <p>「会員」-利用希望者のうち、当社所定の手続きに従い、会員登録を申請し、当社がこれを承認した方をいいます。</p>
            <p>なお、本サービスを利用し、プロジェクトのサポーターを募集したり、応援購入を行う場合には、必ず本利用規約への同意と会員登録が必要です。</p>
          </li>
          <li><p>「利用者」-会員及び本サービスを利用する全ての者をいいます。</p></li>
          <li><p>「プロジェクト」-プロジェクト実行者が実現したい企画（製品の制作・開発、製品の輸入、サービスの開発・実施、作品の制作・提供、イベントの開催をはじめとする様々な企画）のうち、本サービスを通じて発表・掲載されたものをいいます。</p></li>
          <li><p>「リターン」-プロジェクトが成立した場合において、プロジェクト実行者が提供するプロジェクトの成果物（製品のほか、プロジェクト実行者が提供するサービスを受ける権利を含みます。）をいいます。</p></li>
          <li><p>「プロジェクト実行者」-プロジェクトを実行したり、本サービスを通じて、プロジェクトのサポーターを募集する（または募集した）会員をいいます。</p></li>
          <li><p>「サポーター」-プロジェクトに共感し、応援購入の決定を希望している会員のうち、プロジェクトの応援購入についての申込を完了した会員をいいます。</p></li>
          <li><p>「プロジェクト期間」-特定のプロジェクトにつき、ファンディング桐生を通じて当該プロジェクトの発表・掲載をし応援購入の募集が開始された時点から、当該プロジェクトについて設定された応援購入の募集が終了する時点までの期間をいいます。</p></li>
          <li><p>「All in型」-プロジェクトのうち、プロジェクト期間が終了するまでにプロジェクト実行者が設定した目標金額を達成したか否かにかかわらず、いずれかのサポーターにより応援購入の申込みがなされた時点でプロジェクトの成立となるプロジェクトをいいます。</p></li>
          <li><p>「All or Nothing型」-プロジェクトのうち、プロジェクト期間が終了するまでにプロジェクト実行者が設定した目標金額を達成した場合にプロジェクトが成立となるプロジェクトをいいます。</p></li>
          <li><p>「応援購入」-サポーターが、プロジェクトに共感等をし、応援等の意味合いも含めてプロジェクト実行者に対してリターンの対価の支払いをすることをいいます。</p></li>
          <li><p>「応援購入の申込」-サポーターが、当社所定の手続に従い、プロジェクトに対する応援購入の申込を確定させることをいいます。</p></li>
          <li><p>「応援購入の完了」-サポーターが、応援購入の支払方法として、クレジットカード払いを選択した場合には、応援購入の申込をした時点をいいます。サポーターが、コンビニ払い、銀行振込、ネットバンク振込等のクレジットカード払い以外の支払方法を選択した場合には、当社の指定する決済代行会社の決済承認がなされた時点（ただし、当社の定める期日までに入金することを条件とします）をいいます。</p></li>
          <li><p>「会員間契約」-サポーターによるプロジェクトの応援購入及びプロジェクト実行者によるリターンの提供に関する契約をいいます。</p></li>
          <li><p>「パートナー」-当社と提携関係にある法人又は個人をいいます。</p></li>
          <li><p>「登録情報」-本サービスの提供を受ける目的で、会員が当社に提供した一切の情報をいいます。</p></li>
          <li><p>「個人情報」-登録情報のうち、氏名、住所、生年月日、電話番号、電子メールアドレス、金融機関の口座情報、クレジットカード情報等、特定の個人を識別できる情報（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます）をいいます。</p></li>
          <li><p>「認証情報」-登録情報のうち当社が会員からの接続を認証するために必要な情報でIDやパスワードを含む情報をいいます。</p></li>
          <li><p>「反社会的勢力」-暴力団、暴力団員及び暴力団員でなくなったときから5年を経過しない者、暴力団準構成員、暴力団関係企業、総会屋等、政治活動、社会運動等標ぼうゴロ、特殊知能暴力集団等、反社会的勢力共生者、テロリスト等、日本政府または外国政府が経済制裁の対象として指定する者（以下、総称して「暴力団員等」といいます）、暴力団員等の共生者、その他これらに準ずる者をいいます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第2条（本サービスと当社の役割）</div>
        </div>
        <ol class="numlists">
          <li><p>本サービスは、アタラシイものや体験の応援購入サイトであり、会員間での交流やプロジェクトに関する応援購入の場や機会を提供するサービスです。</p></li>
          <li><p>会員間契約は、プロジェクトに関しサポーターとプロジェクト実行者の間において成立するものであり、当社は、その契約の履行には一切関与いたしません。また、プロジェクトに関与するサポーターとプロジェクト実行者以外の会員又は第三者（以下「関連当事者」といいます）が会員間契約に基づき何らかの権利を持ち又は義務を負うか否か、会員間契約又は関連当事者の権利義務の消滅や返金、補償等の取り扱いについても、当社は一切関与いたしません。</p></li>
          <li><p>会員間又は関連当事者との間においてトラブル等が発生した場合についても、当社が定める基準を満たした場合を除き、当社が紛争の解決のためのあっせん、調停、仲裁その他の紛争解決に向けた措置を講じることはありませんので、本サービスの利用に際しては十分に注意し、予めご了承の上ご利用ください。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第3条 (手続きの成立)</div>
          <div class="pages_section_description">会員がインターネット回線を通じて行った登録・サポーターの募集・応援購入の申込・退会・評価の記入・会員同士の連絡・その他の手続は、当社のサーバーに当該手続に関するデータが送信され、当社のシステムに当該手続の内容が反映された時点をもって有効に成立するものとします。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第4条（会員登録）</div>
        </div>
        <ol class="numlists">
          <li><p>利用希望者は、会員となる本人が当社の定める方法にて登録申請を行うものとします。</p></li>
          <li><p>当社が前項の登録申請を承認した場合に、当該利用希望者を会員として登録するものとします。</p></li>
          <li>
            <p>当社は、利用希望者が以下各号のいずれかに該当する場合、本条第1項の登録申請を承認しない場合があります。</p>
            <ol class="bracketslists">
              <li><p>利用希望者が過去に本利用規約違反をしたことなどにより、会員登録の抹消などの処分を受けていることが判明した場合</p></li>
              <li><p>利用希望者が申請時に登録した住所に、郵便物、宅配物が到着しなかった場合</p></li>
              <li><p>利用希望者が外国法人（日本に支店を持つ場合を除く）である場合又は日本国外に居住する場合</p></li>
              <li><p>利用希望者が当社に対する債務の支払を怠ったことがある場合</p></li>
              <li><p>当社の運営・サービス提供または他の会員の利用を妨害し、またはそれらに支障をきたす行為を行った場合またはその恐れがあると当社が判断した場合</p></li>
              <li><p>その他登録申請を承認することが不適当であると当社が判断した場合</p></li>
            </ol>
          </li>
          <li>
            <p>当社は、利用希望者が以下各号のいずれかに該当する場合、本条第1項の登録申請を承認しません。</p>
            <ol class="bracketslists">
              <li><p>登録申請に際して当社に提供した情報に虚偽の事項が含まれている場合</p></li>
              <li><p>利用希望者が反社会的勢力に該当する場合、又は、反社会的勢力が経営に実質的に関与している法人の役員、従業員である等、反社会的勢力と何らかの関係を有していると判明した場合</p></li>
              <li><p>本サービスが犯罪による収益の移転やテロ資金の供与のために用いられる恐れがあると判明した場合</p></li>
              <li><p>前３号のいずれか一に該当すると当社が判断した場合</p></li>
            </ol>
          </li>
          <li><p>会員は、第1項の規定に基づき登録申請を行った場合、前項各号のいずれにも該当しないことを表明し、保証したものとみなされます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第5条（プロジェクトの発表・プロジェクト実行者に関する条件）</div>
        </div>
        <ol class="numlists">
          <!-- <li>
            <p>プロジェクト実行者になろうとする会員は、「プロジェクト実行者ガイドライン」</p>
            <p>(URL:)に同意した上で、当社が定める方法によりプロジェクト実行者登録をする必要があります。</p>
          </li> -->
          <li><p>プロジェクト実行者登録をした者は、当社の定める方法にてプロジェクトのサポーターの募集の申請をすることができます。</p></li>
          <li><p>当社が、「プロジェクト実行者ガイドライン」に従い前項の申請を承認した場合には、プロジェクト実行者は、当社の定める方法によりプロジェクトをファンディング桐生において発表・掲載し、プロジェクト期間における目標金額の達成を目指すものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第6条（プロジェクトへの応援購入）</div>
        </div>
        <ol class="numlists">
          <li><p>プロジェクトの応援購入を希望する者は、当社の定める方式により応援購入を申込みます。応援購入する意思のないもの、または、いたずら目的のもの（それぞれ、当社の判断によります）はお断り申し上げます。</p></li>
          <li><p>プロジェクト期間内に、当社の管理するサーバーに応援購入の申込に関するデータ、応援購入の完了等のアクションデータなどの到達（書き込み）が確認できなかった場合、プロジェクトへの応援購入はなかったことになります。</p></li>
          <li><p>プロジェクト期間については、本サービスを通じて表示されていますので、必ずご確認いただいた上で応援購入するか決定してください。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第7条（プロジェクト成立及びキャンセル）</div>
        </div>
        <ol class="numlists">
          <li><p>プロジェクトがAll in型の場合は、プロジェクト期間が終了するまでにプロジェクト実行者が設定した目標金額を達成したか否かにかかわらず、いずれかのサポーターにより応援購入の申込みがなされた時点でプロジェクトは成立します。プロジェクトがAll or Nothing型の場合は、プロジェクト期間が終了するまでにプロジェクト実行者が設定した目標金額を達成した場合にプロジェクトが成立します。なお、プロジェクトがAll in型 又はAll or Nothing型のどちらにあたるかは、各プロジェクトのページにてご確認ください。</p></li>
          <li><p>サポーターが応援購入の申込をした時点で、サポーター及びプロジェクト実行者の間で会員間契約が成立します。会員間契約は、原則として、サポーターがプロジェクト実行者に対し、応援購入にかかる金銭（以下、「応援購入金」といいます。）を提供することを約し、プロジェクト実行者がサポーターに対して応援購入金の対価としてリターンを提供することを約する契約をその内容とし、リターンは製品その他の有体物のほか、一定の権利であることもあります（但し、法令上、プロジェクト実行者または当社が取り扱うことができないものを除きます。）。なお、リターンの内容によっては、これとは異なるものである場合もありますが、その場合には各プロジェクトのページにてプロジェクト実行者より取引の条件が示されます。会員間契約は、各プロジェクトページの内容に照らして成立し、その具体的な解釈は、民法その他の関連法令に基づき判断されます。</p></li>
          <li><p>サポーターによる応援購入の完了後のキャンセル及びプロジェクト実行者によるプロジェクトの中止は、本利用規約の規定に従ってのみ行われるものとし、それぞれ、任意のキャンセル又は任意の中止はできないものとします。</p></li>
          <li><p>プロジェクトへの応援購入を希望するサポーターは、当社の定める方法により、リターンの対価、消費税その他の各プロジェクトのページに記載された額の金銭を支払うものとします。なお、当社が指定する期日内に支払いがなされない場合には、プロジェクト実行者が個別に会員間契約の成立を承諾した場合を除いて、会員間契約は成立しなかったものとして取り扱われるものとします。</p></li>
          <li><p>プロジェクト実行者は、プロジェクトが成立した時点で、各プロジェクトのページに定める期日までにサポーターに対してリターンを提供する義務を負います。プロジェクト実行者は、プロジェクトの目標金額に達成していないこと（プロジェクトがAll or Nothing型である場合を除きます。）、応援購入金を受領していないこと等を理由として、リターンの提供の遅滞及び不履行をしてはならないものとします。</p></li>
          <li><p>プロジェクト実行者とサポーターの間でトラブル等が発生した場合であっても、当事者間で解決されるものとし、当社は責任を負わないものとします。ただし、当該トラブル等が当社のシステム上の不具合等に起因し、これにつき当社に故意又は重過失がある場合はこの限りではありません。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第8条（成約手数料の支払い）</div>
          <div class="pages_section_description">第７条第1項に基づきプロジェクトが成立した場合、プロジェクト実行者においては、当社に対してプロジェクト成立の成約手数料として、応援購入金の決定額（以下「応援購入額」といいます。）の20%に相当する金額を支払う義務が発生します。当該成約手数料は、当社からプロジェクト実行者へ支払われる応援購入額から差し引かれます。なお、振込手数料は当社負担となります。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第9条（サポーターのお支払いとプロジェクト実行者による応援購入額の受領）</div>
        </div>
        <ol class="numlists">
          <li><p>プロジェクト実行者は、会員間契約に基づきサポーターにより支払われるリターンの対価を、当社がプロジェクト実行者に代わって受領する権限を当社に付与するものとします。</p></li>
          <li><p>サポーターの応援購入金の支払い方法は、コンビニ払い、銀行振込、ネットバンク振込、クレジットカード払い等当社が別途定める方法によるものとし、当社の指定する決済代行会社を通じてお支払いいただきます。</p></li>
          <li>
            <p>会員間契約に基づくサポーターのプロジェクト実行者に対する支払い代金債務の弁済（以下「会員間契約の決済」といいます）が完了する時期は以下の時点となります。</p>
            <ol class="bracketslists">
              <li><p>プロジェクトがAll in型の場合<br>応援購入の完了時点</p></li>
              <li>
                <p>プロジェクトがAll or Nothing型の場合</p>
                <ol class="romanlists">
                  <li><p>クレジットカード払いの場合</p><p>クレジットカード会社の決済承認がなされた時点。ただし、プロジェクト成立後は応援購入の完了時点とします。</p></li>
                  <li><p>コンビニ払い、銀行振込、ネットバンク振込等のクレジットカード払い以外の支払方法を選択した場合</p><p>プロジェクトの成立時点。ただし、プロジェクトの成立後は応援購入の完了時点とします。</p></li>
                </ol>
              </li>
            </ol>
          </li>
          <li><p>応援購入額は、当社より、プロジェクト実行者に対し、前条に従い、成約手数料を差し引き、当社の指定する方法により支払われます（以下「出金」といいます）。</p></li>
          <li><p>出金の実施日については、原則として、成立したプロジェクトのプロジェクト期間終了日の属する月の翌々月の第3営業日（該当日が土日祝日の際は翌営業日）となります。ただし、当社が指定した期日までにプロジェクト実行者名義の正しい口座情報をいただけない場合は、当社指定の期日に実施日を変更することができるものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第10条（応援購入金の取扱い）</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、当社の社内規程等に従い、応援購入金を専用口座に当社の自己資金とは分別して管理したうえで、サポーターごとの入金額を帳簿により管理するものとします。</p></li>
          <li><p>応援購入金の支払いから会員間契約の決済完了までの間に、当社のほか銀行、収納代行会社、決済代行会社等が破綻し、又はその他の理由により取引履行することができなかった場合、会員間契約の決済が完了しない場合があります。この場合には、会員間契約は自動的にキャンセルされるものとし、プロジェクト実行者はサポーターにリターンを提供する義務を負わず、サポーターはプロジェクト実行者に応援購入金を支払う義務を負わないものとします。</p></li>
          <li><p>本利用規約に従い会員間契約の決済が完了した場合、プロジェクト実行者は、本利用規約に別に定める場合を除き、応援購入金の受領の有無を問わず、サポーターにリターンを提供する義務を負うものとします。ただし、サポーターが会員間契約のキャンセルにつき同意した場合はこの限りではありません。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第11条（キャンセル等の対応）</div>
        </div>
        <ol class="numlists">
          <li><p>All or Nothing型のプロジェクトが成立しなかった場合、プロジェクトが本利用規約の定めに従い中止された場合、又は会員間契約が会員間で取り消し若しくは解除となった場合、会員間契約は契約締結時にさかのぼってそれぞれ効力を喪失するものとします。プロジェクト実行者は、プロジェクトが中止された時点で既にサポーターによる応援購入金の支払いが行われている場合には、サポーターに対して、応援購入金相当額の返金手続をするものとします。この場合、当社は、返金手続が適切に行われるようにサポートしますが、返金手続が適切に行われることを保証するものではありません。当社がプロジェクト実行者のために応援購入金の返金手続のサポートを行い費用を負担した場合には、プロジェクト実行者に対して費用相当額（人件費を含みますがそれに限られません）を求償することができるものとします。</p></li>
          <li><p>前項に定める当社のサポートは、電子メール、又はプロジェクトの活動レポートのいずれか一つ又は複数の方法により行われるものとします。サポーターが当社からの最初の連絡に対して6ヶ月以内に返答を行わない場合、サポーターから応援購入金相当額を返還すべき利用可能な銀行口座等の届出がない場合、その他プロジェクト実行者によるサポーターへの返金が不可能な場合は、その金銭の処分は当社に委ねられたものとみなされます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第12条 (パートナー及びパートナーメディアとの連携)</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、パートナーと、パートナーの店舗への展示またはパートナーメディアと相互リンクすること等により、連携することができるものとします。</p></li>
          <li><p>パートナーメディアを経由して本サービスを知った会員は、パートナーメディアと本サービスが別のサービスであることを認識し、パートナーメディアの利用規約とは異なる本サービスに関する利用規約等に同意し、会員登録するものとします。</p></li>
          <li><p>本サービスを経由してパートナーメディアを知った会員は、パートナーメディアと本サービスが別のサービスであることを認識し、本サービスに関する利用規約とは異なるパートナーメディアの利用規約に同意した上で、パートナーメディアが定める方法により、パートナーメディアを利用するものとします。</p></li>
          <li><p>当社は、第2項及び第3項の会員に対してパートナーメディアから提供される情報及びパートナーメディアの利用に関して、一切責任を負わないものとします。</p></li>
          <li><p>プロジェクト実行者とパートナーの店舗を経由して本サービスを知った会員との間のトラブルについては、プロジェクト実行者が責任を負うものとし、当社及びパートナーは一切責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第13条（登録情報の変更と認証情報の管理)</div>
        </div>
        <ol class="numlists">
          <li><p>会員は自己の責任と費用負担によって、認証情報の管理を行うものとし、認証情報を第三者に利用させたり、貸与、譲渡、売買、質入、公開等をすることはできません。</p></li>
          <li><p>会員の認証情報の管理不十分による情報の漏洩、使用上の過誤、第三者の使用、不正アクセス等による損害の責任は会員が負うものとし、当社は一切責任を負わないものとします。万一、認証情報が不正に利用されたことにより当社に損害が生じた場合、会員は当該損害を賠償するものとします。</p></li>
          <li><p>会員は登録情報に変更が生じた場合、認証情報を第三者に知られた場合、または認証情報が第三者に使用されている疑いのある場合には、本利用規約及び当社が定める方法により、直ちに当社にその旨を届け出ると共に、当社の指示がある場合にはこれに従うものとします。</p></li>
          <li><p>当社は、会員の登録情報等に不備があり、会員への連絡がとれないなど、当社の会員に対する債務の履行不能状態が、当該債務の発生日より６ケ月間継続した場合、それに対応する債権を放棄したものとみなすことができるものとします。</p></li>
          <li><p>会員が第3項の届出をしなかったことにより、本サービスを利用できない等の不利益を被った場合、当社は一切の責任を負わないものとします。</p></li>
          <li><p>会員が第3項の届出をしなかった場合、当社は当該会員が退会したとみなすことができるものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第14条 (登録情報・個人情報等の取扱い)</div>
        </div>
        <ol class="numlists">
          <li>
            <p>当社は、氏名、電話番号、住所、メールアドレス、生年月日、性別、クレジットカード情報、金融機関の口座情報、プロジェクトの責任者やサポーターの履歴等の個人情報を含む、会員が当社に登録した登録情報について、以下の各号に定める目的で利用することができるものとします。</p>
            <ol class="bracketslists">
              <li><p>プロジェクトに関する応援購入、応援購入の勧誘、リターンの発送、サポーターの属性分析、本人確認等を含む本サービスの提供に必要な範囲での利用</p></li>
              <li><p>当社及びプロジェクトまたは第三者の販売促進活動(ダイレクトメールの送付、電子メールの送信を含みます。)</p></li>
              <li><p>本サービスの品質管理のためのアンケート調査、及び分析</p></li>
              <li><p>本サービスのアフターケア、問い合わせ対応</p></li>
              <li><p>本サービスの運営に関する事柄についての連絡、追加サービス等の情報提供</p></li>
              <li><p>本サービスにおけるシステムの維持、不具合対応</p></li>
            </ol>
          </li>
          <li>
            <p>サポーターは、本サービス上で会員間取引を承諾することにより、当社がそのプロジェクト実行者に対して、以下の通り情報を提供することに同意したものとして取り扱われます。</p>
            <ol class="bracketslists">
              <li>
                <p>提供する目的</p>
                <ul>
                  <li><p>プロジェクト実行者によるリターンの提供</p></li>
                  <li><p>リターンの品質管理のためのアンケート調査、及び分析</p></li>
                  <li><p>リターンのアフターケア、問い合わせ対応</p></li>
                </ul>
              </li>
              <li>
                <p>提供する情報</p>
                <ul>
                  <li>
                    <p>注文ID、購入日／注文登録日、ユーザーID、ユーザー名、氏名、生年（月日の提供はされません。）、性別、電話番号、リターンID、リターンタイトル、リターン内容、応援購入金額等</p>
                    <p>※ 配送予定のリターンがある場合は、上記に加えて、郵便番号、住所等が提供されます。</p>
                  </li>
                </ul>
              </li>
              <li>
                <p>提供時期及び方法</p>
                <ul>
                  <li><p>プロジェクト期間終了後にデータにて提供</p></li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <p>当社は、氏名、電話番号、住所、メールアドレス、生年月日、性別、クレジットカード情報、プロジェクトの購入履歴等の個人情報を含む、会員が当社に登録した登録情報について、次の各号の場合を除き、本人以外の第三者に開示しないものとし、かつ本サービスを提供していく上で必要な範囲を超えて利用しないものとします。</p>
            <ol class="bracketslists">
              <li><p>別に会員の同意が得られた場合</p></li>
              <li><p>前項に定める場合</p></li>
              <li><p>プロジェクトの応援購入、応援購入の勧誘、成果物の発送または本人確認等を含む本サービスの提供のために必要な場合</p></li>
              <li><p>当社及びプロジェクトまたは第三者の販売促進活動 (ダイレクトメールの送付、電子メールの送信を含みます。)</p></li>
              <li><p>本サービスの品質管理のためのアンケート調査、及び分析</p></li>
              <li><p>本サービスのアフターケア、問い合わせ対応</p></li>
              <li><p>代金の回収のために必要な場合(クレジットカード等を含む)</p></li>
              <li><p>本サービスに関して、当社の権利行使に必要な場合</p></li>
              <li><p>合併、営業譲渡その他の事由による事業の承継の際に、事業を承継する者に対して開示する場合</p></li>
              <li><p>個人情報保護法その他の法令により認められた場合</p></li>
            </ol>
          </li>
          <li><p>当社は、登録情報・個人情報について、当社のプライバシーポリシーに基づき、取り扱うものとします。</p></li>
          <li><p>会員は、当社が登録情報・個人情報を本条に定めるとおりに利用することについて、あらかじめ同意するものとします。</p></li>
          <li>
            <p>会員は、本サービスに登録した個人情報について、開示、削除、訂正または利用停止の請求ができるものとし、本人からの請求であることが確認できる場合に限り、当社はこれに速やかに対応するものとします。当社の本サービスにおける、本件問合せ窓口は以下の通りです。</p>
            <div class="support_info">
              <p>株式会社シカク</p>
              <p>support@kiryu.fund</p>
            </div>
          </li>
          <li><p>プロジェクト実行者は、第2項によりサポーターから同意を得て当社から提供された情報を利用するにあたり、第三者へ業務を委託する場合は、委託業務の遂行に必要な範囲内で、第三者にこれらの情報を開示することができます。この場合、プロジェクト実行者は、個人情報取扱事業者として、委託者による情報の取扱いを管理し、これが委託業務の範囲を超えて利用されないようにするものとします。</p></li>
          <li><p>本サービスを通じてプロジェクト実行者が取得したサポーターの情報について、漏えい、滅失または毀損、もしくはそのおそれがある事象（以下「漏えい等」といいます。）が生じた場合、プロジェクト実行者は直ちに当社に連絡した上で、漏えい等の事実をすみやかに個人情報保護委員会または認定個人情報保護団体に報告し、漏えい等による二次被害の拡大を防止するために、サポーターに対する通知または公表等を行わなければならないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第15条 (Cookie等及びIPアドレス情報）</div>
        </div>
        <ol class="numlists">
          <li>
            <p>当社は、以下各号の目的を達成するため、Cookie・広告識別番号及び利用者から取得したアクセスログ（IPアドレスを含み、以下「Cookie等」といいます）を利用することがあり、利用者はこれを承諾するものとします。</p>
            <ol class="numlists">
              <li><p>会員が登録情報の一部を入力することにより、全部を入力する手間を省くため</p></li>
              <li><p>利用者の動向を分析し、よりよいサービスを企画、提供するため</p></li>
            </ol>
          </li>
          <li><p>利用者は、その利用するパーソナルコンピュータやスマートフォン端末等における設定やブラウザの設定により、Cookieの受理を承諾するものとします。ブラウザの設定によりCookieの受理が拒否される場合、当社は利用者の情報を正しく認識できない可能性がありますが、それに起因して利用者に生ずる損害について、当社は一切の責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第16条(知的財産権等)</div>
        </div>
        <ol class="numlists">
          <li><p>本サービスを構成する素材(文字、写真、映像、音声等を指し、以下「コンテンツ素材」といいます)に関する一切の権利(所有権、知的財産権、肖像権、パブリシティー権等)は当社または当該権利を有する第三者（プロジェクト実行者等含むがこれに限られません）に帰属しています。</p></li>
          <li><p>プロジェクト実行者は、プロジェクト実行者により投稿されたプロジェクトの情報（文章、イラスト、写真等）については、本サービスを宣伝・広告・特集による紹介を目的として、当社が自由に利用することにつき予め了承するものとします。なお、投稿されたプロジェクトの情報であっても、イラスト、写真等について当社が素材を提供した場合は、当該素材に関する一切の権利は当社に帰属し、プロジェクト実行者は、当社の事前の承諾を得ることなく、本サービス内での利用以外できないものとします。</p></li>
          <li><p>会員は、コンテンツ素材について、一切の権利を取得することはないものとし、権利者の許可なく、所有権、著作権を含む一切の知的財産権、肖像権、パブリシティー権等、コンテンツ素材に関する全ての権利を侵害する一切の行為をしてはならないものとします。</p></li>
          <li><p>本サービス自体、ならびに、本サービスを利用するに当たって知り得た一切の秘密情報に関する権利(コンテンツ素材を除き、本サービスのプログラム、ノウハウを含む、所有権、知的財産権、肖像権、パブリシティー権等)は当社に帰属するものとし、当社の書面による承諾なく、自身及び第三者をして利用してはならないものとします。</p></li>
          <li><p>本条の規定に違反して問題が発生した場合、会員は、自己の費用と責任においてかかる問題を解決するとともに、当社に何等の迷惑または損害を与えないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第17条(禁止事項及び退会)</div>
        </div>
        <ol class="numlists">
          <li>
            <p>会員は、本サービスの利用にあたって、以下の行為またはそのおそれがある行為を行ってはならないものとします。</p>
            <ol class="bracketslists">
              <li><p>プロジェクト実行者ガイドラインに定める禁止された表現、ないしプロジェクトに関する説明文の掲載をすること(活動報告における画像、文章の投稿を含む)</p></li>
              <li><p>当社または第三者の所有権、著作権を含む一切の知的財産権、肖像権、パブリシティー権等の正当な権利を侵害する行為</p></li>
              <li><p>他の会員または当社もしくは第三者に不利益、損害を与える行為</p></li>
              <li><p>公序良俗に反する行為</p></li>
              <li><p>法令等又は本利用規約に違反する行為</p></li>
              <li><p>当社の承認がないにも関わらず、本サービスに関連して営利を目的とする行為</p></li>
              <li><p>本サービスの運営を妨害する行為</p></li>
              <li><p>本サービス外における商業目的で利用する行為</p></li>
              <li><p>本サービスの信用を失墜、毀損させる行為</p></li>
              <li><p>虚偽の情報を登録する行為</p></li>
              <li><p>不正に本サービスを利用する行為</p></li>
              <li><p>その他、当社が不適切と判断する行為</p></li>
            </ol>
          </li>
          <li>
            <p>会員は、本サービスの利用に当たって、次の各号に定める内容を含み、またはそのおそれのある表現・内容を含む投稿やメッセージの送信を行ってはならないものとします。尚、会員が本項各号に違反する内容を掲載・送信したものと当社が判断した場合には、直ちに本サービスの利用停止、投稿した内容の削除等の措置を取らせていただくことがあります。</p>
            <ol class="bracketslists">
              <li><p>本人の承諾のない個人情報（但し、一般に公開されている著名人などの情報は除く）</p></li>
              <li><p>アダルト画像、動画、その他アダルトサイト関連の内容（イラストや絵画等も含む）</p></li>
              <li><p>他人を誹謗・中傷もしくは侮辱する内容、他人の名誉や信用を傷つける内容</p></li>
              <li><p>法律に抵触ないし社会通念上、不適切と解釈され、またはその恐れのある表現・内容</p></li>
              <li><p>その他当社が不適切と判断する表現・内容</p></li>
            </ol>
          </li>
          <li>
            <p>会員が以下各号のいずれかに該当すると当社が判断した場合には、直ちに本サービスの利用停止、投稿した内容の削除等の措置を取らせていただくことがあります。</p>
            <ol class="bracketslists">
              <li><p>過去に本規約違反等により、当社から退会等の処分を受けている場合</p></li>
              <li><p>反社会的勢力であると判明した場合、又は、反社会的勢力等が経営に実質的に関与している法人の役員、従業員である等反社会的勢力等と何らかの関係を有していると判明した場合</p></li>
              <li><p>第4条第3項又は同条第４項各号に該当することが判明した場合</p></li>
            </ol>
          </li>
          <li><p>前三項に定める条項に抵触または該当することが発覚した場合において、当社は当該会員に対して、退会させることができるものとします。本条で定める禁止行為に抵触した場合において、当社が何らかの損害を被った場合、会員は当社に対して損害の賠償をしなければならないものとします。</p></li>
          <li><p>本条第1項、第2項、第3項の定めにかかわらず、会員は自らの意思で退会を希望する場合には、当社所定の手続きを経ることで退会することができます。</p></li>
          <li><p>退会した会員は、当社に対して負っている債務の一切について当然に期限の利益を失い、当社に対して、直ちに当該債務の全額の支払うものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第18条(本サービスの停止）</div>
        </div>
        <ol class="numlists">
          <li>
            <p>当社は、以下の事由に起因する場合、本サービスの全部または一部を停止することができ、当該事由に起因して会員または第三者に損害が発生した場合、一切の責任を負わないものとします。</p>
            <ol class="bracketslists">
              <li><p>定期的または緊急に本サービス提供のためのコンピューターシステムの保守・点検を行う場合</p></li>
              <li><p>火災・停電、天災地変等の非常事態により本サービスの運営が不能となった場合</p></li>
              <li><p>戦争、内乱、暴動、騒擾、労働争議等により、本サービスの運営が不能となった場合</p></li>
              <li><p>本サービス提供のためのコンピューターシステムの不良及び第三者からの不正アクセス、コンピューターウイルスの感染等により本サービスを提供できない場合</p></li>
              <li><p>法律、法令等に基づく措置により本サービスが提供できない場合</p></li>
              <li><p>その他、当社が止むを得ないと判断した場合</p></li>
            </ol>
          </li>
          <li><p>当社は、前項により本サービスを停止する場合、事前に電子メールまたは当社ウェブサイト（URL：https://kiryu.fund）に掲示する等適切な方法により会員及び第三者にその旨を通知するものとします。ただし、緊急の場合は、この限りではありません。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第19条(本サービスの変更・廃止)</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、本サービスの内容や機能・利用料金等を変更し、追加し、削除することができるものとします。</p></li>
          <li><p>当社は、当社の判断によっていつでも本サービスを廃止できるものとします。</p></li>
          <li><p>当社は、本サービスに関わる重要な機能・利用料金等を変更・廃止し、または、本サービスを廃止する場合、事前に当社ウェブサイト（URL：https://kiryu.fund）に掲示する等適切な方法により、会員にその旨を周知するものとします。</p></li>
          <li><p>当社は、本条に基づく本サービスの廃止又は変更によって生じた損害について、一切の責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第20条（注意事項）</div>
          <div class="pages_section_description">会員間契約は、あくまでプロジェクト実行者とサポーターとの間において成立するものであり、当社は、会員間契約に基づくプロジェクト実行者又はサポーターの義務（履行期限が遵守されることを含みますがこれに限りません。）が履行されることを保証するものでありません。また、当社は、本サービス又は本サービスにおいて発表・掲載されたプロジェクトについて、以下の事項を含む一切の事項について何らの表明及び保証も行いません。</div>
        </div>
        <ol class="bracketslists">
          <li><p>プロジェクト実行者により提供されるリターンが、サポーターにより期待される性質、品質、財産的価値その他の性能を備えていること。</p></li>
          <li><p>プロジェクトに関して本サイト上に記載されている情報が、最新のものであること、正確かつ真実に適合するものであること。</p></li>
          <li><p>プロジェクトの実施及びリターンの提供が、実行者（及び実行者と共同してプロジェクトを実施する個人又は法人）又はサポーターについて適用のある法令又は規則に抵触しないこと又は公序良俗に反しないこと。</p></li>
          <li><p>プロジェクトが本サイトに記載された内容及び手順において実施されること。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第21条(免責)</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、本サービスに伴う情報の提供に関して発生あるいは誘発された損害、情報の利用により得た成果、または、その情報自体の合法性や道徳性、権利の許諾、正確さ、プロジェクト実行者とサポーター間における会員間契約を含む全ての契約について、責任を負わないものとします。</p></li>
          <li><p>当社は、本サービス提供のためのコンピューターシステムの障害等による電子メールの遅配、未配、ファンディング桐生上の誤表示(価格・数量の誤表示等含む)及びそれ以外のいかなる原因に基づき生じた損害について、一切の責任又は義務を負わないものとします。</p></li>
          <li><p>当社は、会員が使用するコンピューター、回線、ソフトウェア等の環境等に基づき生じた損害について、賠償する義務を一切負わないものとします。なお、当社は別途定める方法により、会員に対して当該環境等について告知することがあります。</p></li>
          <li><p>当社は、会員のインターネット接続環境について、海外からのアクセスを一部制限しております。本サービスサイトは日本国内からのアクセスを対象にして提供しておりますので、予めインターネットが接続可能であることを確認の上ご利用ください。また、本サービスにおける取引の途中で、会員の一方が海外に渡航し、アクセスができなくなるなど、本サービスサイトの利用が妨げられたり、会員間契約の不成立や会員同士のトラブルが生じた場合でも、当社では一切責任を負わないものとします。</p></li>
          <li><p>当社は、本サービスの停止または中止、サービス内容の変更によって受ける損害について、賠償する義務を一切負わないものとします。ただし、本サービスが消費者契約法等の定める「消費者契約」に該当する場合において、利用者に発生した損害が当社の債務不履行または不法行為に因る場合、当社は利用者が直接被った通常の損害の範囲で、損害賠償責任を負うものとします。</p></li>
          <li><p>当社は、本サービス及びパートナーメディアならびに広告主を含む第三者の本サービスサイトからのダウンロードやコンピューターウイルス感染等により発生した、コンピューター、回線、ソフトウェア等の損害について、賠償する義務を一切負わないものとします。</p></li>
          <li><p>当社は、本サービスを通じて行われた第三者と会員との取引について、一切の責任を負わないものとし、全ての取引は当該第三者と会員の責任においてなされるものとします。</p></li>
          <li><p>当社は、本サービスに関し、遅滞、変更、停止、中止、廃止、及び本サービスを通じて提供される情報等の消失、その他本サービスに関連して発生した損害について、一切の責任を負わないものとします。</p></li>
          <li><p>当社は、当社が提供するサービスに於いて、会員及び/または第三者間で生じたトラブル（違法または公序良俗に反する行為の提案、名誉毀損、侮辱、プライバシー侵害、脅迫、誹謗中傷、いやがらせ等）に関して、一切の責任を負わないものとします。</p></li>
          <li><p>当社の故意または重大な過失に起因して生じた損害については、本条各項の限りではありません。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第22条(本利用規約の変更)</div>
        </div>
        <ol class="numlists">
          <li>
            <p>当社は以下の各号のいずれかの場合に、当社の裁量により、本利用規約を変更することができます。</p>
            <ol class="bracketslists">
              <li><p>本利用規約の変更が、会員の一般の利益に適合するとき。</p></li>
              <li><p>本利用規約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。</p></li>
            </ol>
          </li>
          <li><p>当社は前項による本利用規約の変更にあたり、事前に利用規約を変更する旨及び変更後の利用規約の内容とその効力発生日を当社ウェブサイト（URL：https://kiryu.fund）に掲示する等の方法により、会員に周知します。</p></li>
          <li><p>変更後の本利用規約の効力発生日以降に会員が本サービスを利用したときは、会員は、本利用規約の変更に同意したものとみなします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第23条（分離可能性）</div>
          <div class="pages_section_description">本利用規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本利用規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第24条(協議・管轄裁判所)</div>
        </div>
        <ol class="numlists">
          <li><p>本サービスに関連して会員、当社、第三者との間で疑義、問題が生じた場合、都度誠意をもって協議し、解決を図るものとします。</p></li>
          <li><p>前項の協議によっても疑義、問題が解決しない場合、東京簡易裁判所または東京地方裁判所を第一審の専属的合意管轄裁判所とするものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第25条(準拠法)</div>
          <div class="pages_section_description">本利用規約は日本国法に準じて解釈されるものとします。</div>
        </div>
      </section>

      <!-- 「ECサービス」利用に関する特約 -->

      <div class="pages_top">
        <p class="pages_top_title">「ECサービス」利用に関する特約（購入者様用）</p>
        <p>本特約は、当社が運営する「ファンディング桐生」を通じて提供されるサービス「ECサービス」において、購入者の利用条件を定めるものです（以下、「本EC（購入者用）特約」といいます）。</p>
        <p>ECサービスの利用にあたっては、ファンディング桐生利用規約（以下「原規約」といいます）に加えて、本EC（購入者用）特約のすべてに同意いただく必要があります。また、ECサービスの利用により、会員は、本EC（購入者用）特約及び会員が利用するサービスごとに定められたガイドラインや注意事項に同意したものとみなされます。</p>
      </div>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第1条 (定義)</div>
          <div class="pages_section_description">本EC（購入者用）特約において使用する用語の定義は、原規約に定義されるもののほか、以下の通りとします。</div>
        </div>
        <ol class="bracketslists">
          <li><p>「ECサービス」‑原規約第1条第3号に基づく、「ファンディング桐生」を通じて提供される本サービスのうち、会員が販売したい商品・サービス等（以下、「商品等」といいます）を他の会員に対して電子商取引にて販売することを可能にするECサイト運営サービスであり、会員間での交流や電子商取引の場や機会を提供するサービスをいいます。</p></li>
          <li><p>「販売者」‑販売者登録を行った後、ECサービスを利用して、会員に対して、商品等の販売を行う会員をいいます。</p></li>
          <li><p>「購入者」‑ ECサービスにて販売される商品等の購入を行う会員をいいます。</p></li>
          <li><p>「顧客」‑ ECサービスにて販売される商品等につき、購入の有無にかかわらず、購入を希望する会員をいいます。</p></li>
          <li><p>「EC会員間契約」‑購入者による商品等の購入及び販売者による商品の提供に関する電子商取引に関する契約をいいます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第2条 (ECサービスと当社の役割)</div>
        </div>
        <ol class="numlists">
          <li><p>EC会員間契約は、販売者と購入者の間において成立するものであり、当社は、その契約の履行には一切関与いたしません。また、EC会員間契約に関する販売者と購入者以外の会員又は第三者（以下、「関連当事者」といいます）がEC会員間契約に基づき何らかの権利を持ち又は義務を負うか否か、EC会員間契約又は関連当事者の権利義務の消滅や返金、補償等の取扱いについても、当社は一切関与いたしません。</p></li>
          <li><p>会員間又は関連当事者との間においてトラブル等が発生した場合についても、当社が別途定める場合を除き、当社が紛争の解決のためのあっせん、調停、仲裁その他の紛争解決に向けた措置を講じることはありませんので、ECサービスの利用に際しては十分に注意し、予めご了承の上ご利用ください。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第3条 (決済手段の提供)</div>
          <div class="pages_section_description">販売者がクレジットカード決済を利用する場合において、販売者と購入者が共謀している等当社が当該会員の取引を不当と判断した場合には、当社は、支払いの拒否、会員資格の停止、除名等の措置をとる場合があるものとし、会員は予めこれに同意するものとします。この場合、当該会員は当該取引が不当でないことを示す資料を当社が認める内容で提出しない限り、本条に定める支払いを受けることができないものとします。当社は、本項に定める措置により会員に生じた損害には一切責任を負いません。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第4条 (手続きの成立)</div>
          <div class="pages_section_description">会員がインターネット回線を通じて行った登録・商品の販売、購入者の購入または販売価格の決定・退会・評価の記入・会員同士の連絡・その他の手続は、 当社のサーバーに当該手続に関するデータが送信され、当社のシステムに当該手続の内容が反映された時点をもって有効に成立するものとします。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第5条 (購入者のお支払いと販売者による代金の受領)</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、EC会員間契約に基づく販売代金について、販売者を代行して購入者からの支払を受領します。</p></li>
          <li><p>購入者の販売代金の支払い方法は、コンビニ払い、銀行振込、ネットバンク振込、クレジットカード払い等当社が別途定める方法によるものとし、当社の指定する決済代行会社を通じてお支払いいただきます。</p></li>
          <li><p>EC会員間契約に基づく購入者の販売者に対する支払い代金債務の弁済が完了する時期は、決済代行会社の決済承認がなされた時点となります。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第6条（キャンセル等の対応）</div>
        </div>
        <ol class="numlists">
          <li><p>販売者と購入者間での商品等の購入に関するEC会員間契約が解除、取消し、無効等の理由により効力を失った場合、販売者は、速やかに当該購入に関する販売代金を、購入者に対して返金しなければなりません。この場合、当社は、返金手続が適切に行われるようにサポートしますが、返金手続が適切に行われることを保証するものではありません。</p></li>
          <li><p>前項に定める当社のサポートは、電子メール、又はプロジェクトの活動レポートのいずれか一つ又は複数の方法により行われるものとします。購入者が当社からの最初の連絡に対して6ヶ月以内に返答を行わない場合、購入者から販売代金相当額を返還すべき利用可能な銀行口座等の届出がない場合、その他販売者による購入者への返金が不可能な場合は、その金銭の処分は当社に委ねられたものとみなされます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第7条（ECサービスの変更・廃止）</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、ECサービスの内容や機能・利用料金等を変更し、追加し、削除することができるものとします。</p></li>
          <li><p>当社は、当社の判断によっていつでもECサービスを廃止できるものとします。</p></li>
          <li><p>当社は、ECサービスに関わる重要な機能・利用料金等を変更・廃止し、又は、ECサービスを廃止する場合、事前に当社ウェブサイト（URL:https://kiryu.fund）に掲示する等適切な方法により、会員にその旨を周知するものとします。</p></li>
          <li><p>当社は、本条に基づくECサービスの廃止又変更によって生じた損害について、一切の責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第8条 (本EC（購入者用）特約の変更)</div>
        </div>
        <ol class="numlists">
          <li>
            <p>当社は以下の各号のいずれかの場合に、当社の裁量により、本EC（購入者用）特約を変更することができます。</p>
            <ol class="bracketslists">
              <li><p>本EC（購入者用）特約の変更が、会員の一般の利益に適合するとき。</p></li>
              <li><p>本EC（購入者用）特約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。</p></li>
            </ol>
          </li>
          <li><p>当社は前項による本EC（購入者用）特約の変更にあたり、事前に本EC（購入者用）特約を変更する旨及び変更後の本EC（購入者用）特約の内容とその効力発生日を当社ウェブサイト（URL：https://kiryu.fund）に掲示する等の方法により、会員に周知します。</p></li>
          <li><p>変更後の本EC（購入者用）特約の効力発生日以降に会員がECサービスを利用したときは、会員は、本EC（購入者用）特約の変更に同意したものとみなします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第9条 (適用)</div>
          <div class="pages_section_description">本EC（購入者用）特約に定めのない事項は、原規約等の定めに従うものとし、原規約等における「プロジェクト実行者」は「販売者」、「支援者」は「購入者」、「リターン」は「商品等」に読み替えて準用するものとします。</div>
        </div>
      </section>

      <!-- ファンディング桐生ガバメントに関する特約 -->

      <div class="pages_top">
        <p class="pages_top_title">ファンディング桐生ガバメントに関する特約（地方自治体様及び寄附者様用）</p>
        <p>本特約は、当社が運営する「ファンディング桐生」を通じて提供されるサービス「ファンディング桐生ガバメント」の利用条件を定めるものです（以下「本ガバメント特約」といいます）。</p>
        <p>ファンディング桐生ガバメントの利用にあたっては、ファンディング桐生利用規約（以下「原規約」といいます）に加えて、本ガバメント特約のすべてに同意いただく必要があります。また、ファンディング桐生ガバメントの利用により、会員は、本ガバメント特約及び会員が利用するサービスごとに定められたガイドラインや注意事項に同意したものとみなされます。</p>
      </div>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第1条(定義)</div>
          <div class="pages_section_description">本ガバメント特約において使用する用語の定義は、原規約に定義されるもののほか、以下の通りとします。</div>
        </div>
        <ol class="bracketslists">
          <li><p>「ファンディング桐生ガバメント」-原規約第1条第3項に基づく、「ファンディング桐生」を通じて提供される本サービスのうち、地方公共団体へ寄附することを可能にするクラウドファンディングサイト運営サービスであり、会員間での交流や寄附の場や機会を提供するサービスをいいます。</p></li>
          <li><p>「寄附先」- 会員のうちファンディング桐生ガバメントを利用して、プロジェクトを通して寄附金を募り、寄附者に対して返礼品の提供を行う、地方公共団体の会員をいいます。</p></li>
          <li><p>「寄附者」-会員のうちプロジェクトに共感をし、 ファンディング桐生ガバメントにて、寄附先へ寄附の申込を完了した個人または法人の会員をいいます。</p></li>
          <li><p>「返礼品」-とは、プロジェクトが成立した場合において、寄附先が寄附者へ提供するプロジェクトの成果物（製品のほか、サービスを受ける権利等を含みます）などの寄附と対価性を有しない謝礼をいいます。なお、プロジェクトページにおいては返礼品のことを「リターン」という場合があります。</p></li>
          <li><p>「顧客」- ファンディング桐生ガバメントにおいて、寄附の有無にかかわらず、寄附を希望する会員をいいます。</p></li>
          <li><p>「寄附決定代金」- ファンディング桐生ガバメントを利用して集まったプロジェクトごとの寄附金の総額をいいます。</p></li>
          <li><p>「寄附の申込」-寄附者が、当社所定の手続に従い、プロジェクトに対する寄附の申込を確定させることをいいます。</p></li>
          <li><p>「寄附の完了」-寄附者が、寄附金の支払方法として、クレジットカード払いを選択した場合には、寄附の申込をした時点をいいます。寄附者が、コンビニ払い、銀行振込、ネットバンク振込等のクレジットカード払い以外の支払方法を選択した場合には、当社の指定する決済代行会社の決済承認がなされた時点（ただし、当社の定める期日までに入金することを条件とします）をいいます。</p></li>
          <li><p>「ガバメント会員間契約」-寄附者によるプロジェクトの寄附及び寄附先による返礼品の提供に関する契約をいいます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第2条(ファンディング桐生ガバメントにおける当社の役割)</div>
        </div>
        <ol class="numlists">
          <li><p>ガバメント会員間契約は、寄附者と寄附先の間において成立するものであり、当社は、その契約の履行には一切関与いたしません。また、プロジェクトに関与する寄附者と寄附先以外の会員又は第三者（以下「関連当事者」といいます）がガバメント会員間契約に基づき何らかの権利を持ち又は義務を負うか否か、ガバメント会員間契約又は関連当事者の権利義務の消滅や返金、補償等の取り扱いについても、当社は一切関与いたしません。</p></li>
          <li><p>顧客、寄附者及び寄附先の間で、返礼品の不着、到着遅延、瑕疵その他の紛争が生じた場合、または第三者との間で著作権、商標権等の知的財産権もしくは人格権等に関する紛争が生じた場合には、すべて会員の責任と負担において解決するものとします。</p></li>
          <li><p>会員間又は関連当事者との間においてトラブル等が発生した場合についても、当社が別途定めるケースを除き、当社が紛争の解決のためのあっせん、調停、仲裁その他の紛争解決に向けた措置を講じることはありませんので、取引に際しては十分に注意し、予めご了承の上ご利用ください。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第3条（プロジェクト成立）</div>
        </div>
        <ol class="numlists">
          <li><p>ファンディング桐生ガバメントにおいてプロジェクトは全てAll in型となります。そのため、プロジェクト期間が終了するまでに寄附先が設定した目標金額を達成したか否かにかかわらず、いずれかの寄附者により寄附の申込みがなされた時点でプロジェクトは成立し、寄附者及び寄附先間でガバメント会員間契約が成立します。</p></li>
          <li><p>寄附者による寄附の完了後のキャンセル及び寄附先によるプロジェクトの中止は、原規約及び本ガバメント特約の規定に従ってのみ行われるものとし、それぞれ、任意のキャンセル又は任意の中止はできないものとします。</p></li>
          <li><p>寄附者によるガバメント会員間契約にもとづく金銭の支払いは、当社の定める方法により行うものとします。なお、当社が指定する期日内に寄附者が支払手続きを完了しなかった場合には、原規約にあるとおり、寄附先が個別にガバメント会員間契約の成立を承諾した場合を除いて、ガバメント会員間契約は成立しなかったものとして取り扱われますのでご注意ください。</p></li>
          <li><p>寄附先は、寄附者による寄附の完了後各プロジェクトのページに定める期日までに寄附者に対して返礼品を提供する義務を負います。寄附先は、プロジェクトが目標金額を達成していないこと等を理由として、返礼品の提供を拒んだり、返礼品提供の期日を遅延することのないようにしてください。</p></li>
          <li><p>寄附先と寄附者の間で返礼品に関して何らかの問題が発生した場合、会員間等で解決し、当社は責任を負わないものとします。ただし、システム上の不具合などの決済に関する問題に起因し、これにつき当社に故意又は重大な過失がある場合はこの限りではありません。</p></li>
          <li><p>寄附先は、寄附者から請求があった場合は、寄附者に対する寄附証明書の発行等寄附者における税務手続きのために必要な対応を行うものとし、寄附先が当該対応を怠ったことにより寄附者に損害が生じた場合でも、当社は、一切の責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第4条（税務手続き）</div>
          <div class="pages_section_description">寄附者は、寄附に関連して税控除を受ける場合はかかる手続きを自己の責任で行うものとし、当社はかかる手続きに関しいかなる責任も負わないものとします。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第5条(決済手段の提供)</div>
          <div class="pages_section_description">寄附先がクレジットカード決済を利用する場合において、寄附先と寄附者が共謀している等当社が当該会員の取引を不当と判断した場合には、当社は、支払いの拒否、会員資格の停止、除名等の措置をとる場合があるものとし、会員は予めこれに同意するものとします。この場合、当該会員は当該取引が不当でないことを示す資料を当社が認める内容で提出しない限り、本条に定める支払いを受けることができないものとします。当社は、本項に定める措置により会員に生じた損害には一切責任を負いません。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第6条(手続きの成立)</div>
          <div class="pages_section_description">会員がインターネット回線を通じて行った登録、寄附者の寄附または寄附価格の決定・退会・評価の記入・会員同士の連絡・その他の手続は、 当社のサーバーに当該手続に関するデータが送信され、当社のシステムに当該手続の内容が反映された時点をもって有効に成立するものとします。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第7条（代金の支払い）</div>
          <div class="pages_section_description">プロジェクトが成立した場合、寄附先においては、当社に対して、プロジェクト寄附の成約手数料として、寄附決定額（以下「寄附額」といいます）の20％に相当する金額を支払う義務が発生します。当該成約手数料は、当社から寄附先へ支払われる寄附額から差し引かれます。なお、振込手数料は当社負担となります。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第8条(寄附者のお支払いと寄附先による代金の受領)</div>
        </div>
        <ol class="numlists">
          <li><p>寄附先は、ガバメント会員間契約に基づき寄附者により支払われる寄附金を、当社が寄附先に代わって受領する権限を当社に付与するものとします。</p></li>
          <li><p>寄附者の寄附金の支払い方法は、コンビニ払い、銀行振込、ネットバンク振込、クレジットカード払い等当社が別途定める方法によるものとし、当社の指定する決済代行会社を通じてお支払いいただきます。</p></li>
          <li><p>ガバメント会員間契約に基づく寄附者の寄附先に対する支払い代金債務の弁済（以下「ガバメント会員間契約の決済」といいます）が完了する時期は、寄附の完了時点となります。</p></li>
          <li><p>寄附額は、当社より、寄附先に対し、前条に従い、成約手数料を差し引き、当社の指定する方法により支払われます（以下「出金」といいます）。</p></li>
          <li><p>出金の実施日については、原則として、成立したプロジェクトのプロジェクト期間終了日の属する月の翌々月の第3営業日（該当日が土日祝日の際は翌営業日）となります。ただし、当社が指定した期日までに寄附先名義の正しい口座情報をいただけない場合は、当社指定の期日に実施日を変更することができるものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第9条（寄附金の取扱い）</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、当社の社内規程等に従い、寄附金を専用口座に当社の自己資金とは分別して管理したうえで、寄附者ごとの入金額を帳簿により管理するものとします。</p></li>
          <li><p>原規約及び本ガバメント特約に従いガバメント会員間契約の決済が完了した場合、寄附先は、原規約または本ガバメント特約に別に定める場合を除き、寄附金の受領の有無を問わず、寄附者に返礼品を提供する義務を負うものとします。ただし、寄附者がガバメント会員間契約のキャンセルにつき同意した場合はこの限りではありません。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第10条（キャンセル等の対応）</div>
        </div>
        <ol class="numlists">
          <li><p>プロジェクトが原規約又は本ガバメント特約の定めに従い中止された場合、又はガバメント会員間契約が会員間で取り消し若しくは解除となった場合、ガバメント会員間契約は契約締結時にさかのぼって効力を喪失するものとします。寄附先は、プロジェクトが中止された時点で既に寄附者による寄附金の支払いが行われている場合には、寄附者に対して、寄附金相当額の返金手続をするものとします。この場合、当社は、返金手続が適切に行われるようにサポートしますが、返金手続が適切に行われることを保証するものではありません。当社が寄附先のために寄附金の返金手続のサポートを行い費用を負担した場合には、寄附先に対して費用相当額（人件費を含みますがそれに限られません）を求償することができるものとします。</p></li>
          <li><p>前項に定める当社のサポートは、電子メール、又はプロジェクトの活動レポートのいずれか一つ又は複数の方法により行われるものとします。寄附者が当社からの最初の連絡に対して6ヶ月以内に返答を行わない場合、寄附者から寄附金相当額を返還すべき利用可能な銀行口座等の届出がない場合、その他寄附先による寄附者への返金が不可能な場合は、その金銭の処分は当社に委ねられたものとみなされます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第11条(本サービスの変更・廃止)</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、ファンディング桐生ガバメントの内容や機能・利用料金等を変更し、追加し、削除することができるものとします。</p></li>
          <li><p>当社は、当社の判断によっていつでもファンディング桐生ガバメントを廃止できるものとします。</p></li>
          <li><p>当社は、ファンディング桐生ガバメントに関わる重要な機能・利用料金等を変更・廃止し、または、本サービスを廃止する場合、事前に当社ウェブサイト（URL：https://kiryu.fund）に掲示する等適切な方法により、会員にその旨を周知するものとします。</p></li>
          <li><p>当社は、本条に基づくファンディング桐生ガバメントの廃止又は変更によって生じた損害について、一切の責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第12条(原規約の変更)</div>
        </div>
        <ol class="numlists">
          <li>
            <p>当社は以下の各号のいずれかの場合に、当社の裁量により、本ガバメント特約を変更することができます。</p>
            <ol class="bracketslist">
              <li><p>本ガバメント特約の変更が、会員の一般の利益に適合するとき。</p></li>
              <li><p>本ガバメント特約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。</p></li>
            </ol>
          </li>
          <li><p>当社は前項による本ガバメント特約の変更にあたり、事前に本ガバメント特約を変更する旨及び変更後のガバメント特約の内容とその効力発生日を当社ウェブサイト（URL：https://kiryu.fund）に掲示する等の方法により、会員に周知します。</p></li>
          <li><p>変更後の本ガバメント特約の効力発生日以降に会員がファンディング桐生ガバメントを利用したときは、会員は、本ガバメント特約の変更に同意したものとみなします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第13条(適用)</div>
          <div class="pages_section_description">本ガバメント特約は、原規約に優先して適用されるものとします。また、本ガバメント特約に定めのない事項は、原規約の定めに従うものとし、原規約における「プロジェクト実行者」は「寄附先」、「支援者」は「寄附者」に、「リターン」は「返礼品」、「支援金」は「寄附金」とそれぞれ読み替えて準用するものとします。</div>
        </div>
      </section>

      <!-- Mファンディング桐生（寄附型）に関する特約 -->

      <div class="pages_top">
        <p class="pages_top_title">ファンディング桐生（寄附型）に関する特約（公益法人等様及び寄附者様用）</p>
        <p>本特約は、当社が運営する「ファンディング桐生」を通じて提供されるサービス「ファンディング桐生（寄附型）」の利用条件を定めるものです（以下「本寄附型特約」といいます）。</p>
        <p>ファンディング桐生（寄附型）の利用にあたっては、ファンディング桐生利用規約（以下「原規約」といいます）に加えて、本寄附型特約のすべてに同意いただく必要があります。また、ファンディング桐生（寄附型）の利用により、会員は、本寄附型特約及び会員が利用するサービスごとに定められたガイドラインや注意事項に同意したものとみなされます。</p>
      </div>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第1条(定義)</div>
          <div class="pages_section_description">本寄附型特約において使用する用語の定義は、原規約に定義されるもののほか、以下の通りとします。</div>
        </div>
        <ol class="bracketslists">
          <li><p>ファンディング桐生(寄附型)」-原規約第1条第3項に基づく、「ファンディング桐生」を通じて提供される本サービスのうち、認定特定非営利活動法人、特例認定特定非営利活動法人、公益社団法人または公益財団法人（以下、「公益法人等」と総称する）へ寄附することを可能にするクラウドファンディングサイト運営サービスであり、会員間での交流や寄附の場や機会を提供するサービスをいいます。なお、公益法人等に該当しない、特定非営利活動法人、一般社団法人、一般財団法人、その他法人及び個人は本サービスにおける寄附先となることはできないものとします。</p></li>
          <li><p>「寄附先」-ファンディング桐生(寄附型)を利用して、プロジェクトを通して寄附金を募り、寄附者に対して返礼品の提供を行う、公益法人等の会員をいいます。</p></li>
          <li><p>「寄附者」-プロジェクトに共感し、ファンディング桐生(寄附型)にて、寄附先へ寄附の申込を完了した個人または法人の会員をいいます。</p></li>
          <li><p>「返礼品」-とは、プロジェクトが成立した場合において、寄附先が寄附者へ提供するプロジェクトの成果物（製品のほか、サービスを受ける権利等を含みます）などの寄附と対価性を有しない謝礼をいいます。なお、プロジェクトページにおいては返礼品のことを「リターン」という場合があります。</p></li>
          <li><p>「顧客」- ファンディング桐生(寄附型)において、プロジェクトに対する寄附を希望する会員をいいます（寄附の申込みの有無を問いません）。</p></li>
          <li><p>「寄附の申込」-寄附者が、当社所定の手続に従い、プロジェクトに対する寄附の申込を確定させることをいいます。</p></li>
          <li><p>「寄附の完了」-寄附者が、寄附金の支払方法として、クレジットカード払いを選択した場合には、寄附の申込をした時点をいいます。寄附者が、コンビニ払い、銀行振込、ネットバンク振込等のクレジットカード払い以外の支払方法を選択した場合には、当社の指定する決済代行会社の決済承認がなされた時点（ただし、当社の定める期日までに入金することを条件とします）をいいます。</p></li>
          <li><p>「寄附型会員間契約」-寄附者によるプロジェクトの寄附及び寄附先による返礼品の提供に関する契約をいいます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第2条(ファンディング桐生(寄附型)における当社の役割)</div>
          <div class="pages_section_description"></div>
        </div>
        <ol class="numlists">
          <li><p>寄附型会員間契約は、寄附者と寄附先の間において成立するものであり、当社は、その契約の履行には一切関与いたしません。また、プロジェクトに関与する寄附者と寄附先以外の会員又は第三者（以下「関連当事者」といいます）が寄附型会員間契約に基づき何らかの権利を持ち又は義務を負うか否か、寄附型会員間契約又は関連当事者の権利義務の消滅や返金、補償等の取り扱いについても、当社は一切関与いたしません。</p></li>
          <li><p>顧客、寄附者及び寄附先の間で、返礼品の不着、到着遅延、瑕疵その他の紛争が生じた場合、または第三者との間で著作権、商標権等の知的財産権もしくは人格権等に関する紛争が生じた場合には、すべて会員の責任と負担において解決するものとします。</p></li>
          <li><p>会員間又は関連当事者との間においてトラブル等が発生した場合についても、当社が別途定めるケースを除き、当社が紛争の解決のためのあっせん、調停、仲裁その他の紛争解決に向けた措置を講じることはありませんので、取引に際しては十分に注意し、予めご了承の上ご利用ください。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第3条（プロジェクト成立）</div>
        </div>
        <ol class="numlists">
          <li><p>ファンディング桐生(寄附型)においてプロジェクトは全てAll In型となります。そのため、プロジェクト期間が終了するまでに寄附先が設定した目標金額を達成したか否かにかかわらず、いずれかの寄附者により寄附の申込みがなされた時点でプロジェクトは成立し、寄附者及び寄附先間で寄附型会員間契約が成立します。</p></li>
          <li><p>寄附者による寄附の完了後のキャンセル及び寄附先によるプロジェクトの中止は、原規約及び本寄附型特約の規定に従ってのみ行われるものとし、それぞれ、任意のキャンセル又は任意の中止はできないものとします。</p></li>
          <li><p>寄附者による寄附型会員間契約にもとづく金銭の支払いは、当社の定める方法により行うものとします。なお、当社が指定する期日内に寄附者が支払手続きを完了しなかった場合には、原規約にあるとおり、寄附先が個別に寄附型会員間契約の成立を承諾した場合を除いて、寄附型会員間契約は成立しなかったものとして取り扱われますのでご注意ください。</p></li>
          <li><p>寄附先は、寄附者による寄附の完了後各プロジェクトのページに定める期日までに寄附者に対して返礼品を提供する義務を負います。寄附先は、プロジェクトが目標金額を達成していないこと等を理由として、返礼品の提供を拒んだり、返礼品提供の期日を遅延することのないようにしてください。</p></li>
          <li><p>寄附先と寄附者の間で返礼品に関して何らかの問題が発生した場合、会員間等で解決し、当社は責任を負わないものとします。ただし、システム上の不具合などの決済に関する問題に起因し、これにつき当社に故意又は重大な過失がある場合はこの限りではありません。</p></li>
          <li><p>寄附先は、寄附者から請求があった場合は、寄附者に対する寄附証明書の発行等寄附者における税務手続きのために必要な対応を行うものとし、寄附先が当該対応を怠ったことにより寄附者に損害が生じた場合でも、当社は、一切の責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第4条（税務手続き）</div>
          <div class="pages_section_description">寄附者及び寄附先は、寄附に関連する税務手続きを自己の責任で行うものとし、当社はかかる手続きに関していかなる責任も負わないものとします。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第5条(決済手段の提供)</div>
          <div class="pages_section_description">寄附先がクレジットカード決済を利用する場合において、寄附先と寄附者が共謀している等当社が当該会員の取引を不当と判断した場合には、当社は、支払いの拒否、会員資格の停止、除名等の措置をとる場合があるものとし、会員は予めこれに同意するものとします。この場合、当該会員は当該取引が不当でないことを示す資料を当社が認める内容で提出しない限り、本条に定める支払いを受けることができないものとします。当社は、本項に定める措置により会員に生じた損害には一切責任を負いません。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第6条(手続きの成立)</div>
          <div class="pages_section_description">会員がインターネット回線を通じて行った登録、寄附者の寄附または寄附価格の決定・退会・評価の記入・会員同士の連絡・その他の手続は、当社のサーバーに当該手続に関するデータが送信され、当社のシステムに当該手続の内容が反映された時点をもって有効に成立するものとします。</div>
        </div>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第7条（手数料の支払い）</div>
        </div>
        <ol class="numlists">
          <li><p>プロジェクトが成立した場合、寄附先においては、当社に対して、プロジェクト寄附の成約手数料として、寄附決定額（以下「寄附額」といいます）の20％に相当する金額を支払う義務が発生します。</p></li>
          <li><p>寄附先は、当社が別途寄附先に送付する請求書の記載に従って、当社に対して成約手数料を支払うものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第8条(寄附者のお支払いと寄附先による代金の受領)</div>
        </div>
        <ol class="numlists">
          <li><p>寄附先は、寄附型会員間契約に基づき寄附者により支払われる寄附金を、当社が寄附先に代わって受領する権限を当社に付与するものとします。</p></li>
          <li><p>寄附者の寄附金の支払い方法は、コンビニ払い、銀行振込、ネットバンク振込、クレジットカード払い等当社が別途定める方法によるものとし、当社の指定する決済代行会社を通じてお支払いいただきます。</p></li>
          <li><p>寄附型会員間契約に基づく寄附者の寄附先に対する支払い代金債務の弁済（以下「寄附型会員間契約の決済」といいます）が完了する時期は、寄附の完了時点となります。</p></li>
          <li><p>寄附額は、当社より、寄附先に対して、決済代行会社を通じて支払われます（以下「出金」といいます）。</p></li>
          <li><p>出金の実施日については、寄附先がファンディング桐生（寄附型）を利用する際に提出する当社所定の申込書に定める期日となります。ただし、当社が指定した期日までに寄附先名義の正しい口座情報をいただけない場合は、当社指定の期日に実施日を変更することができるものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第9条（寄附金の取扱い）</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、当社の社内規程等に従い、寄附金を当社の自己資金とは分別して管理したうえで、寄附者ごとの入金額を帳簿により管理するものとします。</p></li>
          <li><p>原規約及び本寄附型特約に従い寄附型会員間契約の決済が完了した場合、寄附先は、原規約または本寄附型特約に別に定める場合を除き、寄附金の受領の有無を問わず、寄附者に返礼品を提供する義務を負うものとします。ただし、寄附者が寄附型会員間契約のキャンセルにつき同意した場合はこの限りではありません。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第10条（キャンセル等の対応）</div>
        </div>
        <ol class="numlists">
          <li><p>プロジェクトが原規約又は本寄附型特約の定めに従い中止された場合、又は寄附型会員間契約が会員間で取り消し若しくは解除となった場合、寄附型会員間契約は契約締結時にさかのぼって効力を喪失するものとします。寄附先は、プロジェクトが中止された時点で既に寄附者による寄附金の支払いが行われている場合には、寄附者に対して、寄附金相当額の返金手続をするものとします。この場合、当社は、返金手続が適切に行われるようにサポートしますが、返金手続が適切に行われることを保証するものではありません。当社が寄附先のために寄附金の返金手続のサポートを行い費用を負担した場合には、寄附先に対して費用相当額（人件費を含みますがそれに限られません）を求償することができるものとします。</p></li>
          <li><p>前項に定める当社のサポートは、電子メール、又はプロジェクトの活動レポートのいずれか一つ又は複数の方法により行われるものとします。寄附者が当社からの最初の連絡に対して6ヶ月以内に返答を行わない場合、寄附者から寄附金相当額を返還すべき利用可能な銀行口座等の届出がない場合、その他寄附先による寄附者への返金が不可能な場合は、その金銭の処分は当社に委ねられたものとみなされます。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第11条(本サービスの変更・廃止)</div>
        </div>
        <ol class="numlists">
          <li><p>当社は、ファンディング桐生(寄附型)の内容や機能・利用料金等を変更し、追加し、削除することができるものとします。</p></li>
          <li><p>当社は、当社の判断によっていつでもファンディング桐生(寄附型)を廃止できるものとします。</p></li>
          <li><p>当社は、ファンディング桐生(寄附型)に関わる重要な機能・利用料金等を変更・廃止し、または、本サービスを廃止する場合、事前に当社ウェブサイト（URL：https://kiryu.fund）に掲示する等適切な方法により、会員にその旨を周知するものとします。</p></li>
          <li><p>当社は、本条に基づくファンディング桐生(寄附型)の廃止又は変更によって生じた損害について、一切の責任を負わないものとします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第12条(本寄附型特約の変更)</div>
        </div>
        <ol class="numlists">
          <li>
            <p>当社は以下の各号のいずれかの場合に、当社の裁量により、本寄附型特約を変更することができます。</p>
            <ol class="bracketslists">
              <li><p>本寄附型特約の変更が、会員の一般の利益に適合するとき。</p></li>
              <li><p>本寄附型特約の変更が、契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき。</p></li>
            </ol>
          </li>
          <li><p>当社は前項による本寄附型特約の変更にあたり、事前に本寄附型特約を変更する旨及び変更後の寄附型特約の内容とその効力発生日を当社ウェブサイト（URL：https://kiryu.fund）に掲示する等の方法により、会員に周知します。</p></li>
          <li><p>変更後の本寄附型特約の効力発生日以降に会員がファンディング桐生(寄附型)を利用したときは、会員は、本寄附型特約の変更に同意したものとみなします。</p></li>
        </ol>
      </section>
      <section class="pages_section">
        <div>
          <div class="pages_section_title">第13条(適用)</div>
          <div class="pages_section_description">本寄附型特約は、原規約に優先して適用されるものとします。また、本寄附型特約に定めのない事項は、原規約の定めに従うものとし、原規約における「プロジェクト実行者」は「寄附先」、「サポーター」は「寄附者」に、「リターン」は「返礼品」、「応援購入金」は「寄附金」とそれぞれ読み替えて準用するものとします。</div>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Terms',
};
</script>
