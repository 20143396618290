<template>
  <rounded-box gutter='sm' radius='sm' :has-shadow="true">
    <div v-if="returnItem.imageSrc" :class="$style.thumb">
      <img :src="returnItem.imageSrc" :alt="returnItem.title" :class="$style.image">
    </div>
    <p :class="$style.price">{{ addComma(returnItem.price) }}<span :class="$style.priceUnit">円</span><small :class="$style.exTax" v-if="!isDonation">（税抜）</small><small :class="$style.exTax" v-if="taxIncludes">（税込み）</small></p>
    <div :class="$style.title">{{returnItem.title}}</div>
    <div
      class="n2br"
      :class="$style.description">
      <p v-html="returnItem.description"></p>

      <div
        :class="$style.descriptionImage"
        v-if="returnItem.descriptionImage
          && $route.name.includes('payment-info')">
        <img :src="returnItem.descriptionImage" :alt="returnItem.title">
        <p class="form-assistance" v-if="returnItem.descriptionComment">{{ returnItem.descriptionComment }}</p>
      </div>
    </div>


    <div
      :class="$style.meta"
      v-if="salesTotal">
      <div :class="$style.patron">支援者 {{ addComma(salesTotal.lot) }}<span>人</span></div>
      <div :class="$style.remaining">あと{{ returnItem.limit - salesTotal.lot }}人が支援できます</div>
    </div>
    <button
      v-if="showBtn && salesTotal"
      @click="handleClick"
      :disabled="disabled || !getRemainingToday(project.period) > 0"
      :class="$style.button">支援する</button>
    <p class="form-assistance" v-if="returnItem.disabledComment">{{ returnItem.disabledComment }}</p>
    <p class="form-assistance" v-if="salesTotal && (returnItem.limit - salesTotal.lot) < 1">支援制限数に到達しました。</p>
  </rounded-box>
</template>

<script>
import { mapState } from 'vuex';
import { RoundedBox } from '@/components/parts/Container';
import cf from '@/mixins/commonFunctions';

export default {
  name: 'ReturnItem',
  mixins: [cf],
  components: {
    RoundedBox,
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    returnItem: {
      type: Object,
      required: true,
    },
    showBtn: {
      type: Boolean,
      required: false,
      default: true,
    },
    isDonation: {
      type: Boolean,
      required: true,
    },
    taxIncludes: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      salesTotal: null, // 初期実装時
      disabled: false,
    };
  },
  computed: {
    ...mapState(['user']),
  },
  created() {
    this.getReturnSalesData();
  },
  methods: {
    // 初期実装時: リターンの購入合計取得
    getReturnSalesData() {
      this.axios({
        method: 'GET',
        url: '/v1/sales/get/sum',
        params: {
          type: 'return',
          id: this.returnItem.id,
        },
      })
        .then((response) => {
          const data = response.data;
          this.salesTotal = data.result;
          // 支援数が設定値を超えた場合または
          // 予めdisabledが設定されている場合は支援ボタン無効
          if (((this.returnItem.limit - data.result.lot) < 1)
            || this.returnItem.disabled) {
            this.disabled = true;
          }
          this.setMetas();
        })
        .catch((error) => {
          if (error.response) console.log(error.response.data);
          else console.log(error);
        });
    },

    handleClick() {
      const slug = {
        project: this.$route.params.slug,
        return: this.returnItem.slug,
      };
      const anonymousBank = this.$route.matched[0].instances.default.project.anonymousBank;
      if (this.user.login.isLogin) {
        // ログインユーザは支払いへ進む
        this.$router.push({
          path: `/payment/info/${slug.project}/${slug.return}/`,
        });
      } else {
        // 非ログユーザはモーダル表示
        // 1. 登録・ログイン
        // 2. ゲスト
        // 3. 匿名（振り込みのみ）
        const args = {
          modalName: 'supportType',
          data: {
            slug,
            anonymousBank,
          },
        };

        this.$store.dispatch('modal/messages/showModal', args, { root: true });
      }
    },

    // HTML形式の文字列からタグ類を除去して返す
    transPlainString(htmlString) {
      const regex = /(<([^>]+)>)/ig;
      return htmlString.replace(regex, '');
    },

    setMetas() {
      const defaults = {
        title: 'ファンディング桐生',
        description: 'ファンディング桐生は地域密着型クラウドファンディングです',
        og_image: 'https://kiryu.fund/img/og.png',
      };

      const meta = {};
      const item = cf.methods.getClone(this.returnItem);

      // リターンタイトルとリターン詳細をセット
      meta.title = `${item.title} | ${defaults.title}`;
      meta.description = this.transPlainString(item.description) || defaults.description;
      const split = location.pathname.split('/');
      const trailingSlash = split[split.length - 1] !== '' ? '/' : '';
      meta.og_url = `${location.protocol}//${location.host}${location.pathname}${trailingSlash}`;
      meta.type = 'article';
      // meta sets
      document.title = meta.title;
      document.querySelector("meta[name='description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:title']").setAttribute('content', meta.title);
      document.querySelector("meta[property='og:description']").setAttribute('content', meta.description);
      document.querySelector("meta[property='og:url']").setAttribute('content', meta.og_url);

      // canonicalタグの動的生成
      const canonical = document.querySelector("link[rel='canonical']");
      if (canonical) {
        // 存在する場合は書き換え
        canonical.href = meta.og_url;
      } else {
        // 存在しない場合は生成
        const linkTag = document.createElement('link');
        linkTag.setAttribute('rel', 'canonical');
        linkTag.href = meta.og_url;
        document.head.appendChild(linkTag);
      }
    },
  },
};
</script>

<style module>
.thumb {
  position: relative;
  display: block;
  width: calc(100% + 16px * 2);
  margin-left: -16px;
  margin-top: -16px;
  overflow: hidden;
}

.thumb::before {
  content: '';
  position: relative;
  display: block;
  width: 100%;
  padding-top: 55%;
}

.image {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.price {
  display: block;
  font-size: 32px;
  line-height: 1;
  font-weight: 700;
}

.thumb + .price {
  margin-top: 16px;
}

.priceUnit {
  margin-left: 8px;
  font-size: 24px;
}

.exTax {
  color: rgba(black, .64);
  font-size: 1.4rem;
}

.title {
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
}

.description {
  margin-top: 8px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 15px;
  line-height: 1.6;
  color: rgba(0, 0, 0, 0.64);
}

.descriptionImage {
  margin-top: 1em;
}

.meta {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.patron,
.remaining {
  white-space: pre;
}


.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  margin-top: 24px;
  border: none;
  border-radius: 24px;
  background-color: #000;
  color: #fff;
  appearance: none;
  box-shadow: none;
  font-size: 18px;
  font-weight: bold;
}

@media screen and (max-width: 767px) {
  .description {
    font-size: 14px;
  }
}
</style>
